import React from 'react';
import { Badge } from '@mui/material';

import Upload from '../../components/Upload/Upload';
import avatarEdit from '../../assets/edit-avatar.png';
import Avatar from '../../components/Avatar/Avatar';

const EditAvatarIcon = ({ getRootProps }) => (
  <div
    style={{
      cursor: 'pointer',
      backgroundColor: 'white',
      padding: 5,
      borderRadius: 10,
      border: '1px solid #eee',
    }}
    {...getRootProps()}
  >
    <img src={avatarEdit} width={20} />
  </div>
);

const UploadAvatar = ({ onUpload, loading, avatarUrl, displayName }) => (
  <Upload onUpload={onUpload} loading={loading}>
    {({ getRootProps }) => (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<EditAvatarIcon getRootProps={getRootProps} />}
      >
        <div {...getRootProps()}>
          <Avatar
            src={avatarUrl}
            name={displayName}
            size={104}
            fontSize={34}
            sx={{ cursor: 'pointer' }}
          />
        </div>
      </Badge>
    )}
  </Upload>
);

export default UploadAvatar;
