import { AlertColor } from '@mui/material';
import { useAppDispatch } from 'hooks/redux';
import { actions } from './slice';
import { useDispachify } from '../../utils/redux';

export const useAlert = () => {
  const dispatch = useAppDispatch();
  const wrapper = useDispachify(dispatch);

  const instance = {
    addAlert: wrapper(actions.addAlert),
  };

  return instance;
};
