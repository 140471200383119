import { createAllSlice } from '../../utils/redux';
// import notificationsService from '../../services/notifications';

export interface NotificationState {
  loading?: boolean;
  notifications?: any[];
  pageCount?: number;
  currentPage?: number;
}

const initialState: NotificationState = {
  notifications: [],
};

export const {
  slice,
  reducer,
  multiSet: updateState,
} = createAllSlice('connections', initialState);

// const onLoadNotifications = (page) => async (dispatch) => {
// dispatch(updateState({ loading: true }));
// const {
//   notifications,
//   pages,
//   page: currentPage,
// } = await notificationsService.fetchNotifications(page);

// dispatch(
//   updateState({
//     loading: false,
//     notifications,
//     pageCount: pages,
//     currentPage,
//   })
// );
// };

export const actions = {
  // onLoadNotifications,
};
