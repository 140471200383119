import { createSlice, createAction } from '@reduxjs/toolkit';
import { reduxMultiSet, reduxSingleSet } from '../../utils/redux';
// import { isEmpty, size } from 'lodash';
// import * as actionTypes from '../actions/actionTypes';
// import gratitudesService from '../../services/gratitudes';
// import karmaService from '../../services/karma';
// import usersService from '../../services/users';
// import authService from '../../services/auth';
// import friendshipService from '../../services/friendship';
// import {
// extractRecipints,
// replaceEmailsWithIds,
// gratitudeErrors,
// } from '../../utils/gratitudes';
// import * as oldActions from '../actions';
// import { GiveThanksPayload } from '../../types/model';
// import sliceActions from '../sliceActions';

export interface DashboardState {
  thanksText?: string;
  gratitudeActions?: any[];
  myGratitudeActions?: any[];
  textHasErrors?: boolean;
  // karmaData?: any;
  gratitudesPage?: any;
  gratitudesPageCount?: any;
  myGratitudesPage?: any;
  myGratitudesPageCount?: any;
  gratitudeType?: any;
  addNewConnectionsModalOpen?: any;
  newEmails?: any;
  existingUsers?: any;
  // waitingFriendships?: any;
  sendingLoading?: any;
  accountProperties?: any;
  errors?: any;
  gratitude?: any;
  gratitudeLoading?: any;
}

const clear = createAction('dashboard/clear');

const initialState: DashboardState = {};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    multiSet: reduxMultiSet,
    set: reduxSingleSet,
  },
  extraReducers: (builder) => {
    builder.addCase(clear, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { multiSet, set } = dashboardSlice.actions;

export default dashboardSlice.reducer;

// const dashboardSet = (field, value) => set({ field, value });

const dashboardMultiSet = (map) => multiSet({ map });

// export const onLoadGratitudes = () => (dispatch) => {
//   gratitudesService.fetchGratitudesForMe(1).then((data) => {
//     dispatch(
//       dashboardMultiSet({
//         gratitudeActions: data.gratitudes,
//         gratitudesPage: data.page,
//         gratitudesPageCount: data.pages,
//       })
//     );
//   });
// };

// export const onLoadKarma = () => (dispatch) => {
//   karmaService.fetchLatestAllKarma().then((karamData) => {
//     dispatch(dashboardSet('karmaData', karamData));
//   });
// };

// export const onGratitudesPageChange = (page) => (dispatch) => {
//   dispatch(dashboardMultiSet({ gratitudeLoading: true }));
//   gratitudesService
//     .fetchAllGratitudesForUser(page)
//     .then((data) => {
//       dispatch(
//         dashboardMultiSet({
//           gratitudeActions: data.gratitudes,
//           gratitudesPage: data.page,
//           gratitudesPageCount: data.pages,
//           gratitudeLoading: false,
//         })
//       );
//     })
//     .catch(() => {
//       dispatch(
//         dashboardMultiSet({
//           gratitudeLoading: false,
//         })
//       );
//     });
// };

// export const onMyGratitudesPageChange = (page) => (dispatch) => {
//   dispatch(dashboardMultiSet({ gratitudeLoading: true }));
//   gratitudesService
//     .fetchGratitudesForMe(page)
//     .then((data) => {
//       dispatch(
//         dashboardMultiSet({
//           myGratitudeActions: data.gratitudes,
//           myGratitudesPage: data.page,
//           myGratitudesPageCount: data.pages,
//           gratitudeLoading: false,
//         })
//       );
//     })
//     .catch(() => {
//       dispatch(
//         dashboardMultiSet({
//           gratitudeLoading: false,
//         })
//       );
//     });
// };

export const onGratitudeTypeChange = (value) => (dispatch) => {
  dispatch(
    dashboardMultiSet({
      gratitudeType: value,
    })
  );
};

export const onAddNewConnectionsModalClose = () => (dispatch) => {
  dispatch(
    dashboardMultiSet({
      addNewConnectionsModalOpen: false,
      sendingLoading: false,
    })
  );
};

// export const fetchUsers = (emails) => (dispatch) => {
//   usersService.fetchUsersByEmails(emails).then((users) => {
//     dispatch(
//       dashboardMultiSet({
//         existingUsers: users,
//       })
//     );
//   });
// };

// export const loadWaitingFriendships = () => (dispatch) => {
//   friendshipService.fetchWaitingFriendships().then((friendships) => {
//     dispatch(
//       dashboardMultiSet({
//         waitingFriendships: friendships,
//       })
//     );
//   });
// };

// export const onFrienshipApprove = (friendshipId) => (dispatch) => {
//   friendshipService.approveFrienship(friendshipId).then(() => {
//     dispatch(loadWaitingFriendships());
//     dispatch(sliceActions.alerts.addAlert('Friendship accepted.'));
//   });
// };

// export const onDismissMessage = () => (dispatch) => {
//   authService.updateAccountProperties({ show_message: false }).then(() => {
//     dispatch(sliceActions.auth.onLoadAccountProperties());
//   });
// };

// export const onFrienshipReject = (friendshipId) => (dispatch) => {
//   friendshipService.rejectFrienship(friendshipId).then(() => {
//     dispatch(loadWaitingFriendships());
//     dispatch(sliceActions.alerts.addAlert('Friendship rejected.'));
//   });
// };

export const clearDashboard = () => (dispatch) => dispatch(clear());

export const actions = {
  // onLoadGratitudes,
  // onLoadKarma,
  // onGratitudesPageChange,
  // onMyGratitudesPageChange,
  // onGratitudeTypeChange,
  // onAddNewConnectionsModalClose,
  // fetchUsers,
  // loadWaitingFriendships,
  // onFrienshipApprove,
  // onDismissMessage,
  // onFrienshipReject,
  // clearDashboard,
};
