import axios from 'axios';
import authService from '../../services/auth';
import usersService from '../../services/users';
import { createDefaultSlice, useDispachify } from '../../utils/redux';
import { actions as alertsActions } from '../../features/AlertsFeature/slice';
import faker from '@faker-js/faker';
import { AppThunk } from 'store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

export interface RegisterForm {
  id?: number;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  username?: string;
}

export interface AuthErrors {
  [name: string]: string;
}

export interface AuthState {
  // email?: string;
  // errors?: boolean;
  // flash?: any;
  // partialUser?: any;
  token?: string;
  // authenticated?: boolean;
  isAuthLoading?: boolean;
  // isValidEmail?: boolean;
  // accountProperties?: any;
  // registerStep?: number;
  // registerForm?: RegisterForm;
  // backendErrors?: AuthErrors;
}

const initialState: AuthState = {
  // authenticated: false,
  isAuthLoading: true,
  token: null,
  // registerStep: 1,
  // registerForm: null,
};

// export const authState = createDefaultSlice('auth', initialState);

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      localStorage.setItem('token', token);
      state.token = token;
      state.isAuthLoading = false;
    },
    clearAuth: (state) => {
      localStorage.removeItem('token');
      state.token = null;
      state.isAuthLoading = false;
    },
    doneAuth: (state) => {
      state.isAuthLoading = false;
    },
  },
});

// export const { multiSet, set } = authState.actions;

export const { setCredentials, clearAuth, doneAuth } = authSlice.actions;

export default authSlice.reducer;

// const authMultiSet = (map: AuthState) => multiSet({ map });

// const addError = (dispatch, message: string) => {
//   dispatch(alertsActions.addAlert(message, 'error'));
//   dispatch(
//     authMultiSet({
//       errors: true,
//     })
//   );
// };

// const onInitLogin = (token) => (dispatch) => {
//   localStorage.setItem('token', token);
//   axios.defaults.headers.common.Authorization = token;

//   dispatch(authMultiSet({ token }));
// };

// const onClearAuth = () => (dispatch) => {
//   localStorage.setItem('token', '');
//   axios.defaults.headers.common.Authorization = '';
//   dispatch(
//     authMultiSet({
//       authenticated: false,
//       token: null,
//       isAuthLoading: false,
//       errors: null,
//     })
//   );
// };

// const onhUserSignOut = (): AppThunk => async (dispatch) => {
//   await authService.logout();
//   dispatch(onClearAuth());
// };

// const onLogin =
//   (username, password): AppThunk =>
//     (dispatch) => {
//       authService
//         .login(username, password)
//         .then(({ token, data }) => {
//           dispatch(onInitLogin(token));
//           dispatch(
//             authMultiSet({
//               token,
//               authenticated: true,
//               email: data.user.email,
//               isAuthLoading: false,
//               // accountProperties: data.user.account_properties,
//             })
//           );
//         })
//         .catch((err) => {
//           dispatch(
//             authMultiSet({
//               errors: true,
//             })
//           );

//           const { error } = err.response.data;

//           dispatch(alertsActions.addAlert(error, 'error'));
//         });
//     };

// const onInitSignInUser = () => (dispatch) => {
//   const token = localStorage.getItem('token');
//   dispatch(onInitLogin(token));
//   // authService
//   // .fetchAccountProperties()
//   // .then(({ account_properties }) => {
//   dispatch(
//     authMultiSet({
//       // accountProperties: account_properties,
//       authenticated: true,
//       isAuthLoading: false,
//     })
//   );
//   // })
//   // .catch(() => {
//   //   dispatch(
//   //     authMultiSet({
//   //       authenticated: false,
//   //       isAuthLoading: false,
//   //     })
//   //   );
//   // });
// };

// const onSignUp = (user, navigate) => async (dispatch) => {
//   authService
//     .register(user)
//     .then(({ token, data }) => {
//       dispatch(onInitLogin(token));
//       dispatch(
//         authMultiSet({
//           errors: null,
//           token,
//           authenticated: true,
//           isAuthLoading: false,
//           registerStep: 1,
//         })
//       );
//       navigate('/onboarding');
//     })
//     .catch((error) => {
//       // addError(dispatch, error.response.data.error);

//       dispatch(
//         authMultiSet({
//           backendErrors: error.response.data.error,
//         })
//       );
//     });
// };

// const onForgotPasswordSend = (email, navigate) => (dispatch) => {
//   authService
//     .forgotPassword(email)
//     .then((data) => {
//       dispatch(
//         alertsActions.addAlert(
//           'Email with recovery link sent to the address provided.'
//         )
//       );
//       navigate('/');
//     })
//     .catch((error) => {
//       addError(dispatch, error.response.data.error);
//     });
// };

// const onUpdatePassword = (password, token, navigate) => (dispatch) => {
//   authService
//     .updatePassword(password, token)
//     .then((data) => {
//       dispatch(alertsActions.addAlert('Password successfully updated'));
//       dispatch(
//         authMultiSet({
//           errors: false,
//         })
//       );
//       navigate('/');
//     })
//     .catch((error) => {
//       if (error.response.data.errors) {
//         dispatch(
//           authMultiSet({
//             errors: error.response.data.errors,
//           })
//         );
//       } else {
//         addError(dispatch, 'Ups, something when wrong');
//       }
//     });
// };

// const onLoadPartialUser = (invitationToken) => (dispatch) => {
//   authService
//     .fetchPartialUser(invitationToken)
//     .then((data) => {
//       dispatch(
//         authMultiSet({
//           partialUser: data.user,
//         })
//       );
//     })
//     .catch(() => {
//       addError(dispatch, 'Ups. Looks like the invitation token is wrong.');
//     });
// };

// const onResetAuth = () => (dispatch) => {
//   dispatch(
//     authMultiSet({
//       errors: null,
//     })
//   );
// };

// const onLoadAccountProperties = () => (dispatch) => {
//   authService.fetchAccountProperties().then(({ account_properties }) => {
//     dispatch(authMultiSet({ accountProperties: account_properties }));
//   });
// };

// const onMoveNextRegisterStep = (user) => async (dispatch) => {
//   const { is_valid } = await authService.validateEmail(user.email);

//   if (is_valid) {
//     const newUser = await generateUsername(user);
//     dispatch(
//       authMultiSet({
//         registerStep: 2,
//         registerForm: newUser,
//         errors: false,
//       })
//     );
//   } else {
//     // addError(dispatch, 'Email is already taken.');
//     // dispatch(alertsActions.addAlert(message, 'error'));
//     dispatch(
//       authMultiSet({
//         backendErrors: {
//           email: 'Email is already taken',
//         },
//       })
//     );
//   }
// };

// const generateUsername = async (user) => {
//   const { username } = await usersService.generateUsername(
//     user.firstName,
//     user.lastName
//   );

//   const newUser = {
//     ...user,
//     username,
//   };

//   return newUser;
// };

// const onRegisterFormOnChange = (user, field, value) => (dispatch) => {
//   dispatch(
//     authMultiSet({
//       registerForm: {
//         ...user,
//         [field]: value,
//       },
//     })
//   );
// };

export const getInitialFormData = (partialUser) => {
  if (partialUser) {
    return {
      id: partialUser.id,
      firstName: partialUser.first_name,
      lastName: partialUser.last_name,
      email: partialUser.email,
    };
  }

  if (process.env.NODE_ENV == 'development' && !partialUser) {
    return {
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      email: faker.internet.email(),
      password: 'pass1234',
    };
  }

  return {};
};

// const onInitRegisterForm = (partialUser) => (dispatch) => {
//   dispatch(
//     authMultiSet({
//       registerForm: {
//         ...getInitialFormData(partialUser),
//       },
//     })
//   );
// };

// const onLoginAs = (username) => async (dispatch) => {
//   const { token } = await authService.loginAs(username);

//   dispatch(onInitLogin(token));
// };

// const onSignUpGoBack = () => (dispatch) => {
//   dispatch(
//     authMultiSet({
//       registerStep: 1,
//     })
//   );
// };

export const actions = {
  // onInitLogin,
  // onhUserSignOut,
  // onLogin,
  // onInitSignInUser,
  // onSignUp,
  // onForgotPasswordSend,
  // onUpdatePassword,
  // onLoadPartialUser,
  // onResetAuth,
  // onLoadAccountProperties,
  // onMoveNextRegisterStep,
  // onRegisterFormOnChange,
  // onInitRegisterForm,
  // onLoginAs,
  // onClearAuth,
  // onSignUpGoBack,
};

export const useAuth = (init = false) => {
  const dispatch = useAppDispatch();
  const wrapper = useDispachify(dispatch);
  const authState = useAppSelector((state) => state.auth);

  const initAuth = () => {
    const token = localStorage.getItem('token');

    if (token) {
      dispatch(setCredentials({ token }));
    } else {
      dispatch(doneAuth());
    }
  };

  useEffect(() => {
    if (init) {
      initAuth();
    }
  }, []);

  return {
    token: authState.token,
    isAuthLoading: authState.isAuthLoading,
    clearAuth: wrapper(clearAuth),
    setCredentials: wrapper(setCredentials),
    isAuthenticated: authState.token !== null, //!isEmpty(authState.token) || !(authState.token === null),
    initAuth,
  };
};
