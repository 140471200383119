import React from 'react';
import { Box, Typography } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import { useGetMetricsQuery } from 'features/api/userSlice';
// import down from 'assets/down.png';
// import up from 'assets/up.png';
// import karma from 'assets/karma.png';
import assets from 'assets';
import { HStack } from 'components/Stacks/Stacks';
import { isNil } from 'lodash';

interface PopoverMetrics {
  fromUserId: number;
  toUserId: number;
}

const Metric = ({ icon, number }) => (
  <HStack spacing={1}>
    <img src={icon} width={25} />
    <Typography>{number}</Typography>
  </HStack>
);

const PopoverMetrics: React.FC<PopoverMetrics> = ({ fromUserId, toUserId }) => {
  const skip: boolean = isNil(fromUserId) || isNil(toUserId);
  const { data, isFetching } = useGetMetricsQuery({ fromUserId, toUserId }, { skip });

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <Box sx={{ p: 1, m: 1 }}>
      <Typography color="secondary.dark" component="span">
        {data?.from_user_display_name}
      </Typography>
      {' to '}
      <Typography color="secondary.dark" component="span">
        {data?.to_user_display_name}
      </Typography>
      <Box margin={2}>
        <HStack spacing={3} justifyContent="center">
          <Metric icon={assets.up} number={data?.total_giving} />
          <Metric icon={assets.down} number={data?.total_receiving} />
          <Metric icon={assets.karma} number={data?.total_karma} />
        </HStack>
      </Box>
    </Box>
  );
};

export default PopoverMetrics;
