import { styled } from '@mui/material';
import React from 'react';

const HeaderStyled = styled('h1')(() => ({
  textAlign: 'left',
  textTransform: 'capitalize',
}));

const Header = ({ children }) => <HeaderStyled>{children}</HeaderStyled>;

export default Header;
