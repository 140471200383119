import React, { useState } from 'react';
import GratitudeList from 'components/GratitudeList/GratitudeList';
import { useGetGratitudeForUserQuery } from 'features/api/gratitudeSlice';
import Spinner from 'components/Spinner/Spinner';

const TotalActivityPanel = ({ userId, authenticated, onDenied }) => {
  const [page, setPage] = useState(1);
  const {
    data: list,
    isFetching,
    isLoading,
  } = useGetGratitudeForUserQuery({
    userId,
    page,
  });

  // if (isFetching) {
  //   return <Spinner />;
  // }

  // const { data, page: currentPage, pages: pageCount } = list;

  return (
    <GratitudeList
      page={list?.page}
      actions={list?.data}
      pageCount={list?.pages}
      onPageChange={setPage}
      authenticated={authenticated}
      onDenied={onDenied}
      loading={isFetching}
      isNotLoaded={isLoading}
    />
  );
};

export default TotalActivityPanel;
