import { NotFriends } from 'features/GiveThanksFeature/slice';
import { GiveThanksPayload, Gratitude } from 'types/model';
import { apiSlice } from './apiSlice';
import { ListResponse } from './types';

export interface FriendshipWaiting {
  id: number;
  created_at: string;
  user: { display_name: string; avatar_url: string; username: string };
  other_friendship_id: number;
}

export interface Friendship {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  display_name: string;
  avatar_url: string;
  username: string;
  giving_count: number;
  reciving_count: number;
  total_giving_count: number;
  total_reciving_count: number;
  karma_score: number;
  needs_approval: boolean;
  friend_friendship_id: number;
  friendship_id: number;
  accepted: boolean;
}

export interface FriendshipsWaitingResponse {
  friendships: FriendshipWaiting[];
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWaitingFriendships: builder.query<FriendshipsWaitingResponse, void>({
      query: () => `/friendship/waiting.json`,
      providesTags: ['Friendship'],
    }),
    friendshipApprove: builder.mutation<void, { friendshipId: number }>({
      query: ({ friendshipId }) => ({
        url: `/friendship/${friendshipId}/approve.json`,
        method: 'POST',
      }),
      invalidatesTags: ['Friendship', 'User'],
    }),
    friendshipReject: builder.mutation<void, { friendshipId: number }>({
      query: ({ friendshipId }) => ({
        url: `/friendship/${friendshipId}/reject.json`,
        method: 'POST',
      }),
      invalidatesTags: ['Friendship', 'User'],
    }),
    friendshipRemove: builder.mutation<void, { friendshipId: number }>({
      query: ({ friendshipId }) => ({
        url: `/friendship/${friendshipId}.json`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Friendship', 'User'],
    }),
    requestFriendship: builder.mutation<void, { userId: number }>({
      query: ({ userId }) => ({
        url: `/friendship.json`,
        method: 'POST',
        body: {
          user_id: userId,
        },
      }),
      invalidatesTags: ['Friendship', 'User'],
    }),
    getFriendshipsForUser: builder.query<
      ListResponse<Friendship>,
      { page: number; connectionssSortBy: number }
    >({
      query: ({ page, connectionssSortBy }) =>
        `/friendship.json?page=${page}&sort=${connectionssSortBy}`,
      providesTags: ['Friendship'],
    }),
    getFollowers: builder.query<ListResponse<Friendship>, { page: number }>({
      query: ({ page }) => `/friendship/followers`,
      providesTags: ['Friendship'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWaitingFriendshipsQuery,
  useFriendshipApproveMutation,
  useFriendshipRejectMutation,
  useFriendshipRemoveMutation,
  useRequestFriendshipMutation,
  useGetFriendshipsForUserQuery,
  useGetFollowersQuery,
} = extendedApi;
