import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { each } from 'lodash';
import { Slice } from '@reduxjs/toolkit';

export const reduxSet = (type, mapVariable) => ({
  type,
  map: mapVariable,
});

export const reduxUpdateState = (state, action) => {
  const newState = { ...state };

  each(action.map, (val, key) => {
    newState[key] = val;
  });

  return newState;
};
export interface MapProps {
  [key: string]: any;
}

export interface MultiSetProps {
  map: MapProps;
}
export interface SetProps {
  field: string;
  value: any;
}

export const reduxMultiSet = (state, action: PayloadAction<MultiSetProps>) => {
  each(action.payload.map, (val, key) => {
    state[key] = val;
  });
};

export const reduxSingleSet = (state, action: PayloadAction<SetProps>) => {
  state[action.payload.field] = action.payload.value;
};

export const createDefaultSlice = <T>(sliceName: string, initialState: T) => {
  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      multiSet: reduxMultiSet,
      set: reduxSingleSet,
    },
  });

  return slice;
};

interface FuncHash {
  [funcName: string]: (...args: any) => void;
}

// export const dispachify = <T extends FuncHash>(dispatch, actions: T): T => {
//   const newActions = {}

//   each(actions, (func, key) => {
//     const funcWithDispatch = (...args) => {
//       dispatch(func(...args))
//     }
//     newActions[key] = funcWithDispatch
//   })

//   return newActions as T;
// }

export type InferArgs<T> = T extends (...t: [...infer Arg]) => any
  ? Arg
  : never;
export type InferReturn<T> = T extends (...t: [...infer Arg]) => infer Res
  ? Res
  : never;

export const getWrapper =
  <TFunc extends (...args: any[]) => any>(
    dispatch,
    func: TFunc
  ): ((...args: InferArgs<TFunc>) => void) =>
  (...args: InferArgs<TFunc>) =>
    dispatch(func(...args));

export const useDispachify =
  (dispatch) =>
  <TFunc extends (...args: any[]) => any>(
    func: TFunc
  ): ((...args: InferArgs<TFunc>) => void) =>
  (...args: InferArgs<TFunc>) =>
    dispatch(func(...args));

export const actionsWrapper = <T>(slice: Slice<T>) => {
  const multiSet = (map: T) => slice.actions.multiSet({ map });
  const set = (field, value) => slice.actions.multiSet({ field, value });

  return {
    multiSet,
    set,
  };
};

export const createAllSlice = <T>(sliceName: string, initialState: T) => {
  const slice = createDefaultSlice<T>(sliceName, initialState);
  const reducer = slice.reducer;
  const { multiSet } = actionsWrapper(slice);
  const actions = slice.actions;

  return {
    slice,
    reducer,
    multiSet,
    actions,
  };
};
