import { Button, CircularProgress } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const Upload = ({ onUpload, loading, children, size = 104 }) => {
  const onDrop = useCallback((acceptedFiles) => {
    onUpload(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  if (loading) {
    return (
      <div style={{ width: size, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ marginTop: 15 }}>
      <input {...getInputProps()} />
      {children({ getRootProps })}
    </div>
  );
};

export default Upload;
