import React from 'react';
import {
  trim,
  isEmpty,
  filter,
  includes,
  uniq,
  forEach,
  toString,
  toLower,
  map,
} from 'lodash';
import { Navigate, useNavigate } from 'react-router-dom';
import { scan } from './strings';
import { GiveThanksPayload } from 'types/model';

const EMAIL_REGEX =
  /((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;

export const gratitudeHasRecipints = (text) => {
  const usernames = scan(text, /@\[.*\]\(.*\)/g);
  const emails = scan(text, EMAIL_REGEX);

  return !isEmpty(usernames) || !isEmpty(emails);
};

/// ([a-zA-Z0-9._-\]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g,

export const extractRecipints = (text) => {
  const usernames = scan(text, /@\[([^\]]+?)\]\(([^\)]+?)\)/g);
  const newText = text.replace(/@\[.*\]\(.*\)/g, '');
  let emails = scan(newText, EMAIL_REGEX);

  if (!isEmpty(emails)) {
    const usernames_emails = usernames.map((u) => u[1]);
    emails = filter(emails, (e) => !includes(usernames_emails, e[0])).map(
      (e) => e[0]
    );
  }

  const u = uniq(usernames.map((username) => username[0]));

  return [u, uniq(emails)];
};

export const replaceEmailsWithIds = (text, users) => {
  let newText = text;

  forEach(users, ({ id, first_name, last_name, email }) => {
    newText = toLower(newText).replace(
      email,
      `@[${toString(first_name)} ${toString(last_name)}](${toString(id)})`
    );
  });

  return newText;
};

export const removeUsersAndEmails = (text) => {
  let newText = text.replace(/@\[.*\]\(.*\)/g, '');
  newText = newText.replace(EMAIL_REGEX, '');
  newText = newText.replace(/s/g, '').replace(/,/g, '');

  return trim(newText);
};

export const validateGiveThanksPayload = (
  giveThanksPayload: GiveThanksPayload
) => {};

export const gratitudeErrors = (text, gratitudeType, email) => {
  const errors = { text: [] };

  if (!gratitudeHasRecipints(text)) {
    errors.text.push('Text is missing recipients');
  }

  if (isEmpty(removeUsersAndEmails(text))) {
    errors.text.push('Text is missing a message');
  }

  if (text.match(email)) {
    errors.text.push(
      "It's great to recognise your own achievements, but... you cannot send gratitude to yourself, sorry."
    );
  }

  return errors;
};

const USER_REGEX = /(@\[[a-zA-Z0-9_-\s]+\]\(\d*\))/g;

const FULL_USER_REGEX = /^(@\[[a-zA-Z0-9_-\s]+\]\(\d*\))$/g;

const EXTRACT_USER = /^@\[([a-zA-Z0-9_-\s]+)\]\((\d*)\)$/;

const ClickableUser = ({ name, id }) => {
  const navigate = useNavigate();
  const goToUser = () => navigate(`/u/${id}`);

  return (
    <b className="cap" style={{ cursor: 'pointer' }} onClick={goToUser}>
      {name}
    </b>
  );
};

export const formatContent = (text) => {
  const parts = text.split(USER_REGEX);

  const els = map(parts, (p, idx) => {
    if (p.match(FULL_USER_REGEX)) {
      const [, name, id] = p.match(EXTRACT_USER);
      return <ClickableUser name={name} id={id} key={`clickable-user-${id}`} />;
    } else {
      return <span key={idx} dangerouslySetInnerHTML={{ __html: p }} />;
    }
  });

  return els;
};
// text.replaceAll(/@\[([a-zA-Z0-9_-\s]+)\]\(\d*\)/g, '<b>@$1</b>');
