import React, { useState } from 'react';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import {
  Stack,
  Animation,
  ValueScale,
  HoverState,
  EventTracker,
} from '@devexpress/dx-react-chart';
import { LegendRoot, LegendLabel } from '../Legend/legend';
import { styled } from '@mui/material';
import { width } from '@mui/system';
import BoxStyled from '../Styled/BoxStyled/BoxStyled';

const RBarSeriesStyled = styled(BarSeries)(() => ({
  backgroundColor: '#498BED',
  width: 15,
  borderRadius: 15,
}));

const GBarSeriesStyled = styled(BarSeries)(() => ({
  backgroundColor: '#EB8DBB',
  width: 15,
  borderRadius: 15,
  fill: '#EB8DBB',
}));

// const formatTooltip = d3Format.format(',.2r');

// const TooltipContent = (props) => {
//   const { targetItem, text, ...restProps } = props;
//   return (
//     <div>
//       <div>
//         <Tooltip.Content
//           {...restProps}
//           style={tooltipContentTitleStyle}
//           text={targetItem.series}
//         />
//       </div>
//       <div>
//         <Tooltip.Content
//           {...restProps}
//           style={tooltipContentBodyStyle}
//           text={data[targetItem.point][targetItem.series]}
//         />
//       </div>
//     </div>
//   );
// };

const GivingRecivingChart = ({ data, title }) => {
  const [targetItem, setTargetItem] = useState(undefined);
  // const [hover, setHover] = useState(null);
  // const changeHover = (h) => setHover(h)

  console.log('data');
  console.log(data);

  const changeTargetItem = (target) => setTargetItem(target);

  return (
    <BoxStyled data-testid="giving-receiving-chart">
      <Chart data={data}>
        <ArgumentAxis />

        <ValueAxis />

        <RBarSeriesStyled
          name="Receiving"
          valueField="reciving"
          argumentField="month"
          color="#498BED"
        />

        <GBarSeriesStyled
          name="Giving"
          valueField="giving"
          argumentField="month"
          color="#EB8DBB"
        />

        <Title text={title} />
        <Animation />
        {/* <HoverState hover={hover} onHoverChange={changeHover} /> */}
        <Stack stacks={[{ series: ['Receiving', 'Giving'] }]} />
        <EventTracker />
        <Tooltip
          targetItem={targetItem}
          onTargetItemChange={changeTargetItem}
        />
        <Legend
          rootComponent={LegendRoot}
          labelComponent={LegendLabel}
          position="bottom"
        />
      </Chart>
    </BoxStyled>
  );
};

export default GivingRecivingChart;
