export const APP_NAME = 'Give some gratitude';

export const EMOJIS = {
  hug: '🤗',
  thanks: '🙏',
  flower: '🌹',
  kiss: '💋',
};

export const GRATITUDE_TYPES = [
  ['thanks', 'thanks 🙏'],
  ['hug', 'hug 🤗'],
  ['flower', 'flower 🌹'],
  ['kiss', 'kiss 💋'],
];

export const DEFAULT_GRATTITUDE_TYPE = GRATITUDE_TYPES[0][0];
