import React from 'react';

import Pagination from '@mui/material/Pagination';
import GratitudeAction from '../GratitudeAction/GratitudeAction';
import { styled, Grow, Fade } from '@mui/material';
import { Gratitude } from '../../types/model';
import { isEmpty } from 'lodash';
import AlertMessage from '../AlertMessage/AlertMessage';

import './GratitudeList.css';
import Spinner from 'components/Spinner/Spinner';
interface GratitudeListProps {
  actions: Gratitude[];
  onPageChange: (page: number) => void;
  pageCount: number;
  emptyMessage?: string;
  authenticated?: boolean;
  onDenied?: () => void;
  loading: boolean;
  page: number;
  isNotLoaded: boolean;
}

const Wrapper = styled('div')(() => ({
  borderRadius: 20,
  // boxShadow: '0px 0px 6px rgba(73, 139, 237, 0.3)',
  backgroundColor: '#FFFFFF',
  padding: 15,
  marginTop: 15,
}));

const GratitudeList: React.FC<GratitudeListProps> = ({
  actions,
  onPageChange,
  pageCount,
  emptyMessage = 'Ups, there is nothing here yet.',
  authenticated,
  onDenied,
  loading,
  page,
  isNotLoaded,
}) => {
  if (isNotLoaded) {
    return <Spinner />;
  }

  if (isEmpty(actions) && !loading) {
    return <AlertMessage>{emptyMessage}</AlertMessage>;
  }
  return (
    <div className="Gratitude-List" style={{ textAlign: 'center' }}>
      <Fade in={!loading}>
        <Wrapper>
          {actions &&
            actions.map((action) => (
              <GratitudeAction
                key={action.id}
                action={action}
                authenticated={authenticated}
                onDenied={onDenied}
              />
            ))}
        </Wrapper>
      </Fade>

      {pageCount > 1 && (
        <div
          style={{
            display: 'flex',
            margin: 'auto',
            marginTop: 10,
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          <Pagination
            page={page}
            style={{ margin: 'auto', marginTop: 10, textAlign: 'center' }}
            count={pageCount}
            onChange={(e, p) => onPageChange(p)}
          />
        </div>
      )}
    </div>
  );
};

export default GratitudeList;
