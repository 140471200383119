import React, { useEffect } from 'react';
import KarmaChart from '../KarmaChart/KarmaChart';

const Karma = ({ karmaData }) => {
  if (!karmaData) {
    return null;
  }

  return (
    <div>
      <KarmaChart data={karmaData} />
    </div>
  );
};

export default Karma;
