import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { max } from 'lodash';
import GivingRecivingChart from './GivingRecevingChart';
import KarmaScoreChart from './KarmaScoreChart';
import { santizeKarmaData } from '../../utils/karma';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import { useMediaQuery, useTheme } from '@mui/material';

const KarmaChart = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [chartSelected, setChartSelected] = useState('givingRecevingChart');
  if (!data) {
    return null;
  }

  const maxValue = max(
    data.map(({ giving, reciving }) => max([giving, reciving]))
  );

  if (maxValue <= 0) {
    return (
      <AlertMessage>There is no data to load the chart......yet</AlertMessage>
    );
  }

  const santizedKarmaData = santizeKarmaData(data, isMobile);

  return (
    <Stack spacing={3}>
      <GivingRecivingChart
        data={santizedKarmaData}
        title="Giving and Receiving by Week"
      />
      <KarmaScoreChart data={santizedKarmaData} title="Karma Score by Week" />
    </Stack>
  );
};

export default KarmaChart;
