import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import withGA from '../../hoc/withGoogleAnalytics';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../store/sliceActions';
import { useDispachify } from '../../utils/redux';
import { useUnsubscribeMutation } from 'features/api/userSlice';

const Unsubscribe = () => {
  const [email, setEmail] = useState(null);
  const { id } = useParams();
  // const dispatch = useAppDispatch();
  // const wrapper = useDispachify(dispatch);
  // const { unsubscribeEmail } = useAppSelector((store) => store.account);
  // const onUnsubscribe = wrapper(sliceActions.account.onUnsubscribe);
  const [unsubscribe] = useUnsubscribeMutation();

  useEffect(() => {
    unsubscribe({ id })
      .unwrap()
      .then((response) => {
        setEmail(response.email);
      });
  }, [id]);

  return (
    <div>
      <h1>Give Some Gratitude</h1>
      <h2>Unsubscribe request</h2>
      {email && (
        <h3>You will no longer receive mail notifications at {email}</h3>
      )}

      <p>If you want to resubscribe you can do it later from settings</p>
    </div>
  );
};

Unsubscribe.displayName = 'Unsubscribe';

export default withGA(Unsubscribe);
