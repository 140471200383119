import React from 'react';
import { StackProps, Stack } from '@mui/material';

export const HStack: React.FC<StackProps> = (props) => (
  <Stack {...props} direction="row" />
);

export const VStack: React.FC<StackProps> = (props) => (
  <Stack {...props} direction="column" />
);
