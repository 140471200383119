import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { NotFriends } from 'features/GiveThanksFeature/slice';
import { GiveThanksPayload, Gratitude } from 'types/model';
import { apiSlice } from './apiSlice';
import { ListResponse } from './types';

export interface CreateGratitudeResponse {
  gratitude_id?: number;
  new_user_emails?: string[];
  not_friends?: NotFriends[];
}

export interface Comment {
  display_name: string;
  avatar_url: string;
  id: number;
  content: string;
  created_at: string;
}

export interface GratitudeComment {
  gratitude: Gratitude;
  comments: Comment[];
}

export interface LikeGratitudeResponse {
  liked_by_me: boolean;
  likes_count: number;
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGratitudeForUser: builder.query<
      ListResponse<Gratitude>,
      { userId: number; page: number }
    >({
      query: ({ userId, page }) => ({
        url: `/gratitudes.json?page=${page}&user_id=${userId}`,
        method: 'GET',
      }),
      providesTags: ['Gratitude'],
    }),
    getGratitudeBetweenUs: builder.query<
      ListResponse<Gratitude>,
      { userId: number; page: number }
    >({
      query: ({ userId, page }) => ({
        url: `/gratitudes/between.json?page=${page}&user_id=${userId}`,
        method: 'GET',
      }),
      providesTags: ['Gratitude'],
    }),
    getAllGratitudeForCurrentUser: builder.query<
      ListResponse<Gratitude>,
      { page: number }
    >({
      query: ({ page }) => ({
        url: `/gratitudes/all.json?page=${page}`,
        method: 'GET',
      }),
      // transformResponse: (response: ListResponse<Gratitude>) => {
      //   console.log('response')
      //   console.log(response);
      //   return response;
      // },
      providesTags: ['Gratitude'],
    }),
    getMyGratitudeForCurrentUser: builder.query<
      ListResponse<Gratitude>,
      { page: number }
    >({
      query: ({ page }) => ({
        url: `/gratitudes/me.json?page=${page}`,
        method: 'GET',
      }),
      providesTags: ['Gratitude'],
    }),
    createGratitude: builder.mutation<
      CreateGratitudeResponse,
      GiveThanksPayload
    >({
      query: (params) => ({
        url: '/gratitudes.json',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Gratitude'],
    }),
    likeGratitude: builder.mutation<
      LikeGratitudeResponse,
      { gratitudeId: number }
    >({
      query: ({ gratitudeId }) => ({
        url: `/gratitudes/${gratitudeId}/like.json`,
        method: 'PUT',
      }),
      // invalidatesTags: ['Gratitude'],
    }),
    getGratitudeComments: builder.query<
      GratitudeComment,
      { gratitudeId: number }
    >({
      query: ({ gratitudeId }) => ({
        url: `/gratitudes/${gratitudeId}/comments`,
        method: 'GET',
      }),
      providesTags: ['Gratitude', 'Comment'],
    }),
    createComment: builder.mutation<
      void,
      { gratitudeId: number; content: string }
    >({
      query: ({ gratitudeId, content }) => ({
        url: `/gratitudes/${gratitudeId}/comments`,
        method: 'POST',
        body: { comment: { content } },
      }),
      invalidatesTags: ['Gratitude', 'Comment'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGratitudeForUserQuery,
  useGetGratitudeBetweenUsQuery,
  useCreateGratitudeMutation,
  useGetAllGratitudeForCurrentUserQuery,
  useGetMyGratitudeForCurrentUserQuery,
  useLikeGratitudeMutation,
  useGetGratitudeCommentsQuery,
  useCreateCommentMutation,
} = extendedApi;
