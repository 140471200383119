import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import './Connection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { withLayout } from '../../hoc/Layout/Layout';
import UserConnection from '../../components/UserConnection/UserConnection';
import withGA from '../../hoc/withGoogleAnalytics';
import SearchPeople from '../../components/SearchPeople/SearchPeople';
import Spinner from 'components/Spinner/Spinner';
import {
  useFriendshipApproveMutation,
  useFriendshipRejectMutation,
  useFriendshipRemoveMutation,
  useGetFriendshipsForUserQuery,
  useRequestFriendshipMutation,
} from 'features/api/friendshipSlice';
import List from 'components/List/List';
import Page from 'components/Page/Page';

const Connections = () => {
  const [page, setPage] = useState(1);
  const [connectionssSortBy, setConnectionssSortBy] = useState(1);
  // const dispatch = useAppDispatch();
  // const { users, usersPageCount, loading, usersPage } = useAppSelector(
  //   (state) => state.connections
  // );
  // const wrapper = useDispachify(dispatch);
  // const actions = sliceActions.connections;

  // const onLoadUsers = wrapper(actions.onLoadUsers);
  // const onPageChange = wrapper(actions.onPageChange);
  // const onFrienshipApprove = wrapper(actions.onFrienshipApprove);
  // const onFrienshipReject = wrapper(actions.onFrienshipReject);
  // const onAddFriend = wrapper(actions.onAddFriend);
  // const onRemoveFriend = wrapper(actions.onRemoveFriend);

  const [friendshipApprove] = useFriendshipApproveMutation();
  const [friendshpReject] = useFriendshipRejectMutation();
  const [friendshipRemove] = useFriendshipRemoveMutation();
  const [requestFriendship] = useRequestFriendshipMutation();
  const { data, isFetching } = useGetFriendshipsForUserQuery({
    page,
    connectionssSortBy,
  });

  // useEffect(() => {
  //   onLoadUsers(connectionssSortBy);
  // }, []);

  const handleSortByChange = (e) => {
    setConnectionssSortBy(e.target.value);
  };

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <Page header='Friends'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Box sx={{ minWidth: 120, mb: 2 }}>
                  <SearchPeople
                    onAddFriend={(user) =>
                      requestFriendship({ userId: user.id })
                    }
                  />
                </Box>
              </Grid>
              {!isEmpty(data?.data) && (
                <Grid item xs={12} md={6}>
                  <Box sx={{ minWidth: 120, mb: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Sort by
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={connectionssSortBy}
                        label="Sort by"
                        onChange={handleSortByChange}
                      >
                        <MenuItem value={1}>Name</MenuItem>
                        <MenuItem value={2}>Highest receiver</MenuItem>
                        <MenuItem value={3}>Highest giver</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <List
          list={data}
          onPageChange={setPage}
          loading={isFetching}
          emptyMessage="No friends so far"
        >
          {(item) => (
            <UserConnection
              user={item}
              onRemoveFriend={(friendshipId) =>
                friendshipRemove({ friendshipId })
              }
              onApprove={(friendshipId) => friendshipApprove({ friendshipId })}
              onReject={(friendshipId) => friendshpReject({ friendshipId })}
            />
          )}
        </List>
      </Box>
    </Page>
  );
};

export default withLayout(withGA(Connections));
