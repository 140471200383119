import React, { ReactNode } from 'react';
import {
  Avatar as MuiAvatar,
  Badge,
  styled,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { isEmpty, isNumber } from 'lodash';

function hexToRgb(hex) {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));

  return null;
}

function stringToColor(string) {
  if (!string) {
    return null;
  }

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  const rgbColor = hexToRgb(color);
  const c = `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.5)`;

  return c;
}

function stringAvatar(
  avatarName,
  fontSize
): {
  sx: SxProps;
  children: React.ReactNode;
} {
  if (!avatarName) {
    return { sx: {}, children: null };
  }

  const splitName: string[] = avatarName.split(' ');
  const firstNameLetter = splitName[0]?.charAt(0) ?? '';
  const lastNameLetter = splitName[1]?.charAt(0) ?? '';

  const ret = {
    sx: {
      bgcolor: stringToColor(avatarName),
    },
    children: (
      <span
        data-testid="avatar-initials"
        style={{ fontSize, textTransform: 'uppercase' }}
      >
        {firstNameLetter}
        {lastNameLetter}
      </span>
    ),
  };

  return ret;
}

interface AvatarProps {
  src?: string;
  name: string;
  sx?: SxProps;
  size?: 'large' | 'small' | number;
  style?: React.CSSProperties;
  fontSize?: number;
  onClick?: () => void;
  badge?: ReactNode;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Avatar: React.FC<AvatarProps> = ({
  src,
  name,
  sx,
  size = null,
  style = {},
  fontSize = 12,
  onClick,
  badge,
}) => {
  let avatarProps: { sx: SxProps; children: React.ReactNode } = {
    sx: sx,
    children: null,
  };

  if (!src) {
    avatarProps = stringAvatar(name, fontSize);
  }

  avatarProps = {
    ...avatarProps,
    sx: Object.assign({}, sx, avatarProps.sx),
  };

  if (size == 'small') {
    avatarProps.sx = {
      ...avatarProps.sx,
      width: 24,
      height: 24,
    };
  }

  if (isNumber(size)) {
    avatarProps.sx = {
      ...avatarProps.sx,
      width: size,
      height: size,
    };
  }

  if (onClick) {
    style.cursor = 'pointer';
  }

  let avatar = (
    <MuiAvatar
      data-testid="avatar"
      onClick={onClick}
      style={style}
      alt={name}
      src={src}
      {...avatarProps}
    />
  );

  if (!isEmpty(name)) {
    avatar = <Tooltip title={name}>{avatar}</Tooltip>;
  }

  if (badge) {
    return (
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={badge}
      >
        {avatar}
      </Badge>
    );
  }

  return avatar;
};

export default Avatar;
