import React, { useState } from 'react';
import Spinner from 'components/Spinner/Spinner';
import KarmaChart from 'components/KarmaChart/KarmaChart';
import { useGetLatestKarmaForUserQuery } from 'features/api/karmaSlice';

const KarmaBetweenUsPanel = ({ userId }) => {
  const { data, isFetching } = useGetLatestKarmaForUserQuery({
    userId,
    flag: 'ME',
  });

  if (isFetching) {
    return <Spinner />;
  }

  const { karma_data } = data;

  return <KarmaChart data={karma_data} />;
};

export default KarmaBetweenUsPanel;
