import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Avatar from 'components/Avatar/Avatar';
import { HStack, VStack } from 'components/Stacks/Stacks';
import { isEmpty } from 'lodash';
import React from 'react';
import { User } from 'types/model';

interface LatestsFriendsProps {
  friends: User[];
  onClick: (user: User) => void;
}

const ListWrapper = styled(Box)(() => ({
  overflow: 'scroll',
  paddingBottom: 10,
}));

const UserWrapper = styled(HStack)(() => ({
  div: {
    fontSize: 12,
  },
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#eee',
  },
  padding: 10,
  borderRadius: 20,
}));

const FriendItem: React.FC<{ friend: User; onClick: (user: User) => void }> = ({
  friend,
  onClick,
}) => {
  return (
    <UserWrapper spacing={1} sx={{ mr: 1 }} onClick={() => onClick(friend)}>
      <Avatar src={friend.avatar_url} name={friend.display_name} size="small" />
      <VStack>
        <div style={{ whiteSpace: 'nowrap' }}>{friend.display_name}</div>
        {/* <div>{friend.username}</div> */}
      </VStack>
    </UserWrapper>
  );
};

const LatestsFriends: React.FC<LatestsFriendsProps> = ({
  friends,
  onClick,
}) => {
  if (isEmpty(friends)) {
    return null;
  }

  return (
    <ListWrapper sx={{ mt: 2 }}>
      <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 12 }}>
        Recent
      </div>
      <HStack>
        {friends.map((friend) => (
          <FriendItem key={friend.username} friend={friend} onClick={onClick} />
        ))}
      </HStack>
    </ListWrapper>
  );
};

export default LatestsFriends;
