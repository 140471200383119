import React from 'react';
import { HStack, VStack } from '..//Stacks/Stacks';
import Avatar from '../Avatar/Avatar';
import { timeAgo } from 'utils/dates';
import { fontSize } from '@mui/system';

const styles = {
  '.displayName': {
    textAlign: 'left',
  },
  '.Comment-Main': {
    marginLeft: 2,
  },
  '.createdAt': {
    fontSize: 12,
    textAlign: 'left',
    color: 'gray',
  },
  '.content': {
    textAlign: 'left',
    marginTop: 1,
    fontSize: '1rem',
  },
};

const Comment = ({ comment }) => {
  const { user } = comment;
  return (
    <HStack sx={styles}>
      <Avatar src={user.avatar_url} name={user.display_name} />
      <VStack className="Comment-Main">
        <div className="displayName">{user.display_name}</div>
        <div className="createdAt">{timeAgo(comment.created_at)}</div>
        <div className="content">{comment.content}</div>
      </VStack>
    </HStack>
  );
};

export default Comment;
