import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useSearchParams } from 'react-router-dom';
import ALink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import BoxStyled from 'components/Styled/BoxStyled/BoxStyled';
import { ResetPasswordSchema } from 'utils/valiations';
import { useFormik } from 'formik';
import FormikTextField from 'components/FormikTextField/FormikTextField';

const NewPassword = ({ onUpdate, error }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const reset_password_token = searchParams.get('reset_password_token');

  const formik = useFormik<{ password: string; confirmPassword: string }>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: (values) => {
      onUpdate(values.password, reset_password_token);
    },
    validationSchema: ResetPasswordSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  if (isEmpty(reset_password_token)) {
    return (
      <div>
        <h2>Reset token not present</h2>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/">Sign in</Link>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <BoxStyled
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // width: 500
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password recovery
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <FormikTextField
            formik={formik}
            formikField="password"
            error={error}
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            // onChange={handleChange}
            autoComplete="current-password"
          />

          <FormikTextField
            formik={formik}
            formikField="confirmPassword"
            error={error}
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            // onChange={handleChange}
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Update
          </Button>
        </Box>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/">Sign in</Link>
          </Grid>
        </Grid>
      </BoxStyled>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
  );
};

export default NewPassword;
