import { actions as account } from './slices/account';
import { actions as userDetails } from './slices/userDetails';
import { actions as dashboard } from './slices/dashboard';
import { actions as connections } from './slices/connections';
import { actions as comments } from './slices/comments';
import { actions as followers } from './slices/followers';
import { actions as auth } from './slices/auth';
import { actions as layout } from './slices/layout';
import { actions as onboarding } from './slices/onboarding';
import { actions as alerts } from '../features/AlertsFeature/slice';
import { actions as notifications } from './slices/notifications';

export default {
  account,
  userDetails,
  dashboard,
  connections,
  comments,
  followers,
  auth,
  layout,
  onboarding,
  alerts,
  notifications,
};
