import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import withGA from '../../hoc/withGoogleAnalytics';
import { withLayout } from '../../hoc/Layout/Layout';
import { HStack } from 'components/Stacks/Stacks';
import Avatar from 'components/Avatar/Avatar';
import { styled } from '@mui/material';
import moment from 'moment';
import { useGetNotificationsQuery } from 'features/api/notificationsSlice';
import List from 'components/List/List';
import Page from 'components/Page/Page';

const UnreadDot = styled('div')(() => ({
  backgroundColor: '#d32f2f',
  borderRadius: 5,
  width: 10,
  height: 10,
  marginLeft: 10,
}));

const NotificationEntry = ({
  notification: {
    display_name,
    read,
    content,
    username,
    created_at,
    avatar_url,
  },
}) => {
  const navigate = useNavigate();
  const handleGoToUser = () => navigate(`/u/${username}`);

  return (
    <HStack spacing={2}>
      <Avatar src={avatar_url} name={display_name} />
      <div style={{ fontSize: 14, textAlign: 'left' }}>
        <b className="cursor-pointer primary-color" onClick={handleGoToUser}>
          {display_name}{' '}
        </b>
        {content}
      </div>
      <div style={{ fontSize: 14, color: 'gray' }}>
        {moment.utc(created_at).fromNow()}
      </div>
      {!read && <UnreadDot style={{ marginTop: 4 }} />}
    </HStack>
  );
};
const Notifications = () => {
  const [page, setPage] = useState(1);
  const { data, isFetching } = useGetNotificationsQuery({ page });

  return (
    <Page header="Notifications">
      <List list={data} onPageChange={setPage} loading={isFetching}>
        {(item) => <NotificationEntry notification={item} />}
      </List>
    </Page>
  );
};

export default withLayout(withGA(Notifications));
