import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import SignIn from '../../components/SignIn/SignIn';
import { APP_NAME } from '../../utils/constants';
import sideImage from './logo.png';
import screenshotImage from './screenshot.png';
import './Auth.css';
import withGA from '../../hoc/withGoogleAnalytics';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../store/sliceActions';
import { useDispachify } from '../../utils/redux';
import NoAuthenticated from 'layouts/NoAuthenticated';
import AlertsFeature from 'features/AlertsFeature';
import { APPLICATION_ENV } from 'services/http';
import InformationTopBanner from 'components/InformationTopBanner/InformationTopBanner';
import backgroundImage from '../../assets/background.png';
import { useLoginMutation } from 'features/api/authSlice';
import { useAuth } from 'store/slices/auth';
import Spinner from 'components/Spinner/Spinner';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  // const dispatch = useAppDispatch();
  // const actions = sliceActions.auth;
  // const wrapper = useDispachify(dispatch);
  // const onResetAuth = wrapper(actions.onResetAuth);
  // const onLogin = wrapper(actions.onLogin);
  // const { errors } = useAppSelector((state) => state.auth);
  // const [errors, setErrors] = useState({})
  const navigate = useNavigate();
  const { setCredentials } = useAuth();
  const [login, { error, isLoading }] = useLoginMutation();

  const handleOnLogin = async (email, password) => {
    try {
      const data = await login({ email, password }).unwrap();
      const { token } = data;
      setCredentials({ token });
      navigate('/');
    } catch (error) {
      // console.error(error)
    }
  };

  // useEffect(() => {
  //   onResetAuth();
  // }, []);

  // if (isLoading) {
  //   return <Spinner />
  // }

  // console.log(error);

  // const rightContent = (
  //   <SignIn
  //     onLogin={handleOnLogin}
  //     error={!!error}
  //     isLoading={isLoading}
  //     errorMessages={error?.['data']?.['error']}
  //   />
  // );

  // const leftContent = (
  //   <div className="Auth-Intro">
  //     <div
  //       style={{
  //         paddingTop: 20,
  //         paddingBottom: 20,
  //         margin: 'auto',
  //         textAlign: 'center',
  //       }}
  //     >
  //       <img alt="logo" className="Auth-Logo" src={sideImage} />
  //     </div>
  //     <p className="Auth-Text-Sm">
  //       We are put on this earth to help other people, as far as Robert De Niro
  //       says in the movie Being Flynn
  //     </p>
  //     <p className="Auth-Text-Sm">
  //       Well, I created this website to make visible your good deeds and your
  //       good karma and give gratitude to others.{' '}
  //     </p>
  //     <p className="Auth-Text-Xs">
  //       In the past year I have been receiving help from a lot of people for a
  //       personal matter. Sometimes, I forget to say thank you or show some
  //       gratitude. Even though saying thank you to someone is just a call away,
  //       I thought it would be cool to keep a record of that in some place.
  //     </p>
  //     <p className="Auth-Text-Xs">
  //       The intention of this website is to show some gratitude to the people
  //       that you think deserve it (family, friends, coworkers, partners). You
  //       just need the email and the text you want to share. You can share it
  //       with multiple people too.
  //     </p>
  //     <div className="Auth-Text-Xs">
  //       ...and now I&apos;m just going to leave you with another quote:
  //     </div>

  //     <div className="Auth-Quote">
  //       Life&apos;s most persistent and urgent question is, What are you doing
  //       for others?'
  //     </div>
  //     <div className="Auth-Quote-Author">– Martin Luther King, Jr.</div>
  //   </div>
  // );

  return (
    // <>
    //   <InformationTopBanner />
    //   <div style={{ height: '100%', minHeight: '100%' }}>
    //     <AlertsFeature />
    //     <Grid
    //       container
    //       spacing={2}
    //       justifyContent="center"
    //       style={{
    //         height: '100%',
    //         minHeight: '100%',
    //         backgroundImage: backgroundImage,
    //       }}
    //     >
    //       <Grid
    //         item
    //         xs={12}
    //         sm={12}
    //         md={6}
    //         justifyContent="center"
    //         className="Auth-LeftSide"
    //         style={{
    //           backgroundImage: backgroundImage,
    //         }}
    //       >
    //         {leftContent}
    //       </Grid>
    //       <Grid item xs={12} sm={12} md={6} justifyContent="center">
    //         {rightContent}
    //       </Grid>
    //     </Grid>
    //   </div>
    // </>

    <NoAuthenticated>
      <SignIn
        onLogin={handleOnLogin}
        error={!!error}
        isLoading={isLoading}
        errorMessages={error?.['data']?.['error']}
      />
    </NoAuthenticated>
  );
};

export default withGA(Auth);
