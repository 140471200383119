import React, { useEffect, useState } from 'react';
import './GratitudeAction.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import Tooltip from '@mui/material/Tooltip';
import Paper from '../Paper/Paper';
import { AvatarGroup, Link, Popover, Stack, Typography } from '@mui/material';
import Avatar from '../Avatar/Avatar';
import { formatContent } from '../../utils/gratitudes';
import { Gratitude } from '../../types/model';
import { HStack } from '../Stacks/Stacks';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { selectableIcon } from '../../utils/icons';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';

import gratitudeService from '../../services/gratitudes';
import { first, size } from 'lodash';
import { useLikeGratitudeMutation } from 'features/api/gratitudeSlice';
import PopoverMetrics from 'components/PopoverMetrics/PopoverMetrics';

interface GratitudeActionProps {
  action: Gratitude;
  authenticated?: boolean;
  onDenied?: () => void;
}

const FavoriteBorderIconSelectable = selectableIcon(
  FavoriteBorderIcon,
  FavoriteIcon,
  { clickable: true }
);

const ChatBubbleOutlineIconSelectable = selectableIcon(
  ChatBubbleOutlineIcon,
  ChatBubbleIcon,
  { clickable: true }
);

const GratitudeAction: React.FC<GratitudeActionProps> = ({
  action,
  authenticated,
  onDenied,
}) => {
  const [popoverState, setPopoverState] = React.useState({
    anchorEl: null,
    fromUserId: null,
    toUserId: null,
  });
  // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
  //   null
  // );
  const [gratitudeState, setGratitudeState] = useState(action);
  const navigate = useNavigate();
  const [likeGratitude] = useLikeGratitudeMutation();

  useEffect(() => {
    if (action) {
      setGratitudeState(action);
    }
  }, [action]);

  const handleOnLike = async () => {
    if (authenticated == false) {
      onDenied && onDenied();
    } else {
      const { likes_count, liked_by_me } = await likeGratitude({
        gratitudeId: action.id,
      }).unwrap();

      setGratitudeState({
        ...gratitudeState,
        liked_by_me,
        likes_count,
      });

      // gratitudeService
      //   .likeGratitude(action.id)
      //   .then(({ likes_count, liked_by_me }) => {
      //     setGratitudeState({
      //       ...gratitudeState,
      //       liked_by_me,
      //       likes_count,
      //     });
      //   });
    }
  };

  const handleOnComment = () => {
    if (authenticated == false) {
      onDenied && onDenied();
    } else {
      navigate(`/gratitudes/${gratitudeState.id}/comments`);
    }
  };

  const popoverLeave = (event) => {
    setPopoverState({
      anchorEl: null,
      fromUserId: null,
      toUserId: null,
    });
  };

  const popoverEnter = (fromUserId, toUserId) => function(event) {
    if (!fromUserId || !toUserId) {
      return null;
    }
    
    setPopoverState({
      anchorEl: event.currentTarget,
      fromUserId,
      toUserId,
    });
  };

  const open = Boolean(popoverState.anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const onePersonOnly = size(gratitudeState.recipients) == 1;
  const firstPerson = first(gratitudeState.recipients);

  return (
    <Paper>
      <div className="Action" data-testid="gratitude-action">
        <Stack direction="row">
          <Stack direction="row">
            <Avatar
              onClick={() => navigate(`/u/${gratitudeState.username}`)}
              src={gratitudeState.avatar_url}
              name={gratitudeState.from_name}
            />
            {onePersonOnly ? (
              <Avatar
                onClick={() => navigate(`/u/${firstPerson.username}`)}
                sx={{ left: -10 }}
                src={firstPerson.avatar_url}
                name={firstPerson.display_name}
              />
            ) : (
              <Avatar
                sx={{ left: -10 }}
                name={`${size(gratitudeState.recipients)} People`}
              />
            )}
          </Stack>

          <Stack direction="column">
            <div className="Action-From">
              <b data-testid="gratitude-from">
                {gratitudeState.from_username ? (
                  <>
                    <Link
                      aria-describedby={id}
                      href={`/u/${gratitudeState.from_username}`}
                      onMouseEnter={popoverEnter(
                        gratitudeState.from_id,
                        gratitudeState.to_id
                      )}
                      onMouseLeave={popoverLeave}
                      color="secondary.dark"
                    >
                      <span className="cap">{gratitudeState.from}</span>
                    </Link>
                  </>
                ) : (
                  <span className="primary-color cap">
                    {gratitudeState.from}
                  </span>
                )}
              </b>
              <span> to </span>
              <span>
                <b data-testid="gratitude-to">
                  {gratitudeState.to_username ? (
                    <Link
                      data-testid="gratitude-action-to-link"
                      href={`/u/${gratitudeState.to_username}`}
                      color="secondary.dark"
                      onMouseEnter={popoverEnter(
                        gratitudeState.to_id,
                        gratitudeState.from_id
                      )}
                      onMouseLeave={popoverLeave}
                    >
                      <span className="cap">{gratitudeState.to}</span>
                    </Link>
                  ) : (
                    <span className="cap">{gratitudeState.to}</span>
                  )}
                </b>
              </span>
              <span className="Action-Date">
                {' '}
                said on {moment.utc(gratitudeState.created_at).fromNow()}
              </span>
              {!gratitudeState.to_partial && (
                <Popover
                  style={{ pointerEvents: 'none' }}
                  id={id}
                  open={open}
                  anchorEl={popoverState.anchorEl}
                  onClose={popoverLeave}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <PopoverMetrics
                    fromUserId={popoverState.fromUserId}
                    toUserId={popoverState.toUserId}
                  />
                </Popover>
              )}
            </div>
            <div className="Action-Content">
              <pre className="Action-Text" data-testid="gratitude-content">
                {formatContent(gratitudeState.content)}
              </pre>
              {gratitudeState.privacy === 1 && (
                <div className="Action-IsPrivate">
                  <Tooltip title="Everybody can see this">
                    <PublicIcon data-testid="gratitude-ispublic-icon" />
                  </Tooltip>
                </div>
              )}
              {gratitudeState.privacy === 2 && (
                <div className="Action-IsPrivate">
                  <Tooltip title="Only you can see this">
                    <LockIcon />
                  </Tooltip>
                </div>
              )}
              {gratitudeState.privacy === 3 && (
                <div className="Action-IsPrivate">
                  <Tooltip title="Only friends can see this">
                    <GroupIcon />
                  </Tooltip>
                </div>
              )}
            </div>
          </Stack>
        </Stack>
        <HStack marginLeft={7} marginTop={2} spacing={2}>
          <HStack spacing={1}>
            <FavoriteBorderIconSelectable
              dataTestid="gratitude-like"
              onClick={handleOnLike}
              selected={gratitudeState.liked_by_me}
              selectedColor="#FF3D33"
            />
            <div style={{ fontSize: 12 }} data-testid="gratitude-likes-count">
              {gratitudeState.likes_count > 0
                ? gratitudeState.likes_count
                : null}
            </div>
          </HStack>
          <HStack spacing={1}>
            <ChatBubbleOutlineIconSelectable
              dataTestid="gratitude-comment"
              onClick={handleOnComment}
              selected={gratitudeState.has_comments}
              selectedColor="#A7C7E7"
            />
            <div
              style={{ fontSize: 12 }}
              data-testid="gratitude-comments-count"
            >
              {gratitudeState.comments_count > 0
                ? gratitudeState.comments_count
                : null}
            </div>
          </HStack>
        </HStack>
      </div>
    </Paper>
  );
};

export default GratitudeAction;
