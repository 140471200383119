import React, { CSSProperties } from 'react';
import { SvgIconProps } from '@mui/material';

type SelectableCmpWrapperType = SvgIconProps & {
  selected: boolean;
  selectedColor: string;
  dataTestid?: string;
};

interface IconOptionsProps {
  clickable?: boolean;
}

export const selectableIcon = (
  IconCmpNotSelected: React.FC<SvgIconProps>,
  IconCmpSelected: React.FC<SvgIconProps>,
  options: IconOptionsProps = {}
) => {
  const SelectableCmpWrapper: React.FC<SelectableCmpWrapperType> = ({
    selected,
    selectedColor,
    dataTestid,
    ...props
  }) => {
    const style: CSSProperties = {};

    if (options.clickable) {
      style.cursor = 'pointer';
    }

    if (selected) {
      return (
        <IconCmpSelected
          data-testid={`${dataTestid}-selected`}
          {...props}
          style={{ ...style, color: selectedColor }}
        />
      );
    }
    return (
      <IconCmpNotSelected
        {...props}
        style={style}
        data-testid={`${dataTestid}-notSelected`}
      />
    );
  };

  return SelectableCmpWrapper;
};
