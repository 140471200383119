import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import SignUp from '../../../components/SignUp/SignUp';

import withGA from '../../../hoc/withGoogleAnalytics';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../../store/sliceActions';
import { useDispachify } from '../../../utils/redux';
import NoAuthenticated from 'layouts/NoAuthenticated';
// import { areRequired } from 'utils/valiations';
// import { useLazyGenerateUsernameQuery } from 'features/api/userSlice';
import { getInitialFormData, RegisterForm, useAuth } from 'store/slices/auth';
import {
  useGetPartialUserQuery,
  useRegisterMutation,
} from 'features/api/authSlice';

const Register = () => {
  const [errors, setErrors] = useState(false);
  const [backendErrors, setBackendErrors] = useState({});
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const dispatch = useAppDispatch();
  // const actions = sliceActions.auth;
  // const wrapper = useDispachify(dispatch);
  // const { errors, partialUser, registerStep, registerForm, backendErrors } =
  //   useAppSelector((store) => store.auth);

  // const onSignUp = wrapper(actions.onSignUp);
  // const loadPartialUser = wrapper(actions.onLoadPartialUser);
  // const resetAuth = wrapper(actions.onResetAuth);
  // const onMoveNextRegisterStep = wrapper(actions.onMoveNextRegisterStep);
  // const onRegisterFormOnChange = wrapper(actions.onRegisterFormOnChange);
  // const onInitRegisterForm = wrapper(actions.onInitRegisterForm);
  // const onSignUpGoBack = wrapper(actions.onSignUpGoBack);

  const invitation_token = searchParams.get('invitation_token');
  const { data, isFetching: isFetchingPartialUser } = useGetPartialUserQuery(
    { invitationToken: invitation_token },
    { skip: isEmpty(invitation_token) }
  );
  const [register] = useRegisterMutation();
  const { setCredentials, clearAuth } = useAuth();
  const [registerForm, setRegisterForm] = useState({
    // ...getInitialFormData(data?.user)
  });

  const handleOnMoveNextRegisterStep = async (values: RegisterForm) => {
    // const { data } = await triggerLazyGenerateUsername({
    //   firstName: values.firstName,
    //   lastName: values.lastName
    // })
    setStep(2);
  };

  const handleOnSignUpGoBack = () => {
    setStep(1);
  };

  // const handleOnRegisterFormOnChange = (user, field, value) => {
  //   setRegisterForm({
  //     ...user,
  //     [field]: value
  //   })
  // }

  const handleOnSignUp = async (user: RegisterForm) => {
    register(user)
      .unwrap()
      .then(({ token }) => {
        setCredentials({ token });
        setStep(1);
        navigate('/onboarding');
      })
      .catch((error) => {
        // setErrors(true);
        setBackendErrors(error.data.error);
        // console.error(error);
      });
  };

  // useEffect(() => {
  //   onInitRegisterForm(partialUser);
  // }, [partialUser]);

  // useEffect(() => {
  //   if (!isEmpty(invitation_token)) {
  //     loadPartialUser(invitation_token);
  //   }
  // }, [invitation_token]);

  useEffect(() => {
    clearAuth();
  }, []);

  // if (registerForm == null) {
  //   return null;
  // }

  if (isFetchingPartialUser) {
    return null;
  }

  const partialUser = data?.user;

  return (
    <NoAuthenticated>
      <SignUp
        backendErrors={backendErrors}
        onSignUpGoBack={handleOnSignUpGoBack}
        // onChange={handleOnRegisterFormOnChange}
        user={registerForm}
        step={step}
        onSignUp={(user) => handleOnSignUp(user)}
        error={errors}
        partialUser={partialUser}
        invitationToken={invitation_token}
        onMoveNextStep={handleOnMoveNextRegisterStep}
      />
    </NoAuthenticated>
  );
};

Register.displayName = 'Register';

export default withGA(Register);
