import { compact, isEmpty, map } from 'lodash';
import * as Yup from 'yup';

interface BasicHash {
  [k: string]: string;
}

type Options<T> = keyof T;

export const isRequired = <T, K extends keyof T>(
  object: T,
  field: K,
  message: string = 'is required'
) => {
  if (isEmpty(object[field])) {
    return message;
  }

  return null;
};

interface Props {
  [index: string]: string;
}

export const areRequired = <T, K extends keyof T>(
  object: T,
  fields: K[],
  message: string = 'is required'
) => {
  const m: Props = {};
  fields.forEach((f) => {
    const msg = isRequired(object, f, message);
    if (!isEmpty(msg)) {
      m[f as string] = msg;
    }
  });

  return m;
};

const passwordFieldSchema = Yup.string()
  .required('required')
  .min(6, 'Password is too short - should be 6 chars minimum.');

const SignupStepOneSchema = Yup.object().shape({
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  email: Yup.string().email('Invalid email').required('required'),
  password: passwordFieldSchema,
});

const SignupStepTwoSchema = Yup.object().shape({
  username: Yup.string().required('required'),
});

export const SignupSchema = [SignupStepOneSchema, SignupStepTwoSchema];

export const ResetPasswordSchema = Yup.object().shape({
  password: passwordFieldSchema,
  confirmPassword: Yup.string()
    .required('required')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});

export const UpdatePassswordSchema = Yup.object().shape({
  oldPassword: passwordFieldSchema,
  newPassword: passwordFieldSchema,
  repeatPassword: Yup.string()
    .required('required')
    .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.'),
});

export const CreateGratitudeSchema = Yup.object().shape({
  text: Yup.string().required('required'),
  gratitude_type: Yup.string().required('required'),
  privacy: Yup.number().required('required'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('required'),
});
