import { createDefaultSlice } from '../../utils/redux';
// import friendshipService from '../../services/friendship';
// import { isEmpty, isNull, isUndefined, size } from 'lodash';
// import gratitudesService from '../../services/gratitudes';
// import friendshipService from '../../services/friendship';
// import authService from '../../services/auth';
// import usersService from '../../services/users';
// import {
//   extractRecipints,
//   replaceEmailsWithIds,
//   gratitudeErrors,
// } from '../../utils/gratitudes';
import { GiveThanksPayload } from '../../types/model';
// import sliceActions from 'store/sliceActions';
import { AppThunk } from 'store/store';

export interface NotFriends {
  id: number;
  display_name: string;
  username: string;
  avatar_url: string;
}
export interface GiveThanksState {
  gratitude?: GiveThanksPayload;
  // textHasErrors?: boolean;
  // sendingLoading?: boolean;
  // existingUsers?: any[];
  // newEmails?: string[];
  // addNewConnectionsModalOpen?: boolean;
  // errors?: { text: any[] };
  // showInfoMessage?: boolean;
  notFriends?: NotFriends[];
}

const initialState: GiveThanksState = {
  // addNewConnectionsModalOpen: false,
};

export const giveThanksSlice = createDefaultSlice('giveThanks', initialState);

export default giveThanksSlice.reducer;

const multiSet = (map: GiveThanksState) =>
  giveThanksSlice.actions.multiSet({ map });

const onGiveThanksUpdate = (gratitude: GiveThanksPayload) => (dispatch) => {
  dispatch(
    multiSet({
      gratitude,
      // textHasErrors: false,
    })
  );
};

// const createGratitude = (text, gratitudeType, privacy, dispatch, onDone) =>
//   gratitudesService
//     .createGratitude(text, gratitudeType, privacy)
//     .then(({ gratitude, not_friends }) => {
//       // dispatch(onLoadGratitudes());
//       onDone();
//       dispatch(
//         multiSet({
//           gratitude: null,
//           textHasErrors: false,
//           sendingLoading: false,
//           notFriends: not_friends,
//         })
//       );
//       // dispatch(oldActions.layoutOnLoadTotalKarma());
//     });

// const onGiveThanksSend = (gratitude, email) => (dispatch) => {
//   if (isUndefined(gratitude) || isNull(gratitude)) {
//     dispatch(
//       multiSet({
//         textHasErrors: true,
//         errors: {
//           text: [
//             'A gratitude without text does not make too much sense, right? You have to enter some text and a recipient.',
//           ],
//         },
//         sendingLoading: false,
//       })
//     );
//     return;
//   }

//   const { text, gratitudeType, privacy } = gratitude;
//   dispatch(multiSet({ sendingLoading: true }));
//   const errors = gratitudeErrors(text, gratitudeType, email);
//   if (isEmpty(errors.text)) {
//     const [usernames, emails] = extractRecipints(text);
//     if (!isEmpty(emails)) {
//       usersService.fetchUsersByEmails(emails).then((existingUsers) => {
//         if (size(existingUsers) === size(emails)) {
//           const newThanksText = replaceEmailsWithIds(text, existingUsers);
//           createGratitude(
//             newThanksText,
//             gratitudeType,
//             privacy,
//             dispatch,
//             onDone
//           );
//         } else {
//           dispatch(
//             multiSet({
//               existingUsers,
//               newEmails: emails,
//               addNewConnectionsModalOpen: true,
//             })
//           );
//         }
//       });
//     } else {
//       createGratitude(text, gratitudeType, privacy, dispatch, onDone);
//     }
//   } else {
//     dispatch(
//       multiSet({
//         textHasErrors: true,
//         errors,
//         sendingLoading: false,
//       })
//     );
//   }
// };

// const onFetchShowMessage = () => (dispatch) => {
//   authService.fetchAccountProperties().then(({ account_properties }) => {
//     dispatch(
//       multiSet({
//         showInfoMessage: account_properties.show_message,
//       })
//     );
//   });
// };

// const onDismissMessage = () => (dispatch) => {
//   authService.updateAccountProperties({ show_message: false }).then(() => {
//     // dispatch(oldActions.authOnLoadAccountProperties());
//     dispatch(
//       multiSet({
//         showInfoMessage: false,
//       })
//     );
//   });
// };

// const fetchUsers = (emails) => (dispatch) => {
//   usersService.fetchUsersByEmails(emails).then((users) => {
//     dispatch(
//       multiSet({
//         existingUsers: users,
//       })
//     );
//   });
// };

// const onAddNewConnectionsModalClose = () => (dispatch) => {
//   dispatch(
//     multiSet({
//       addNewConnectionsModalOpen: false,
//       sendingLoading: false,
//     })
//   );
// };

// const onAddNewConnectionsModalSave =
//   (partialUsers, existingUsers, gratitude, email) => (dispatch) => {
//     const { text, gratitudeType } = gratitude;
//     usersService
//       .createParialUsers(partialUsers)
//       .then((partialUsersPersisted) => {
//         let newThanksText = replaceEmailsWithIds(text, partialUsersPersisted);
//         newThanksText = replaceEmailsWithIds(newThanksText, existingUsers);

//         const newGratitude = {
//           ...gratitude,
//           text: newThanksText,
//         };

//         // dispatch(onGiveThanksSend(newGratitude, email));
//         dispatch(
//           multiSet({
//             addNewConnectionsModalOpen: false,
//             newEmails: null,
//           })
//         );
//       });
//   };

// const onRequestFriendship =
//   (user: NotFriends): AppThunk =>
//     async (dispatch, getState) => {
//       await friendshipService.addFriend(user.id);

//       const { notFriends } = getState().giveThanks;
//       const notFriendsUpdate = notFriends.filter((u) => u.id != user.id);

//       dispatch(sliceActions.alerts.addAlert('Friendship request sent'));
//       dispatch(
//         multiSet({
//           notFriends: notFriendsUpdate,
//         })
//       );
//     };

// const onCloseRequestFriendships = (): AppThunk => (dispatch) => {
//   dispatch(
//     multiSet({
//       notFriends: [],
//     })
//   );
// };

const setNotFriends =
  (notFriends: NotFriends[]): AppThunk =>
  (dispatch) => {
    dispatch(
      multiSet({
        notFriends,
      })
    );
  };

export const actions = {
  onGiveThanksUpdate,
  // onGiveThanksSend,
  // onFetchShowMessage,
  // onDismissMessage,
  // fetchUsers,
  // onAddNewConnectionsModalClose,
  // onAddNewConnectionsModalSave,
  // onRequestFriendship,
  // onCloseRequestFriendships,
  setNotFriends,
};
