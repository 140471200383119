import React, { useState } from 'react';
import Container from '@mui/material/Container';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
// import RssFeedIcon from '@mui/icons-material/RssFeed';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';

import { formatNumberShort } from '../../utils/numbers';
import { getkarmaColor } from '../../utils/karma';
import { APP_NAME } from '../../utils/constants';
// import logo from './justLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty, isFunction, random } from 'lodash';
import LayoutDrawer from './LayoutDrawer';
import Avatar from '../../components/Avatar/Avatar';
// import { formatContent } from '../../utils/gratitudes';
// import KarmaChip from '../../components/KarmaChip/KarmaChip';
import MenuNotifications from './MenuNotifications';
// import { Notification } from 'types/model';
import logo from '../../assets/topnav-logo.png';
import {
  ChipKarma,
  ChipGiving,
  ChipReceiving,
} from '../../components/Styled/ChipStyled/ChipStyled';
import SearchPeople from 'components/SearchPeople/SearchPeople';
import { useMarkNotificationsMutation } from 'features/api/notificationsSlice';
import { CurrentUser } from 'features/api/types';
import Spinner from 'components/Spinner/Spinner';
import DesktopMenu from './DesktopMenu';
// (${ formatNumberShort(props.connectionsCount) })
const pages = [
  ['Activity', '/', <HomeIcon key="home-icon" />],
  ['Friends', '/friends', <GroupIcon key="group-icon" />],
];

interface LayoutMenuProps {
  onLogout: () => void;
  currentUser: CurrentUser;
  // totalReceiving: number;
  // totalGiving: number;
  // displayName: string;
  // connectionsCount: number;
  // avatarUrl: string;
  // notifications: Notification[];
  // notificationsCount: number;
  // onMarkNotifications: () => void;
  // karmaScore: number;
  onChipClick: () => void;
  authenticated: boolean;
  // onLoadNavigations: () => void;
}

const LayoutMenu: React.FC<LayoutMenuProps> = (props) => {
  const {
    onLogout,
    // totalReceiving,
    // totalGiving,
    // displayName,
    // avatarUrl,
    // notifications,
    // notificationsCount,
    // karmaScore,
    // onMarkNotifications,
    onChipClick,
    authenticated,
    currentUser,
    // onLoadNavigations,
  } = props;
  const navigate = useNavigate();
  const [naviationsAnchorEl, setNavigationsAnchorEl] = useState(null);
  const [openNavigation, setOpenNavigation] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [markNotification, { isLoading }] = useMarkNotificationsMutation();
  // const [karmaLabel, karmaScore] = karmaLevel(totalGiving, totalReceiving);
  // const karmaColor = getkarmaColor(karmaScore);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (url) => {
    setAnchorElNav(null);
    navigate(url);
  };

  const handleClickAccount = () => {
    setAnchorElNav(null);
    navigate('/account');
  };

  const handleClose = () => {
    setOpenNavigation(false);
    setNavigationsAnchorEl(null);
    markNotification();
  };

  const handleOpenNotifications = (event) => {
    setNavigationsAnchorEl(event.currentTarget);
    setOpenNavigation(true);
  };

  const handleOnPeopleSelected = (user) => {
    navigate(`/u/${user.username}`);
  };

  if (!currentUser) {
    return null;
  }

  return (
    <AppBar position="static" style={{ boxShadow: 'none' }}>
      {/* <Box sx={{ mr: 1, ml: 1 }}> */}
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Toolbar disableGutters style={{ textAlign: 'center' }}>
            <LayoutDrawer
              pages={pages}
              onSetAnchorElNav={() => setAnchorElNav(null)}
              onCloseMenu={handleCloseNavMenu}
              onOpenMenu={handleOpenNavMenu}
              anchorElNav={anchorElNav}
              // karmaColor={karmaColor}
              karmaScore={currentUser.karma_score}
              totalGiving={currentUser.total_giving}
              onAccountClick={handleClickAccount}
              totalReceiving={currentUser.total_receiving}
              displayName={currentUser.display_name}
              onLogout={onLogout}
            />

            <DesktopMenu
              handleCloseNavMenu={handleCloseNavMenu}
              onChipClick={onChipClick}
              pages={pages}
              authenticated={authenticated}
              onLogout={onLogout}
              currentUser={currentUser}
              handleOpenNotifications={handleOpenNotifications}
            />

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'right',
                marginRight: 2,
              }}
            >
              <img src={logo} height="35" style={{ marginLeft: 10 }} />
            </Typography>

            {/* {authenticated && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
              }}
            >
              <SearchPeople
                placeholder="Search people"
                onAddFriend={handleOnPeopleSelected}
                showRequestFriendshipButton={false}
                textFieldProps={{
                  size: 'small',
                  style: {
                    minWidth: 500,
                  },
                }}
              />
            </Box>
          )} */}
          </Toolbar>
        </Grid>
      </Grid>
      {/* </Box> */}
      {authenticated && (
        <MenuNotifications
          openNavigation={openNavigation}
          handleClose={handleClose}
          naviationsAnchorEl={naviationsAnchorEl}
        />
      )}
    </AppBar>
  );
};

export default LayoutMenu;
