import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BoxStyled from '../Styled/BoxStyled/BoxStyled';
import { useAlert } from 'features/AlertsFeature/hook';
import { ForgotPasswordSchema } from 'utils/valiations';
import { isEmpty } from 'lodash';

const theme = createTheme();

interface ForgotPasswordProps {
  error: boolean;
  onClick: (email: string) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onClick, error }) => {
  const [schemaErrors, setSchemaErrors] = React.useState({
    email: null,
  });
  const alerts = useAlert();
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email').toString();

    ForgotPasswordSchema.validate({ email }, { abortEarly: false })
      .then(() => {
        onClick(email);
        alerts.addAlert(
          'If the email is in our database you will email with instructions about how to reset your password.'
        );
        navigate('/');
      })
      .catch((err) => {
        const errorsCollected = { email: null };
        err.inner.forEach((e) => {
          errorsCollected[e.path] = e.message;
        });
        setSchemaErrors(errorsCollected);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <BoxStyled
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Forgot password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={error || !isEmpty(schemaErrors.email)}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            helperText={schemaErrors.email}
            sx={{
              '.MuiInputBase-root': {
                backgroundColor: 'white',
              },
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send
          </Button>
          <Grid container>
            <Grid item xs>
              <div>Already have an account?</div>
              <Link to="/">Sign in</Link>
            </Grid>
          </Grid>
        </Box>
      </BoxStyled>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
  );
};

export default ForgotPassword;
