import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
// import ALink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import BoxStyled from '../Styled/BoxStyled/BoxStyled';
// import faker from '@faker-js/faker';
// import usersService from '../../services/users';
import { HStack } from 'components/Stacks/Stacks';
// import { ValidationGroup, Validate, AutoDisabler } from 'mui-validate';
import { SignupSchema } from 'utils/valiations';
import { RegisterForm } from 'store/slices/auth';
import { useFormik } from 'formik';
import FormikTextField from 'components/FormikTextField/FormikTextField';
import { isEmpty } from 'lodash';
import { useLazyGenerateUsernameQuery } from 'features/api/userSlice';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

// const theme = createTheme();

// const getInitialFormData = (partialUser) => {
//   if (partialUser) {
//     return {
//       firstName: partialUser.first_name,
//       lastName: partialUser.last_name,
//       email: partialUser.email,
//     };
//   }

//   if (process.env.NODE_ENV == 'development' && !partialUser) {
//     return {
//       firstName: faker.name.firstName(),
//       lastName: faker.name.lastName(),
//       email: faker.internet.email(),
//       password: 'pass1234',
//     };
//   }
// };

const SignUp = ({
  onSignUp,
  error,
  partialUser,
  invitationToken,
  step,
  onMoveNextStep,
  user,
  // onChange,
  onSignUpGoBack,
  backendErrors,
}) => {
  const [triggerLazyGenerateUsername] = useLazyGenerateUsernameQuery();
  const formik = useFormik<RegisterForm>({
    initialValues: {
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phoneNumber: '',
      username: '',
      ...user,
    },
    onSubmit: (values, { setSubmitting }) => {
      const userPayload = {
        id: null,
        email: values.email,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.phoneNumber,
        username: values.username,
      };

      if (partialUser) {
        userPayload.id = partialUser.id;
      }

      onSignUp(userPayload);
      setSubmitting(false);
    },
    validationSchema: SignupSchema[step - 1],
    validateOnChange: true,
  });

  // useEffect(() => {
  //   if (!isEmpty(user.username)) {
  //     if (isEmpty(formik.values.username)) {
  //       formik.setFieldValue('username', user.username);
  //     }
  //   }
  // }, [user.username]);

  useEffect(() => {
    if (!isEmpty(backendErrors)) {
      formik.setErrors({
        ...formik.errors,
        ...backendErrors,
      });
    }
  }, [backendErrors]);

  useEffect(() => {
    if (partialUser) {
      formik.setValues({
        email: partialUser.email,
        firstName: partialUser.first_name,
        lastName: partialUser.last_name,
        password: '',
      });
    }
  }, [partialUser]);

  if (invitationToken && !partialUser) {
    return <CircularProgress />;
  }

  // const handleOnChange = (field) => (event) => {
  //   onChange(user, field, event.target.value);
  // };

  const handleOnMoveNext = async () => {
    formik
      .setTouched({
        firstName: true,
        lastName: true,
        email: true,
        password: true,
      })
      .then(async (errors) => {
        if (isEmpty(errors)) {
          const { firstName, lastName } = formik.values;
          const {
            data: { username },
          } = await triggerLazyGenerateUsername({ firstName, lastName });

          formik.setFieldValue('username', username);

          onMoveNextStep(formik.values);
        }
      });
  };

  const handlePhoneNumberChange = (value) => {
    formik.setFieldValue('phoneNumber', value);
  };

  // console.log(formik.values);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <BoxStyled
        disabled={formik.isSubmitting}
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 3 }}
        >
          {partialUser && (
            <Alert sx={{ marginBottom: 3 }} severity="info">
              <div>Another users has sent you some gratitude.</div>
              <div>
                Sign up to have access to your account and send and recive
                gratitude to others.
              </div>
            </Alert>
          )}
          {/* <ValidationGroup> */}
          <Grid container spacing={2}>
            {step === 1 && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    tabIndex={2}
                    backendErrors={backendErrors}
                    formik={formik}
                    formikField="firstName"
                    error={error}
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    tabIndex={1}
                    backendErrors={backendErrors}
                    formik={formik}
                    formikField="lastName"
                    error={error}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    backendErrors={backendErrors}
                    formik={formik}
                    formikField="email"
                    error={error}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    InputProps={{
                      readOnly: !!partialUser,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    backendErrors={backendErrors}
                    formik={formik}
                    formikField="password"
                    error={error}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    // disabled={!formik.isValid}
                    tabIndex={1}
                    onClick={handleOnMoveNext}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Next
                  </Button>
                </Grid>
              </>
            )}
            {step === 2 && (
              <>
                <Grid item xs={12}>
                  <FormikTextField
                    backendErrors={backendErrors}
                    formik={formik}
                    formikField="username"
                    required
                    error={error}
                    fullWidth
                    name="username"
                    label="Username"
                    id="username"
                    // defaultValue={user.username}
                    // onChange={handleOnChange('username')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      fontSize: 12,
                      color: 'gray',
                      textAlign: 'left',
                      marginBottom: 10,
                    }}
                  >
                    We use the phone number, to allow people locate you in
                    multiple ways. It&apos;s optional.
                  </div>
                  {/* <FormikTextField
                    backendErrors={backendErrors}
                    formik={formik}
                    formikField="phoneNumber"
                    error={error}
                    fullWidth
                    name="phoneNumber"
                    label="Phone Number"
                    id="phoneNumber"
                  // defaultValue={user.phoneNumber}
                  // onChange={handleOnChange('phoneNumber')}
                  /> */}

                  {/* <InputMask */}
                  <InputMask
                    mask="(1)999-999-9999"
                    // value={this.state.phone}
                    onChange={(e) =>
                      handlePhoneNumberChange(e.currentTarget.value)
                    }
                    disabled={false}
                    maskChar=" "
                  >
                    <TextField
                      fullWidth
                      name="phoneNumber"
                      label="Phone Number"
                      id="phoneNumber"
                    />
                    {/* 
                    <FormikTextField
                      backendErrors={backendErrors}
                      formik={formik}
                      formikField="phoneNumber"
                      error={error}
                      fullWidth
                      name="phoneNumber"
                      label="Phone Number"
                      id="phoneNumber"
                    /> */}
                  </InputMask>
                </Grid>

                <HStack flexGrow={1}>
                  <Button
                    onClick={onSignUpGoBack}
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign Up
                  </Button>
                </HStack>
              </>
            )}
          </Grid>
          {/* </ValidationGroup> */}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/">Already have an account? Sign in</Link>
            </Grid>
          </Grid>
        </Box>
      </BoxStyled>
    </Container>
  );
};

export default SignUp;
