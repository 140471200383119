import React, { ReactNode } from 'react';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Header from 'components/Header/Header';

interface PageProps {
  header: string;
  breadcrumb?: string;
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ header, breadcrumb, children }) => {
  if (!breadcrumb) {
    breadcrumb = header;
  }

  return (
    <div>
      <Breadcrumbs page={breadcrumb} />
      <Header>{header}</Header>
      {children}
    </div>
  );
};

export default Page;
