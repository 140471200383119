import { createDefaultSlice } from '../../utils/redux';
import { AlertColor } from '@mui/material';
import { isArray } from 'lodash';
import { AppThunk } from 'store/store';

export interface Alert {
  id: number;
  message: string;
  type: AlertColor;
}

export interface AlertsState {
  alerts: Alert[];
}

const initialState: AlertsState = {
  alerts: [],
};

export const alertsSlice = createDefaultSlice('alerts', initialState);

export default alertsSlice.reducer;

const multiSet = (map: AlertsState) => alertsSlice.actions.multiSet({ map });

let alertsIds = 0;

const closeAlert =
  (idx: number): AppThunk =>
  (dispatch, getState) => {
    const { alerts } = getState().alerts;
    const newAlerts = alerts.filter((alert) => alert.id != idx);

    dispatch(
      multiSet({
        alerts: newAlerts,
      })
    );
  };

const addAlerts =
  (alerts: Alert[]): AppThunk =>
  (dispatch) => {
    alertsIds = alertsIds + 1;

    dispatch(
      multiSet({
        alerts,
      })
    );

    alerts.forEach((alert) => {
      setTimeout(() => {
        dispatch(closeAlert(alert.id));
      }, 7000);
    });
  };

const addAlert =
  (message: string | string[], type: AlertColor = 'info') =>
  (dispatch) => {
    let messages: string[] = null;
    if (isArray(message)) {
      messages = message as string[];
    } else {
      messages = [message];
    }

    messages.forEach((msg) => {
      dispatch(addAlerts([{ id: alertsIds, message: msg, type }]));
    });
  };

export const actions = {
  addAlerts,
  addAlert,
  closeAlert,
};
