import React from 'react';

import { Alert } from '@mui/material';

import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { actions } from './slice';

const AlertsFeature = () => {
  const dispatch = useAppDispatch();
  const { alerts } = useAppSelector((store) => store.alerts);
  const { closeAlert } = actions;

  if (isEmpty(alerts)) {
    return null;
  }

  return (
    <div
      data-testid="alerts"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
      }}
    >
      {alerts.map((alert) => (
        <Alert
          onClick={() => dispatch(closeAlert(alert.id))}
          key={alert.message}
          style={{ justifyContent: 'center' }}
          severity={alert.type}
          onClose={() => dispatch(closeAlert(alert.id))}
        >
          {alert.message}
        </Alert>
      ))}
    </div>
  );
};

export default AlertsFeature;
