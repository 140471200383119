import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { isEmpty, isEqual } from 'lodash';
import { withLayout } from '../../hoc/Layout/Layout';
import './Account.css';
import withGA from '../../hoc/withGoogleAnalytics';
import { Link, Stack, TextField } from '@mui/material';
import BoxStyled from '../../components/Styled/BoxStyled/BoxStyled';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import sliceActions from '../../store/sliceActions';
import UploadAvatar from 'components/UploadAvartar/UploadAvatar';
import { VStack } from 'components/Stacks/Stacks';
import Header from 'components/Header/Header';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { Formik, Form, useFormik } from 'formik';
import { UpdatePassswordSchema } from 'utils/valiations';
import FormikTextField from 'components/FormikTextField/FormikTextField';
import {
  useGetSettingsQuery,
  useUpdatePasswordMutation,
  useUpdateSettingsMutation,
  useUploadAvatarMutation,
} from 'features/api/userSlice';
import Spinner from 'components/Spinner/Spinner';

const Value = ({ label, value }) => (
  <Grid item>
    <Typography component="dt" variant="h6">
      {label}
    </Typography>
    <Typography component="dd" variant="body2">
      {value}
    </Typography>
  </Grid>
);

const Account = (props) => {
  // const [changePasswordError, setChangePasswordError] = useState({
  //   passwordDoNotMatch: false,
  // });
  // const [changePasswordState, setChangePasswordState] = useState({
  //   oldPassword: null,
  //   newPassword: null,
  //   repeatNewPassword: null,
  // });
  // // const dispatch = useAppDispatch();
  const { data: user, isFetching } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();
  const [updatePassword] = useUpdatePasswordMutation();
  const [uploadAvatar, { isLoading: isAvatarLoading }] =
    useUploadAvatarMutation();

  // const {
  //   onUpdateSettings,
  //   onLoadSettings,
  //   onAccountReset,
  //   onUploadAvatar,
  //   onUpdatePassword,
  // } = sliceActions.account;
  // const {
  //   email,
  //   flash,
  //   avatarUrl,
  //   loading,
  //   user,
  //   firstName,
  //   lastName,
  //   accountSettings,
  // } = useAppSelector((state) => state.account);
  // const displayName = `${firstName} ${lastName}`;

  // useEffect(() => {
  //   dispatch(onAccountReset());
  //   dispatch(onLoadSettings());
  // }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      mail_notifications: !isEmpty(data.get('mail_notifications')),
    };

    // dispatch(onUpdateSettings(payload));

    await updateSettings(payload);
  };

  // const handleChangePasswordUpdate = (field) => (event) => {
  //   const newState = {
  //     ...changePasswordState,
  //     [field]: event.currentTarget.value,
  //   };
  //   setChangePasswordState(newState);

  //   if (
  //     !isEmpty(newState.newPassword) &&
  //     !isEmpty(newState.repeatNewPassword)
  //   ) {
  //     if (!isEqual(newState.newPassword, newState.repeatNewPassword)) {
  //       setChangePasswordError({
  //         passwordDoNotMatch: true,
  //       });
  //     } else {
  //       setChangePasswordError({
  //         passwordDoNotMatch: false,
  //       });
  //     }
  //   }
  // };

  // const handleOnUpdatePassword = () => {
  //   if (!changePasswordError.passwordDoNotMatch) {
  //     dispatch(
  //       onUpdatePassword(
  //         changePasswordState.oldPassword,
  //         changePasswordState.newPassword
  //       )
  //     );
  //     setChangePasswordState({
  //       oldPassword: '',
  //       newPassword: '',
  //       repeatNewPassword: '',
  //     });
  //   }
  // };

  // const updatePasswordFormik = useFormik<{ oldPassword: string, newPassword: string, repeatPassword: string }>({
  //   initialValues: {
  //     oldPassword: '',
  //     newPassword: '',
  //     repeatPassword: ''
  //   },
  //   onSubmit: (values) => {

  //   },
  //   validationSchema: UpdatePassswordSchema,
  //   validateOnBlur: true,
  //   validateOnChange: true,
  // })

  if (isFetching) {
    return <Spinner />;
  }

  const handleOnChangePasswordSubmit = async (
    { oldPassword, newPassword },
    { setSubmitting, resetForm }
  ) => {
    // dispatch(onUpdatePassword(values.oldPassword, values.newPassword));
    await updatePassword({ oldPassword, newPassword });
    setSubmitting(false);
    resetForm();
  };

  const handleOnUploadAvatar = (file: File) => {
    const imageData = new FormData();

    imageData.append('file', file, file.name);

    uploadAvatar(imageData);
  };

  return (
    <Box className="Account">
      <Breadcrumbs page="Account" />

      <Header>Account</Header>

      <BoxStyled>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Stack direction="row" spacing={2}>
            <UploadAvatar
              onUpload={handleOnUploadAvatar}
              loading={isAvatarLoading}
              avatarUrl={user.avatar_url}
              displayName={user.display_name}
            />
          </Stack>
          <Stack>
            <h1 style={{ marginBottom: 0, paddingBottom: 0 }}>
              {user.display_name}
            </h1>
            <div>
              <Link href={`/u/${user.username}`}>@{user.username}</Link>
            </div>
            <div>{user.email}</div>
            <div>{user.phone_number}</div>
            {user.account_settings && (
              <>
                <h2 style={{ textAlign: 'left' }}>Settings</h2>
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            !!user.account_settings.mail_notifications
                          }
                          name="mail_notifications"
                        />
                      }
                      label="Mail notifications"
                    />
                  </FormGroup>
                  <div style={{ textAlign: 'left', marginTop: 30 }}>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </div>
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      </BoxStyled>

      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          repeatPassword: '',
        }}
        onSubmit={handleOnChangePasswordSubmit}
        validationSchema={UpdatePassswordSchema}
        validateOnBlur
        validateOnChange
      >
        {(formik) => (
          <Form>
            <Header>Change password</Header>
            <BoxStyled marginTop={3}>
              <VStack spacing={3}>
                <div>
                  <FormikTextField
                    formik={formik}
                    formikField="oldPassword"
                    label="Old password"
                    required
                    type="password"
                    style={{ maxWidth: 400 }}
                  />
                </div>
                <div>
                  <FormikTextField
                    formik={formik}
                    formikField="newPassword"
                    label="New password"
                    required
                    type="password"
                    style={{ maxWidth: 400 }}
                  />
                </div>
                <div>
                  <FormikTextField
                    formik={formik}
                    formikField="repeatPassword"
                    label="Repeat new password"
                    required
                    type="password"
                    style={{ maxWidth: 400 }}
                  />
                </div>
                <div>
                  <Button variant="contained" type="submit">
                    Update
                  </Button>
                </div>
              </VStack>
            </BoxStyled>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

Account.displayName = 'Account';

export default withLayout(withGA(Account));
