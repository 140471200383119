import React from 'react';
import MuiPaper from '@mui/material/Paper';
import './Paper.css';

// , borderBottom: '1px solid #eee'
const Paper = ({ children }) => (
  <MuiPaper elevation={0} sx={{ mb: 3, mt: 3 }}>
    <div className="Paper">{children}</div>
  </MuiPaper>
);

export default Paper;
