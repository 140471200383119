import React from 'react';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps } from '@mui/material';
import { DefaultTheme } from '@mui/system';
import { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

LinkBehavior.displayName = 'LinkBehavior';

declare module '@mui/material/styles' {
  // interface Theme {
  //   status: {
  //     danger: React.CSSProperties['color'];
  //   };
  // }

  interface Palette {
    receiving: Palette['primary'];
    giving: Palette['primary'];
    karma: Palette['primary'];
  }
  interface PaletteOptions {
    receiving: Palette['primary'];
    giving: Palette['primary'];
    karma: Palette['primary'];
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#498BED',
    },
    // secondary: {
    //   main: '#EB8DBB',
    //   dark: '#E04C94',
    //   light: 'rgba(244, 143, 186, 0.1)',
    // },
    secondary: {
      main: '#65E080',
      dark: '#3c864c',
      light: 'rgba(101, 224, 128, 0.12)',
    },
    receiving: {
      main: '#74B9FF',
      light: 'rgba(116, 185, 255, 0.1)',
      dark: '#74B9FF',
      contrastText: '#74B9FF',
    },
    giving: {
      main: '#A29CFE',
      light: 'rgba(162, 156, 254, 0.1)',
      dark: '#A29CFE',
      contrastText: '#A29CFE',
    },
    karma: {
      main: '#FFBD47',
      light: 'rgba(255, 189, 71, 0.12)',
      dark: '#FFBD47',
      contrastText: '#FFBD47',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '.MuiPaginationItem-root.Mui-selected': {
            backgroundColor: 'rgba(101, 224, 128, 0.12)',
            color: 'secondary.dark',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          // backgroundColor: '#eee',
          opacity: 1,
          zIndex: 1000,
        },
        message: {
          marginRight: 'auto',
        },
        icon: {
          marginLeft: 'auto',
        },
        action: {
          marginLeft: 0,
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);

export const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
