import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query/react';

import layoutReducer from './slices/layout';
import authReducer from './slices/auth';
import followersReducer from './slices/followers';
import commentsReducer from './slices/comments';
import dashboardReducer from './slices/dashboard';
import connectionUserReducer from './slices/userDetails';
import accountReducer from './slices/account';
import connectionsReducer from './slices/connections';
import giveThanksReducer from '../features/GiveThanksFeature/slice';
import alertsReducer from '../features/AlertsFeature/slice';
import onboardingReducer from './slices/onboarding';
import { reducer as notificationsReducer } from './slices/notifications';
import { apiSlice } from 'features/api/apiSlice';
import { rtkUnauthorizedMiddleware } from 'utils/rtkUnauthorizedMiddleware';

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    connections: connectionsReducer,
    userDetails: connectionUserReducer,
    auth: authReducer,
    layout: layoutReducer,
    account: accountReducer,
    followers: followersReducer,
    comments: commentsReducer,
    giveThanks: giveThanksReducer,
    alerts: alertsReducer,
    onboarding: onboardingReducer,
    notifications: notificationsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(rtkUnauthorizedMiddleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
