import GratitudeList from 'components/GratitudeList/GratitudeList';
import { useGetMyGratitudeForCurrentUserQuery } from 'features/api/gratitudeSlice';
import React, { useState } from 'react';

const MyActivity = () => {
  const [page, setPage] = useState(1);
  const { data, isFetching, isLoading } = useGetMyGratitudeForCurrentUserQuery({
    page,
  });

  return (
    <GratitudeList
      actions={data?.data}
      pageCount={data?.pages}
      page={data?.page}
      onPageChange={setPage}
      isNotLoaded={isLoading}
      loading={isFetching}
    />
  );
};

export default MyActivity;
