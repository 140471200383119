import React, { ReactNode } from 'react';
// import { Chip as MuiChip } from '@mui/material'
import styled from '@emotion/styled';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

import karmaIcon from '../../../assets/karma-icon.png';
import upIcon from '../../../assets/up.png';
import downIcon from '../../../assets/down.png';

const ChipWrapperStyled = styled(Box)(() => ({
  borderRadius: 16,
  // backgroundColor: '#eee',
  padding: 7,
}));

interface ChipStyledProps {
  icon: ReactNode;
  label?: string;
  score: number;
  bgColor: string;
  scoreColor: string;
  onClick?: () => void;
  title?: string;
}

const abbreviateNumber = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

const Img = ({ src }) => (
  <img src={src} height={15} style={{ marginLeft: 5, marginTop: 5 }} />
);

const ChipStyled: React.FC<ChipStyledProps> = ({
  icon,
  label,
  score,
  bgColor,
  scoreColor,
  onClick,
  title,
}) => {
  const style = { cursor: null };

  if (onClick) {
    style.cursor = 'pointer';
  }

  return (
    <Tooltip title={title || label}>
      <Stack
        direction="row"
        justifyContent="center"
        onClick={onClick}
        style={style}
      >
        <ChipWrapperStyled bgcolor={bgColor}>
          <Stack spacing={1} direction="row">
            {icon}
            {label && <Typography>{label}</Typography>}
            <Typography>|</Typography>
            <Typography color={scoreColor}>
              {abbreviateNumber(score)}
            </Typography>
          </Stack>
        </ChipWrapperStyled>
      </Stack>
    </Tooltip>
  );
};

interface CustomChip {
  score: number;
  label?: string;
  onClick?: () => void;
  title?: string;
}

export const ChipReceiving: React.FC<CustomChip> = (props) => (
  <ChipStyled
    icon={<Img src={downIcon} />}
    title="Receiving"
    bgColor="receiving.light"
    scoreColor="receiving.main"
    {...props}
  />
);

export const ChipGiving: React.FC<CustomChip> = (props) => (
  <ChipStyled
    icon={<Img src={upIcon} />}
    title="Giving"
    bgColor="giving.light"
    scoreColor="giving.main"
    {...props}
  />
);

export const ChipKarma: React.FC<CustomChip> = (props) => (
  <ChipStyled
    icon={<Img src={karmaIcon} />}
    title="Karma"
    bgColor="karma.light"
    scoreColor="karma.main"
    {...props}
  />
);

export default ChipStyled;
