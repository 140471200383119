import React from 'react';
import { Badge, Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/Avatar/Avatar';
import { CurrentUser } from 'features/api/types';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from '../../assets/topnav-logo.png';
import {
  ChipGiving,
  ChipKarma,
  ChipReceiving,
} from 'components/Styled/ChipStyled/ChipStyled';
import { isFunction } from 'lodash';

interface DesktopMenuProps {
  currentUser: CurrentUser;
  handleOpenNotifications: (event: any) => void;
  onLogout: () => void;
  authenticated: boolean;
  pages: any[][];
  onChipClick: () => void;
  handleCloseNavMenu: (page) => void;
}

const displaySx = {
  display: {
    xs: 'none',
    md: 'flex',
    lg: 'flex',
  },
};

const pageName = (page, props) =>
  isFunction(page[0]) ? page[0](props) : page[0];

const DesktopMenu: React.FC<DesktopMenuProps> = (props) => {
  const {
    handleCloseNavMenu,
    onChipClick,
    pages,
    authenticated,
    onLogout,
    currentUser,
    handleOpenNotifications,
  } = props;
  const navigate = useNavigate();

  // <Box
  //   sx={{
  //     display: { xs: 'none', md: 'block' },
  //     color: 'black',
  //   }}
  // >
  const actionButtons = (
    <>
      <IconButton
        sx={{ p: 0, ...displaySx }}
        onClick={() => navigate('/account')}
      >
        <Avatar
          src={currentUser.avatar_url}
          name={currentUser.display_name}
          size="small"
          sx={{ display: 'inline-block' }}
        />
      </IconButton>
      <IconButton
        size="large"
        color="inherit"
        sx={{
          color: 'black',
          ml: 1,
          ...displaySx,
        }}
        onClick={handleOpenNotifications}
      >
        <Badge badgeContent={currentUser.notifications_count} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Button
        sx={{
          color: 'black',
          ...displaySx,
        }}
        onClick={onLogout}
        startIcon={<LogoutIcon />}
      >
        Logout
      </Button>
    </>
  );

  const unauthenticatedButtons = (
    <Box
      sx={{
        // display: { xs: 'none', md: 'block' },
        color: 'black',
        width: '100%',
        textAlign: 'right',
        ...displaySx,
      }}
    >
      <Button
        sx={{
          color: 'black',
        }}
        onClick={() => navigate('/')}
        startIcon={<LogoutIcon sx={{ ml: 1 }} />}
      >
        Sign in
      </Button>
    </Box>
  );

  const chips = (
    <Stack direction="row" spacing={1} sx={displaySx}>
      <ChipReceiving
        label={null}
        score={currentUser.total_receiving}
        onClick={onChipClick}
      />
      <ChipGiving
        label={null}
        score={currentUser.total_receiving}
        onClick={onChipClick}
      />
      <ChipKarma
        label={null}
        score={currentUser.karma_score}
        onClick={onChipClick}
      />
    </Stack>
  );

  // <Box sx={{ ml: 2, display: { xs: 'none', md: 'block' } }}>
  // <Box key={pageName(page, props)} sx={{ mr: 2 }}>
  const pagesLinks = pages.map((page) => (
    <Tooltip title={pageName(page, props)}>
      <IconButton
        onClick={() => handleCloseNavMenu(page[1])}
        sx={{ mr: 2, ...displaySx }}
        style={{ cursor: 'pointer', color: 'black' }}
      >
        {page[2]}
      </IconButton>
    </Tooltip>
  ));

  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
            lg: 'flex',
            justifyContent: 'left',
            flexGrow: 1,
          },
        }}
      >
        <Link to="/">
          <img
            alt="logo"
            src={logo}
            height="35"
            style={{
              marginRight: 10,
            }}
          />
        </Link>
        {authenticated && chips}
      </Box>

      {/* <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
            lg: 'flex',
            justifyContent: 'left',
            flexGrow: 1,
          },
        }} */}
      {/* > */}
      {authenticated && pagesLinks}
      {authenticated && actionButtons}
      {!authenticated && unauthenticatedButtons}
      {/* </Box> */}
      {/* </Stack> */}
    </>
  );
};

export default DesktopMenu;
