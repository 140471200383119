import { Button, Stack, styled } from '@mui/material';
import { borderBottom, fontWeight } from '@mui/system';
import Avatar from 'components/Avatar/Avatar';
import { HStack } from 'components/Stacks/Stacks';
import { NotFriends } from 'features/GiveThanksFeature/slice';
import React from 'react';

const User = ({ user: { display_name, avatar_url, username } }) => (
  <div style={{ display: 'flex', flexDirection: 'row', width: 200 }}>
    <Avatar src={avatar_url} name={display_name} />
    <span style={{ marginLeft: 10, textAlign: 'left' }}>
      <div>{display_name}</div>
      <div className="Suggestion-Username">@{username}</div>
    </span>
  </div>
);

const Wrapper = styled('div')(() => ({
  textAlign: 'center',
  backgroundColor: '#eee',
  marginTop: 20,
  marginBottom: 20,
  padding: 15,
  borderRadius: 15,
  h2: {
    padding: 0,
    margin: 0,
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  li: {
    padding: 5,
    paddingBottom: 20,
    paddingTop: 20,
    textAlign: 'center',
    borderBottom: '1px solid silver',
  },
  'li:last-child': {
    borderBottom: '0px',
  },
  '.title': {
    // marginBottom: 20,
    fontWeight: 'bold',
  },
  '.MuiButton-root': {
    fontSize: 12,
  },
}));

interface RequestFriendshipProp {
  users: NotFriends[];
  onClose: () => void;
  onRequestFriendship: (user: NotFriends) => void;
}

const RequestFriendship: React.FC<RequestFriendshipProp> = ({
  users,
  onClose,
  onRequestFriendship,
}) => (
  <Wrapper>
    <div className="title">
      <h2>Looks like you are not friend with...</h2>
    </div>
    <ul>
      {users.map((user) => (
        <li key={user.id}>
          <Stack justifyContent="center">
            <HStack spacing={3} justifyContent="center">
              <User user={user} />
              <Button
                data-testid="request-friendship-button"
                variant="outlined"
                size="small"
                onClick={() => onRequestFriendship(user)}
              >
                Send friend request
              </Button>
            </HStack>
          </Stack>
        </li>
      ))}
    </ul>
    {/* <Stack justifyContent='right' style={{ flexGrow: 0 }}> */}
    <div style={{ textAlign: 'right', marginTop: 20 }}>
      <Button size="small" variant="contained" onClick={onClose}>
        Close
      </Button>
    </div>
    {/* </Stack> */}
  </Wrapper>
);

export default RequestFriendship;
