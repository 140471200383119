import React from 'react';

import withGA from '../../hoc/withGoogleAnalytics';

const Unknown = ({}) => (
  <div>
    <h2>Give Some Gratitude</h2>
    <h1>404 - Unknown page</h1>
  </div>
);

Unknown.displayName = 'Unknown';

export default withGA(Unknown);
