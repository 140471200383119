import { Button, ButtonProps, Stack, styled } from '@mui/material';
import React from 'react';
import { isMap, isString, map } from 'lodash';

type ButtonStyledProps = ButtonProps & { selected: boolean };

const ButtonStyled = styled(Button)<ButtonStyledProps>(
  ({ theme: { palette }, selected }) => ({
    backgroundColor: selected ? palette.secondary.main : 'white',
    color: selected ? 'white' : palette.secondary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: selected ? palette.secondary.dark : 'white',
      color: selected ? 'white' : palette.secondary.dark,
      boxShadow: 'none',
    },
  })
);

interface LabelOptions {
  label: string;
  index: number;
}

interface ButtonTabsProps {
  value: number;
  onChange: (value: number) => void;
  labels: string[] | LabelOptions[];
}

const ButtonTabs: React.FC<ButtonTabsProps> = ({ value, onChange, labels }) => {
  const newLabels: LabelOptions[] = map<string | LabelOptions, LabelOptions>(
    labels,
    (value, index) => {
      if (isString(value)) {
        return { label: value, index: index };
      } else {
        return value;
      }
    }
  );

  return (
    <div style={{ marginBottom: 10 }}>
      <Stack direction="row" spacing={1}>
        {map(newLabels, (label, idx) => (
          <ButtonStyled
            data-testid={`button-tab-${idx}`}
            key={label.label}
            variant="contained"
            onClick={(e) => onChange(label.index)}
            selected={value === label.index}
          >
            {label.label}
          </ButtonStyled>
        ))}
        {/* <ButtonStyled color='primary' selected={}>All Activity</ButtonStyled>
        <ButtonStyled color='primary'>My Activity</ButtonStyled>
        <ButtonStyled color='primary'>Karma</ButtonStyled> */}
      </Stack>
    </div>
  );
};

export default ButtonTabs;
