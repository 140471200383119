import { apiSlice } from './apiSlice';
import { RegisterForm } from 'store/slices/auth';

const AUTHORIZE_REGEX = /Bearer(.*)/;

export interface GetPartialUserResponse {
  user: any;
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<
      { token: string },
      { email: string; password: string }
    >({
      query: ({ email, password }) => ({
        url: `/login.json`,
        method: 'POST',
        body: { user: { email, password } },
      }),
      transformResponse: (response, meta) => ({
        // token: meta.response.headers['Authorization']
        token: meta.response.headers.get('Authorization'),
      }),
      invalidatesTags: ['Session'],
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `/logout.json`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Session'],
    }),
    register: builder.mutation<{ token: string }, RegisterForm>({
      query: (user) => ({
        url: '/signup.json',
        method: 'POST',
        body: { user },
      }),
      invalidatesTags: ['Session'],
      transformResponse: (response, meta) => ({
        // token: meta.response.headers['Authorization']
        token: meta.response.headers.get('Authorization'),
      }),
    }),
    sendForgotPassword: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: `/passwords.json`,
        method: 'POST',
        body: { user: { email } },
      }),
      invalidatesTags: ['Session'],
    }),
    resetPassword: builder.mutation<void, { password: string; token: string }>({
      query: ({ password, token }) => ({
        url: '/passwords.json',
        method: 'PUT',
        body: { user: { password, reset_password_token: token } },
      }),
      invalidatesTags: ['Session'],
    }),
    getPartialUser: builder.query<
      GetPartialUserResponse,
      { invitationToken: string }
    >({
      query: ({ invitationToken }) => ({
        url: `/users/partial?invitation_token=${invitationToken}`,
        method: 'GET',
      }),
    }),
    updateAccount: builder.mutation<void, any>({
      query: (account) => ({
        url: '/account.json',
        method: 'PUT',
        body: { account },
      }),
    }),
    validateEmail: builder.query<void, string>({
      query: (email) => ({
        url: `/users/validate_email?email=${email}`,
        method: 'GET',
      }),
    }),
    loginAs: builder.mutation<{ token: string }, { username: string }>({
      query: ({ username }) => ({
        url: '/users/login_as.json',
        method: 'POST',
        body: { id: username },
      }),
      transformResponse: (response, meta) => ({
        // token: meta.response.headers['Authorization']
        token: meta.response.headers.get('Authorization'),
      }),
      invalidatesTags: ['Session'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useSendForgotPasswordMutation,
  useResetPasswordMutation,
  useGetPartialUserQuery,
  useUpdateAccountMutation,
  useValidateEmailQuery,
  useLoginAsMutation,
} = extendedApi;
