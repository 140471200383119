import { createDefaultSlice } from '../../utils/redux';
import karmaService from '../../services/karma';
import userService from '../../services/users';
import gratitudesService from '../../services/gratitudes';
import friendshipService from '../../services/friendship';
import { GiveThanksPayload } from '../../types/model';
import sliceActions from '../sliceActions';
export interface UserDetails {
  id: number;
  avatar_url: string;
  display_name: string;
  karma_score: number;
  username: string;
  friends_count: number;
  first_name: string;
  last_name: string;
  giving_count: number;
  reciving_count: number;
  total_giving_count: number;
  total_reciving_count: number;
  are_we_friends: boolean;
  friendship_request_sent?: boolean;
  friendship_id: number;
  needs_approval: boolean;
  can_unfriend: boolean;
  can_request_friendship: boolean;
  is_me: boolean;
}

export interface UserDetailsState {
  // user: UserDetails;
  karmaData: object;
  gratitudeActions?: any[];
  gratitudePage?: number;
  gratitudesPageCount?: number;
  loading?: boolean;
  gratitude?: GiveThanksPayload;
  email?: string;
}

const initialState: UserDetailsState = {
  // user: null,
  karmaData: null,
};

export const userDetailsSlice = createDefaultSlice<UserDetailsState>(
  'userDetails',
  initialState
);

export const { multiSet, set } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;

// const userDetailsMultiSet = (map) => multiSet({ map });

// const userDetailsSet = (field, value) => set({ field, value });

// export const onLoadUser = (uid) => (dispatch) => {
//   dispatch(set({ field: 'loading', value: true }));
//   userService
//     .fetchConnection(uid)
//     .then((user) => {
//       dispatch(
//         multiSet({
//           map: {
//             user,
//             loading: false,
//           },
//         })
//       );
//     })
//     .catch(() => dispatch(userDetailsSet('loading', false)));
// };

// export const onLoadKarma = (uid, flag) => (dispatch) => {
//   karmaService.fetchLatestKarmaForUser(uid, flag).then((karmaData) => {
//     dispatch(userDetailsSet('karmaData', karmaData));
//   });
// };

// export const onGratitudesPageChange = (userId, page) => (dispatch) => {
//   gratitudesService.fetchGratitudesForUser(userId, page).then((data) => {
//     dispatch(
//       userDetailsMultiSet({
//         gratitudeActions: data.gratitudes,
//         gratitudesPage: data.page,
//         gratitudesPageCount: data.pages,
//       })
//     );
//   });
// };

// const onRequestFriendship = (userId) => async (dispatch) => {
//   await friendshipService.addFriend(userId);

//   dispatch(sliceActions.alerts.addAlert('Friendship request sent'));
// };

// const onUnfriend = (userId, friendshipId) => async (dispatch) => {
//   friendshipService.removeFriend(friendshipId).then(() => {
//     // dispatch(onLoadUser(userId));
//     dispatch(sliceActions.alerts.addAlert(`Unfriend`));
//   });
// };

// const onAcceptFriendship = (userId, friendshipId) => async (dispatch) => {
//   await friendshipService.approveFrienship(friendshipId);
//   dispatch(onLoadUser(userId));
//   dispatch(sliceActions.alerts.addAlert('Friendship request sent'));
// };

// const onGratitudeBetweenUs = (userId, currentPage) => async (dispatch) => {
//   const { gratitudes, page, pages } =
//     await gratitudesService.fetchGratitudesBetweenUserAndMe(
//       userId,
//       currentPage
//     );

//   dispatch(
//     userDetailsMultiSet({
//       gratitudeActions: gratitudes,
//       gratitudesPage: page,
//       gratitudesPageCount: pages,
//     })
//   );
// };

export const actions = {
  // onLoadUser,
  // onLoadKarma,
  // onGratitudesPageChange,
  // onRequestFriendship,
  // onUnfriend,
  // onAcceptFriendship,
  // onGratitudeBetweenUs,
};
