import GratitudeList from 'components/GratitudeList/GratitudeList';
import { useGetAllGratitudeForCurrentUserQuery } from 'features/api/gratitudeSlice';
import React, { useState } from 'react';

const AllActivity = () => {
  const [page, setPage] = useState(1);
  const { data, isFetching, isLoading } = useGetAllGratitudeForCurrentUserQuery(
    { page }
  );

  return (
    <GratitudeList
      actions={data?.data}
      pageCount={data?.pages}
      page={data?.page}
      onPageChange={setPage}
      isNotLoaded={isLoading}
      loading={isFetching}
    />
  );
};

export default AllActivity;
