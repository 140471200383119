import { createDefaultSlice } from '../../utils/redux';
import karmaService from '../../services/karma';
import usersService from '../../services/users';
// import notificationService from '../../services/notifications';
import { AppThunk } from 'store/store';

export interface LayoutState {
  // displayName?: string;
  // totalReceiving?: number;
  // totalGiving?: number;
  // karmaScore?: number;
  // connectionsCount?: number;
  // avatarUrl?: string;
  // notifications?: any[];
  // notificationsCount?: number;
  // email?: string;
  // alerts?: any[];
}

const initialState: LayoutState = {};

export const layoutSlice = createDefaultSlice('layout', initialState);

export const { multiSet, set } = layoutSlice.actions;

const u = multiSet;

export default layoutSlice.reducer;

const layoutdMultiSet = (map: LayoutState) => multiSet({ map });

// export const onLoadTotalKarma = () => (dispatch) => {
//   karmaService.ferchTotalKarma().then((data) => {
//     dispatch(
//       layoutdMultiSet({
//         email: data.email,
//         displayName: data.display_name,
//         totalReceiving: data.total_receiving,
//         totalGiving: data.total_giving,
//         connectionsCount: data.connections_count,
//         notificationsCount: data.notifications_count,
//         notifications: data.notifications,
//         karmaScore: data.karma_score,
//         avatarUrl: data.avatar_url,
//       })
//     );
//   });
// };

// export const onMarkNotifications = () => (dispatch) => {
//   usersService.markNotifications().then(() => {
//     dispatch(onLoadTotalKarma());
//   });
// };

// export const addAlert = (message: string) => (dispatch) => {
//   dispatch(
//     layoutdMultiSet({
//       alerts: [message],
//     })
//   );

//   setTimeout(() => {
//     dispatch(
//       layoutdMultiSet({
//         alerts: [],
//       })
//     );
//   }, 2000);
// };

// const onLoadNavigations = (): AppThunk => async (d) => {
//   const { notifications, notifications_count: notificationsCount } =
//     await notificationService.fetchLatestNotifications();

//   d(layoutdMultiSet({ notifications, notificationsCount }));
// };

export const actions = {
  // onLoadTotalKarma,
  // onMarkNotifications,
  // onLoadNavigations,
};
