import { UserDetails } from 'store/slices/userDetails';
import { User } from 'types/model';
import { apiSlice } from './apiSlice';
import { CurrentUser, ListResponse } from './types';

export interface UserListItem {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  display_name: string;
  avatar_url: string;
  username: string;
  are_users_friends: string;
}

export interface UserSearchResponse {
  users: UserListItem[];
}

export interface AccountSettings {
  mail_notifications: boolean;
  show_message: boolean;
}
export interface UserSettingsResponse {
  account_settings: AccountSettings;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string;
  username: string;
  display_name: string;
  phone_number: string;
}
export interface PartialUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export interface CreatePartialUserResponse {
  users: PartialUser[];
}

export interface TopFriendsResponse {
  users: User[];
}

export interface MetricsResponse {
  total_giving: number;
  total_receiving: number;
  giving_between_us: number;
  receiving_between_us: number;
  from_user_display_name: string;
  to_user_display_name: string;
  total_karma: string;
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => '/karma/total.json',
      providesTags: ['Account', 'Karma', 'User', 'CurrentUser'],
    }),
    getUser: builder.query<{ user: UserDetails }, { userId: string }>({
      query: ({ userId }) => `/users/${userId}.json`,
      providesTags: ['User'],
    }),
    searchUsers: builder.query<UserSearchResponse, { query: string }>({
      query: ({ query }) => `/users.json?q=${query}`,
      providesTags: ['User'],
    }),
    getSettings: builder.query<UserSettingsResponse, void>({
      query: () => '/users/setting.json',
      providesTags: ['Account'],
    }),
    generateUsername: builder.query<
      { username: string },
      { firstName: string; lastName: string }
    >({
      query: ({ firstName, lastName }) =>
        `/users/generate_username?first_name=${firstName}&last_name=${lastName}`,
    }),
    searchFriends: builder.query<UserSearchResponse, { query: string }>({
      query: ({ query }) => `/users/search.json?q=${query}`,
      providesTags: ['User'],
    }),
    getUsersByEmails: builder.query<UserSearchResponse, { emails: string[] }>({
      query: ({ emails }) => `/users.json?emails=${emails.join(',')}`,
      providesTags: ['User'],
    }),
    updatePassword: builder.mutation<
      void,
      { oldPassword: string; newPassword: string }
    >({
      query: ({ oldPassword, newPassword }) => ({
        url: '/users/password',
        method: 'PUT',
        body: {
          user: { old_password: oldPassword, new_password: newPassword },
        },
      }),
    }),
    unsubscribe: builder.mutation<{ email: string }, { id: string }>({
      query: ({ id }) => ({
        url: '/users/password',
        method: 'POST',
        body: { id },
      }),
    }),
    updateSettings: builder.mutation<void, any>({
      query: (payload) => ({
        url: '/users/setting',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Account'],
    }),
    updateAvatar: builder.mutation<void, { url: string }>({
      query: ({ url }) => ({
        url: '/users/avatar.json',
        method: 'POST',
        body: { url },
      }),
      invalidatesTags: ['Account', 'Karma'],
    }),
    createPartialUser: builder.mutation<
      CreatePartialUserResponse,
      { users: any }
    >({
      query: ({ users }) => ({
        url: '/users/partial_create.json',
        method: 'POST',
        body: { users },
      }),
    }),
    uploadAvatar: builder.mutation<{ avatar_url: string }, FormData>({
      query: (formData) => ({
        url: '/storage/avatar',
        method: 'PUT',
        body: formData,
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data;`,
        },
      }),
      invalidatesTags: ['Account', 'Karma'],
    }),
    getAccountProperties: builder.query<AccountSettings, void>({
      query: () => '/account.json',
      providesTags: ['Account'],
    }),
    topFriends: builder.query<TopFriendsResponse, void>({
      query: () => '/friendship/top.json',
      // providesTags: ['Account'],
    }),
    getMetrics: builder.query<
      MetricsResponse,
      { fromUserId: number; toUserId: number }
    >({
      query: ({ fromUserId, toUserId }) =>
        `/users/metrics.json?from_user_id=${fromUserId}&to_user_id=${toUserId}`,
      // providesTags: ['Account'],
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetCurrentUserQuery,
  useGetUserQuery,
  useSearchUsersQuery,
  useGetSettingsQuery,
  useGenerateUsernameQuery,
  useSearchFriendsQuery,
  useUpdatePasswordMutation,
  useUnsubscribeMutation,
  useUpdateSettingsMutation,
  useUpdateAvatarMutation,
  useCreatePartialUserMutation,
  useLazySearchUsersQuery,
  useLazyGetUsersByEmailsQuery,
  useLazySearchFriendsQuery,
  useUploadAvatarMutation,
  useLazyGenerateUsernameQuery,
  useGetAccountPropertiesQuery,
  useTopFriendsQuery,
  useGetMetricsQuery,
} = extendedApi;
