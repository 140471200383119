import { apiSlice } from './apiSlice';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLatestAllKarma: builder.query<{ karma_data: any }, void>({
      query: () => '/karma.json',
    }),
    getLatestKarmaForUser: builder.query<
      any,
      { userId: number; flag: 'ALL' | 'ME' }
    >({
      query: ({ userId, flag }) => `/karma/${userId}.json?flag=${flag}`,
      providesTags: ['Karma'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetLatestAllKarmaQuery, useGetLatestKarmaForUserQuery } =
  extendedApi;
