import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: Array<any>;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const push = (event, pageTitle, pageUrl) => {
  window.dataLayer.push({
    event,
    pageUrl,
    pageTitle,
  });
};

const pageView = (pageUrl, pageTitle) =>
  push('virtualPageview', pageUrl, pageTitle);

const withGoogleAnalytics = (Cmp) => {
  if (process.env.NODE_ENV === 'test') {
    const EmptyWrapper = (props) => {
      return <Cmp {...props} />;
    };

    return EmptyWrapper;
  }

  const Wrapper = (props) => {
    const location = useLocation();
    useEffect(() => {
      if (!window.location.href.includes('localhost')) {
        pageView(Cmp.displayName, location.pathname);
      }
    }, [location.pathname]);

    return <Cmp {...props} />;
  };

  return Wrapper;
};

export default withGoogleAnalytics;
