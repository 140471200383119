import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';

import GiveThanks from '../../components/GiveThanks/GiveThanks';
import AddNewConnectionModal from '../../components/AddNewConnectionsModal/AddNewConnectionsModal';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { actions, NotFriends } from './slice';
import { useDispachify } from '../../utils/redux';
import { isEmpty } from 'lodash';
import RequestFriendship from 'components/RequestFriendship/RequestFriendship';
import {
  useCreatePartialUserMutation,
  useGetAccountPropertiesQuery,
  useGetCurrentUserQuery,
  useLazyGetUsersByEmailsQuery,
  useTopFriendsQuery,
  useUpdateSettingsMutation,
} from 'features/api/userSlice';
import { useCreateGratitudeMutation } from 'features/api/gratitudeSlice';
import { CreateGratitudeSchema } from 'utils/valiations';
import { useAlert } from 'features/AlertsFeature/hook';
import { useRequestFriendshipMutation } from 'features/api/friendshipSlice';
import { replaceEmailsWithIds } from 'utils/gratitudes';
import { GiveThanksPayload, User } from 'types/model';
import LatestsFriends from 'components/LatestsFriends/LatestsFriends';

interface GiveThanksFeatureProps {
  // onDone: () => void;
  defaultText?: string;
}

const GiveThanksFeature: React.FC<GiveThanksFeatureProps> = ({
  // onDone,
  defaultText,
}) => {
  const [errors, setErrors] = useState([]);
  const alerts = useAlert();
  const [modalState, setModalState] = useState({
    open: false,
    emails: [],
    users: [],
  });
  const {
    // textHasErrors,
    // addNewConnectionsModalOpen,
    // newEmails,
    // existingUsers,
    // sendingLoading,
    // errors,
    // email,
    gratitude,
    // showInfoMessage,
    notFriends,
  } = useAppSelector((state) => ({
    ...state.giveThanks,
    // email: state.layout.email,
  }));
  const dispatch = useAppDispatch();
  const wrapper = useDispachify(dispatch);

  // const onGiveThanksSend = wrapper(actions.onGiveThanksSend);
  const onGiveThanksUpdate = wrapper(actions.onGiveThanksUpdate);
  // const onFetchShowMessage = wrapper(actions.onFetchShowMessage);
  // const onDismissMessage = wrapper(actions.onDismissMessage);
  // conshandleF fetchUsers = wrapper(actions.fetchUsers);
  // const onAddNewConnectionsModalClose = wrapper(
  //   actions.onAddNewConnectionsModalClose
  // );
  // const onAddNewConnectionsModalSave = wrapper(
  //   actions.onAddNewConnectionsModalSave
  // );
  // const onCloseRequestFriendships = wrapper(actions.onCloseRequestFriendships);
  // const onRequestFriendship = wrapper(actions.onRequestFriendship);
  const setNotFriends = wrapper(actions.setNotFriends);

  const { data: currentUser } = useGetCurrentUserQuery();
  const [createGratitude, { isLoading: isSendingLoading }] =
    useCreateGratitudeMutation();
  const [requestFriendship] = useRequestFriendshipMutation();
  const [createPartialUser] = useCreatePartialUserMutation();
  const [triggerGetUsersByEmail] = useLazyGetUsersByEmailsQuery();
  const [updateSettings] = useUpdateSettingsMutation();
  const { data } = useGetAccountPropertiesQuery();
  const { data: topFriends, isLoading: isLoadingTopFriends } =
    useTopFriendsQuery();

  const handleOnAddNewConnectionsModalSave = async (
    partialUser,
    existingUsers,
    gratitude,
    email
  ) => {
    const { text, gratitudeType } = gratitude;
    const { users } = await createPartialUser({ users: partialUser }).unwrap();

    let newThanksText = replaceEmailsWithIds(text, users);
    newThanksText = replaceEmailsWithIds(newThanksText, existingUsers);

    const newGratitude: GiveThanksPayload = {
      ...gratitude,
      text: newThanksText,
    };

    await createGratitude(newGratitude);

    setModalState({
      ...modalState,
      open: false,
    });
  };

  const email = currentUser?.email;

  const handleCreateGratitude = async () => {
    CreateGratitudeSchema.validate(gratitude)
      .then(async (ok) => {
        try {
          const { new_user_emails, not_friends } = await createGratitude(
            gratitude
          ).unwrap();

          if (!isEmpty(new_user_emails)) {
            setModalState({
              ...modalState,
              open: true,
              emails: new_user_emails,
            });
          } else {
            onGiveThanksUpdate({ text: '', privacy: 1 });
            setNotFriends(not_friends);
          }
        } catch (e) {
          // console.log('handleCreateGratitude.error');
          // console.log(e);
          const { new_user_emails } = e['data'];

          setModalState({
            ...modalState,
            open: true,
            emails: new_user_emails,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnRequestFriendship = async (friend: NotFriends) => {
    await requestFriendship({ userId: friend.id });
    const notFriendsUpdate = notFriends.filter((u) => u.id != friend.id);
    setNotFriends(notFriendsUpdate);
    alerts.addAlert('Friendship request sent');
  };

  const handleOnAddNewConnectionsModalClose = () => {
    setModalState({
      ...modalState,
      open: false,
    });
  };

  const handleFetchUsers = async (emails: string[]) => {
    const { data } = await triggerGetUsersByEmail({ emails });

    setModalState({
      ...modalState,
      users: data.users,
    });
  };

  const handleOnDismissMessage = async () => {
    await updateSettings({ show_message: false });
  };

  const handleOnCloseRequestFriendships = () => {
    setNotFriends([]);
  };

  const getText = (gratitude: GiveThanksPayload) => {
    if (gratitude && gratitude.text) {
      return gratitude.text;
    }

    return '';
  };

  const handleSelectTopFriend = (user: User) => {
    onGiveThanksUpdate({
      text: `${getText(gratitude)} @[${user.display_name}](${user.id})`,
    });
  };

  useEffect(() => {
    if (defaultText) {
      onGiveThanksUpdate({
        text: defaultText,
      });
    }
  }, []);

  return (
    <div>
      <LatestsFriends
        friends={topFriends?.users}
        onClick={handleSelectTopFriend}
      />

      {isEmpty(notFriends) ? (
        <GiveThanks
          gratitude={gratitude}
          onChange={onGiveThanksUpdate}
          // onSend={() => onGiveThanksSend(gratitude, email)}
          onSend={handleCreateGratitude}
          loading={isSendingLoading}
          onDismissMessage={handleOnDismissMessage}
          showMessage={data?.show_message}
          currentUser={currentUser}
        />
      ) : (
        <RequestFriendship
          users={notFriends}
          onClose={handleOnCloseRequestFriendships}
          onRequestFriendship={handleOnRequestFriendship}
        />
      )}

      <AddNewConnectionModal
        onSave={(partialUsers, existingUsers) =>
          handleOnAddNewConnectionsModalSave(
            partialUsers,
            existingUsers,
            gratitude,
            email
          )
        }
        onClose={handleOnAddNewConnectionsModalClose}
        open={modalState.open}
        emails={modalState.emails}
        fetchUsers={handleFetchUsers}
        existingUsers={modalState.users}
      />
    </div>
  );
};

export default GiveThanksFeature;
