import { styled, SxProps } from '@mui/material';
import { borderRadius, borderRight } from '@mui/system';
import React from 'react';

const FillWrapper = styled('div')(() => ({
  backgroundColor: 'rgb(255, 165, 0, 0.1)',
  padding: 10,
  borderRadius: 20,
}));

const TextWrapper = styled('div')(({ theme: { palette } }) => ({
  color: palette.error.main,
  padding: 10,
  borderRadius: 20,
}));

interface AlertMessageProps {
  type?: 'fill' | 'text';
  sx?: SxProps;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  children,
  type = 'fill',
  ...rest
}) => {
  if (type === 'fill') {
    return <FillWrapper {...rest}>{children}</FillWrapper>;
  }

  if (type == 'text') {
    return <TextWrapper {...rest}>{children}</TextWrapper>;
  }
};

export default AlertMessage;
