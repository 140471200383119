import React, { useEffect, useState } from 'react';

import { withLayout } from '../../hoc/Layout/Layout';
import withGA from '../../hoc/withGoogleAnalytics';
import Pagination from '@mui/material/Pagination';
import UserConnection from '../../components/UserConnection/UserConnection';
import BoxStyled from '../../components/Styled/BoxStyled/BoxStyled';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../store/sliceActions';
import { useDispachify } from '../../utils/redux';
import { isEmpty } from 'lodash';
import {
  useFriendshipApproveMutation,
  useFriendshipRejectMutation,
  useFriendshipRemoveMutation,
  useGetFollowersQuery,
} from 'features/api/friendshipSlice';
import Spinner from 'components/Spinner/Spinner';
import List from 'components/List/List';
import Page from 'components/Page/Page';

const Followers = () => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const { users, usersPageCount, loading } = useAppSelector(
    (state) => state.followers
  );
  const wrapper = useDispachify(dispatch);
  const actions = sliceActions.followers;

  const onRemoveFriend = wrapper(actions.onRemoveFriend);
  // const onFrienshipApprove = wrapper(actions.onFrienshipApprove);
  // const onFrienshipReject = wrapper(actions.onFrienshipReject);
  // const onPageChange = wrapper(actions.onPageChange);
  // const onLoadUsers = wrapper(actions.onLoadUsers);

  const [friendshipApprove] = useFriendshipApproveMutation();
  const [friendshpReject] = useFriendshipRejectMutation();
  const [friendshipRemove] = useFriendshipRemoveMutation();
  const { data, isFetching } = useGetFollowersQuery({ page });

  // useEffect(() => {
  //   onLoadUsers();
  // }, []);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <Page header="Followers">
      <List
        list={data}
        onPageChange={setPage}
        loading={isFetching}
        emptyMessage="No friends so far"
      >
        {(item) => (
          <UserConnection
            user={item}
            onRemoveFriend={(friendshipId) =>
              friendshipRemove({ friendshipId })
            }
            onApprove={(friendshipId) => friendshipApprove({ friendshipId })}
            onReject={(friendshipId) => friendshpReject({ friendshipId })}
          />
        )}
      </List>
    </Page>
  );
};

export default withLayout(withGA(Followers));
