import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import logo from '../../assets/topnav-logo.png';
import useWidth from 'utils/useWidth';

const Logo = (props) => {
  const width = useWidth({
    xs: 300,
    sm: 300,
    md: 300,
    lg: 300,
    xl: 300,
  });

  return <img src={logo} style={{ width, ...props }} />;
};

export default Logo;
