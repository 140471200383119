import React, { CSSProperties } from 'react';
import { Legend, LegendProps } from '@devexpress/dx-react-chart-material-ui';
import { withStyles } from '@material-ui/core/styles';

const legendStyles: CSSProperties = {
  display: 'flex',
  margin: 'auto',
  flexDirection: 'row',
};

const legendLabelStyles: CSSProperties = {
  whiteSpace: 'nowrap',
};

export const LegendRoot: React.FC<Legend.RootProps> = (props) => (
  <Legend.Root {...props} style={legendStyles} />
);

export const LegendLabel: React.FC<Legend.LabelProps> = (props) => (
  <Legend.Label {...props} style={legendLabelStyles} />
);

// export const LegendRoot = withStyles(legendStyles, { name: 'LegendRoot' })(LegendRootBase);

// export const LegendLabel = withStyles(legendLabelStyles, { name: 'LegendLabel' })(LegendLabelBase);
