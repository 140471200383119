import React from 'react';
import { Box, styled, BoxProps } from '@mui/material';
import { create } from 'lodash';

const BoxWrapper = styled(Box)(() => ({
  padding: 9,
  width: '100%',
  zIndex: 1000,
  // height: 35,
  backgroundColor: 'black',
  color: 'white',
  marginBottom: 16,
}));

const DONATE_URL = 'https://stand-with-ukraine.pp.ua/';

const InformationTopBanner: React.FC<BoxProps> = (props) => {
  return null;
  // return (
  //   <BoxWrapper {...props}>
  //     <span>
  //       <span>Support Ukraine 🇺🇦 </span>
  //       <a
  //         href={DONATE_URL}
  //         target="_blank"
  //         rel="noopener"
  //         style={{ color: '#eb8dbb' }}
  //       >
  //         <span>Help Provide Humanitarian Aid to Ukraine.</span>
  //       </a>
  //     </span>
  //   </BoxWrapper>
  // );
};

export default InformationTopBanner;

// const api = createApi({
//   basePath: 'http://localhost:3000'
// })

// const gratitudeApi = api.sub({
//   name: 'gratitude',
//   endPoints: builder => ({
//     fetchAllGratitudes: sort => builder.get('/gratitudes.json', { sort })
//   })
// })

// const usersApi = api.sub({
//   name: 'users',
//   basePath: 'http://localhost:3000',
//   endPoints: builder => ({
//     createUser: user => builder.post('/users.json', { user })
//     ...builder.resource('users')
//   })
// })

// // ...builder.resource('users'

// create: (user, params = {}) => builder.post('/users.json', { user, ...params })
// update: (id, user, params = {}) => builder.get(`/users/${id}.json`, { user, ...params })
// delete: (id, params = {}) => builder.delete(`/users/${id}.json`, params)
// get: (id, params = {}) => build.get(`/users/${id}.json`)
// find: (params = {}) => builder.get(`/users.json`, params)
