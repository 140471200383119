import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import Avatar from '../Avatar/Avatar';
import { Grid } from '@mui/material';

export const renderPeopleSuggestion = (
  suggestion,
  query,
  highlightedDisplay,
  index,
  focused
) => (
  <div>
    {suggestion.friends && (
      <div style={{ float: 'right' }}>
        <GroupIcon style={{ fontSize: 16 }} />
      </div>
    )}
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
      <Avatar
        src={suggestion.avatarUrl}
        name={suggestion.display}
        // badge={<GroupIcon style={{ fontSize: 16 }} />}
      />
      <span style={{ marginLeft: 10, textAlign: 'left', marginRight: 10 }}>
        <div>{highlightedDisplay}</div>
        <div className="Suggestion-Username">@{suggestion.username}</div>
      </span>
    </div>
  </div>
);

export const renderEmojisSuggestion = (
  suggestion,
  query,
  highlightedDisplay,
  index,
  focused
) => (
  <Grid container>
    <Grid item>{suggestion.id}</Grid>
  </Grid>
  // <div>
  //   {suggestion.friends && (
  //     <div style={{ float: 'right' }}>
  //       <GroupIcon style={{ fontSize: 16 }} />
  //     </div>
  //   )}
  //   <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
  //     <Avatar
  //       src={suggestion.avatarUrl}
  //       name={suggestion.display}
  //       // badge={<GroupIcon style={{ fontSize: 16 }} />}
  //     />
  //     <span style={{ marginLeft: 10, textAlign: 'left', marginRight: 10 }}>
  //       <div>{highlightedDisplay}</div>
  //       <div className="Suggestion-Username">@{suggestion.username}</div>
  //     </span>
  //   </div>
  // </div>
);
