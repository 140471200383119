import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Grid, Stack, styled, TextFieldProps } from '@mui/material';

import userService from '../../services/users';
import Avatar from '../../components/Avatar/Avatar';
import { isEmpty } from 'lodash';
import { useLazySearchFriendsQuery } from 'features/api/userSlice';

interface People {
  id: number;
  avatar_url: string;
  display_name: string;
  username: string;
}
interface SearchPeopleProps {
  onAddFriend: (user: People) => void;
  textFieldProps?: TextFieldProps;
  placeholder?: string;
  showRequestFriendshipButton?: boolean;
}

const OptionWrapper = styled('li')<React.HTMLAttributes<HTMLLIElement>>(() => ({
  padding: 10,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#eee',
  },
}));

const Username = styled('div')(() => ({
  fontSize: 12,
}));

const SearchPeople: React.FC<SearchPeopleProps> = ({
  textFieldProps = {},
  onAddFriend,
  placeholder = 'Search people and follow...',
  showRequestFriendshipButton = true,
}) => {
  const [added, setAdded] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly People[]>([]);
  const loading = open && options.length === 0;
  const [triggerSearchFriends] = useLazySearchFriendsQuery();

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleOnInputChange = async (event, inputValue) => {
    if (!event) {
      return;
    }
    setValue(inputValue);
    if (!isEmpty(inputValue)) {
      // const people = await userService.searchFriends(inputValue);
      const { data } = await triggerSearchFriends({ query: inputValue });
      setOptions(data.users);
    } else {
      setOptions([]);
    }
  };

  const handleOnChange = (event, inputValue) => {
    setValue('');
    inputValue && onAddFriend(inputValue);
  };

  return (
    <Autocomplete
      clearOnEscape={true}
      blurOnSelect={true}
      clearOnBlur={true}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleOnInputChange}
      onChange={handleOnChange}
      renderOption={(props, option) => (
        <OptionWrapper {...props}>
          <Grid container spacing={2} justifyContent="center">
            <Grid
              item
              xs={showRequestFriendshipButton ? 6 : 12}
              md={showRequestFriendshipButton ? 6 : 12}
            >
              <Stack direction="row" spacing={2}>
                <Avatar src={option.avatar_url} name={option.display_name} />
                <Stack>
                  <div>{option.display_name}</div>
                  <Username>@{option.username}</Username>
                </Stack>
              </Stack>
            </Grid>
            {showRequestFriendshipButton && (
              <Grid
                item
                xs={6}
                md={6}
                justifyContent="right"
                justifyItems="right"
                textAlign="right"
              >
                <Button variant="outlined" size="small">
                  Request friendship
                </Button>
              </Grid>
            )}
          </Grid>
        </OptionWrapper>
      )}
      filterOptions={(options) => options}
      isOptionEqualToValue={(option, value) =>
        option.display_name === value.display_name
      }
      getOptionLabel={(option) => option.display_name}
      options={options}
      // loading={loading}
      renderInput={(params) => {
        const p = {
          ...params,
          inputProps: {
            ...params.inputProps,
            value: value,
          },
        };
        return (
          <TextField
            {...p}
            {...textFieldProps}
            label={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {/* {params.InputProps.endAdornment} */}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default SearchPeople;
