import React, { ReactNode, useEffect } from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import LayoutMenu from './LayoutMenu';
// import ReduxAlerts from '../../components/ReduxAlerts/ReduxAlerts';
import AlertFeature from '../../features/AlertsFeature';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../store/sliceActions';
import { useDispachify } from '../../utils/redux';
import { useNavigate } from 'react-router-dom';
import { random } from 'lodash';
import InformationTopBanner from 'components/InformationTopBanner/InformationTopBanner';
import { useGetCurrentUserQuery } from 'features/api/userSlice';
import { useAuth } from 'store/slices/auth';
import { useLogoutMutation } from 'features/api/authSlice';
import { grid } from '@mui/system';
import bg from '../../assets/bg.png';
import { Box } from '@mui/material';
import Footer from '../../layouts/Footer';

interface LayoutProps {
  // xs?: number;
  // sm?: number;
  // md?: number;
  // padding
  gridProps?: GridProps;
  children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, gridProps }) => {
  // const { children } = props;

  const gridPropsMerged: GridProps = {
    ...{ xs: 12, sm: 12, md: 6 },
    ...gridProps,
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const {
  // displayName,
  // totalReceiving,
  // totalGiving,
  // karmaScore,
  // connectionsCount,
  // avatarUrl,
  // notifications,
  // notificationsCount,
  // authenticated,
  // } = useAppSelector((state) => ({
  // ...state.layout,
  // ...state.auth,
  // }));
  // const wrapper = useDispachify(dispatch);
  // const actions = sliceActions.layout;

  // const onLogout = wrapper(sliceActions.auth.onhUserSignOut);
  // const onLoadTotalKarma = wrapper(actions.onLoadTotalKarma);
  // const onMarkNotifications = wrapper(actions.onMarkNotifications);
  // const onLoadNavigations = wrapper(actions.onLoadNavigations);
  const { isAuthenticated, clearAuth } = useAuth();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [logout] = useLogoutMutation();

  // useEffect(() => {
  //   onLoadTotalKarma();
  // }, []);

  const handleChipClick = () => navigate(`/me/karma`);

  const handleOnLogout = async () => {
    clearAuth();
    await logout();
  };

  return (
    <>
      <InformationTopBanner style={{ marginBottom: 0 }} />
      <LayoutMenu
        onLogout={handleOnLogout}
        currentUser={currentUser}
        // totalReceiving={totalReceiving}
        // totalGiving={totalGiving}
        // displayName={displayName}
        // connectionsCount={connectionsCount}
        // avatarUrl={avatarUrl}
        // notifications={notifications}
        // notificationsCount={notificationsCount}
        // onMarkNotifications={onMarkNotifications}
        // karmaScore={karmaScore}
        onChipClick={handleChipClick}
        authenticated={isAuthenticated}
        // onLoadNavigations={onLoadNavigations}
      />
      <Box
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          width: '100%',
          height: '100%',
        }}
      >
        <Grid
          container
          // spacing={2}
          justifyContent="center"
          // style={{ marginTop: 5 }}
          sx={{
            padding: { xs: 1.5, md: 0 },
          }}
        >
          <Grid item {...gridPropsMerged}>
            <AlertFeature />
            {children}
            <Footer/>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

// interface withLayoutProps {
//   Cmp: React.FC,
//   options: { xs: number, sm: number, md: number }
//   LayoutComp: React.FC
//

export const withLayout = (
  Cmp,
  options: LayoutProps = {},
  LayoutComp = Layout
) => {
  const LayoutWrapper = (props) => (
    <LayoutComp {...options}>
      <Cmp {...props} />
    </LayoutComp>
  );

  return LayoutWrapper;
};

export default Layout;
