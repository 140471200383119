import React, { ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import withParams from 'hoc/withParams';
import MuiStack from '@mui/material/Stack';
import { withLayout } from '../../hoc/Layout/Layout';
import KarmaChart from '../../components/KarmaChart/KarmaChart';
import TabPanel from '../../components/TabPanel/TabPanel';
import { getkarmaColor } from '../../utils/karma';
import './UserDetails.css';
import withGA from '../../hoc/withGoogleAnalytics';
import ButtonTabs from '../../components/ButtonTabs/ButtonTabs';
import {
  ChipKarma,
  ChipGiving,
  ChipReceiving,
} from '../../components/Styled/ChipStyled/ChipStyled';
import Avatar from '../../components/Avatar/Avatar';
import Spinner from '../../components/Spinner/Spinner';
import Stack from '@mui/material/Stack';
import GratitudeList from '../../components/GratitudeList/GratitudeList';
// import GiveThanks from '../../components/GiveThanks/GiveThanks';
import GiveThanksFeature from '../../features/GiveThanksFeature';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import sliceActions from '../../store/sliceActions';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { HStack } from 'components/Stacks/Stacks';
import useTitle from 'hooks/useTitle';
import { UserDetails } from 'store/slices/userDetails';
import TotalActivityPanel from './TotalActivityPanel';
import ActivityBetweenUsePanel from './ActivityBetweenUsPanel';
import TotalKarmaChartsPanel from './TotalKarmaChartsPanel';
import KarmaBetweenUsPanel from './KarmaBetweenUsPanel';
import { useAlert } from 'features/AlertsFeature/hook';
import {
  useFriendshipApproveMutation,
  useFriendshipRemoveMutation,
  useRequestFriendshipMutation,
} from 'features/api/friendshipSlice';
import { useGetUserQuery } from 'features/api/userSlice';
import { useLoginAsMutation } from 'features/api/authSlice';
import { useAuth } from 'store/slices/auth';
import { Grid } from '@mui/material';
import { plural } from 'utils/strings';
import { isEmpty } from 'lodash';

const ConnectionUser = (props) => {
  const { setCredentials } = useAuth();
  const navigate = useNavigate();
  const setTitle = useTitle();
  // const { sendingLoading } = props;
  // const dispatch = useAppDispatch();
  // const {
  // onLoadUser,
  // onLoadKarma,
  // onGratitudesPageChange,
  // onRequestFriendship,
  // onUnfriend,
  // onAcceptFriendship,
  // onGratitudeBetweenUs,
  // } = sliceActions.userDetails;
  // const { onLoginAs } = sliceActions.auth;

  // const {
  // user,
  // karmaData,
  // gratitudeActions,
  // gratitudesPageCount,
  // loading,
  // gratitude,
  // email,
  // authenticated,
  // } = useAppSelector((state) => ({
  // ...state.userDetails,
  // ...state.auth,
  // }));
  const { isAuthenticated } = useAuth();
  const params = useParams();
  const { id, tab } = params;
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  // const [tabValue, setTabValue] = useState(0);
  const [showSendGratitude, setShowSendGratitude] = useState(false);
  const alerts = useAlert();
  const [approveFriendship] = useFriendshipApproveMutation();
  const [removeFriendship] = useFriendshipRemoveMutation();
  const [requestFriendship] = useRequestFriendshipMutation();
  const { data, isFetching } = useGetUserQuery({ userId: id });
  const [loginAs] = useLoginAsMutation();

  if (isFetching) {
    return <Spinner />;
  }

  const user = data?.user;

  const PAGE_FOR_TAB = {
    karma: 2,
  };

  const tabValue = isEmpty(tab) ? 0 : PAGE_FOR_TAB[tab];

  // useEffect(() => {
  //   id;
  //   dispatch(onLoadUser(id));
  //   if (tabValue === 2 || tabValue === 2) {
  //     dispatch(onLoadKarma(id, getFlag(tabValue)));
  //   }
  //   dispatch(onGratitudesPageChange(id, 1));
  // }, [id]);

  // const getFlag = (value) => {
  //   if (value === 2) {
  //     return 'ALL';
  //   }
  //   if (value === 3) {
  //     return 'ME';
  //   }
  // };

  const friendshipButtons = (user: UserDetails): ReactNode => {
    if (user.is_me) {
      return null;
    }

    if (user.can_unfriend) {
      return (
        <Button
          size="small"
          disabled={user.friendship_request_sent}
          variant="outlined"
          onClick={handleUnfriend}
        >
          Unfriend
        </Button>
      );
    }

    if (user.needs_approval) {
      return (
        <Button
          size="small"
          variant="outlined"
          onClick={handleAcceptFriendship}
        >
          Accept friend request
        </Button>
      );
    }

    if (user.friendship_request_sent) {
      return (
        <Button
          data-testid="friendship-request-sent-button"
          size="small"
          disabled
          variant="contained"
          onClick={handleRequestFriendship}
        >
          Friendship request sent
        </Button>
      );
    }

    if (user.can_request_friendship) {
      return (
        <Button
          size="small"
          variant="contained"
          onClick={handleRequestFriendship}
        >
          Request friendship
        </Button>
      );
    }

    return null;
  };

  const PAGES_FOR_TABS = {
    0: `/u/${id}`,
    2: `/u/${id}/karma`,
  };
  const handleTabChange = (newValue) => {
    navigate(PAGES_FOR_TABS[newValue]);
  };

  const handleToggleShowSendGratitude = () => {
    setShowSendGratitude(true);
  };

  if (!user) {
    return <div>Ups, user not found. That's a wrong user name.</div>;
  }

  setTitle(user.username);

  let tabOptions = null;

  if (user.are_we_friends) {
    tabOptions = [
      'Total activity',
      'Activity between us',
      'Total karma',
      'Karma between us',
    ];
  } else {
    tabOptions = [
      { label: 'Activity', index: 0 },
      { label: 'Karma', index: 2 },
    ];
  }

  const handleMustLoginMessage = () => setOpenAlertDialog(true);

  const handleCloseDialog = () => setOpenAlertDialog(false);

  const handleRequestFriendship = async () => {
    await requestFriendship({ userId: user.id });
    alerts.addAlert('Friendship request sent.');
  };

  const handleUnfriend = () => {
    removeFriendship({ friendshipId: user.friendship_id });
  };

  const handleAcceptFriendship = async () => {
    await approveFriendship({ friendshipId: user.friendship_id });
    alerts.addAlert('Friendship request sent');
  };

  const handleLoginAs = async () => {
    const { token } = await loginAs({ username: user.username }).unwrap();
    setCredentials({ token });
    navigate('/');
  };

  return (
    <Box data-testid="user-details">
      <MuiStack
        direction="column"
        spacing={2}
        justifyContent="center"
        sx={{ mb: 2, backgroundColor: 'secondary.light' }}
        padding={3}
      >
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <Avatar
            src={user.avatar_url}
            name={user.display_name}
            sx={{ width: 150, height: 150 }}
            fontSize={44}
          />
        </div>
        <ChipKarma score={user.karma_score} />
        <h1 style={{ margin: 0, padding: 0, marginTop: 15 }}>
          <span style={{ textTransform: 'capitalize' }}>{user.first_name}</span>{' '}
          <span style={{ textTransform: 'capitalize' }}>{user.last_name}</span>
        </h1>
        <Stack direction="row" justifyContent="center" spacing={4}>
          <div style={{ padding: 0 }}>@{user.username}</div>
          <div style={{ padding: 0 }}>
            {user.friends_count} {plural('friend', user.friends_count)}
          </div>
        </Stack>

        {isAuthenticated && (
          <div style={{ marginBottom: 20, marginTop: 20 }}>
            {showSendGratitude ? (
              <GiveThanksFeature
                // onDone={handleSendGratitudeDone}
                defaultText={`@[${user.display_name}](${user.id})`}
              />
            ) : (
              <HStack spacing={2} justifyContent="center">
                {friendshipButtons(user)}
                {process.env.NODE_ENV == 'development' && (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={handleLoginAs}
                  >
                    Login as
                  </Button>
                )}
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleToggleShowSendGratitude}
                >
                  Send gratitude
                </Button>
              </HStack>
            )}
          </div>
        )}
      </MuiStack>

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <MuiStack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ mb: 2 }}
          >
            {user.are_we_friends && (
              <>
                <ChipGiving score={user.giving_count} label="Between us" />
                <ChipReceiving score={user.reciving_count} label="Between us" />
              </>
            )}

            <ChipGiving score={user.total_giving_count} label="Total" />
            <ChipReceiving score={user.total_reciving_count} label="Total" />
          </MuiStack>

          <Box>
            <ButtonTabs
              value={tabValue}
              onChange={handleTabChange}
              labels={tabOptions}
            />

            <TabPanel value={tabValue} index={0}>
              <TotalActivityPanel
                userId={id}
                authenticated={isAuthenticated}
                onDenied={handleMustLoginMessage}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ActivityBetweenUsePanel
                userId={id}
                authenticated={isAuthenticated}
                onDenied={handleMustLoginMessage}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <TotalKarmaChartsPanel userId={id} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <KarmaBetweenUsPanel userId={id} />
            </TabPanel>
          </Box>

          <AlertDialog
            title="You must log in"
            body="Ups, you must sign in or sign up to perform this action."
            open={openAlertDialog}
            onClose={handleCloseDialog}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withLayout(withGA(ConnectionUser), {
  gridProps: { md: 12, paddingTop: 0 },
});
