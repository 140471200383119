import React, { useEffect } from 'react';

import withGA from '../../../hoc/withGoogleAnalytics';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../../store/sliceActions';
import { useDispachify } from '../../../utils/redux';
import NoAuthenticated from 'layouts/NoAuthenticated';
import BoxStyled from 'components/Styled/BoxStyled/BoxStyled';
import {
  Avatar as MuiAvatar,
  Button,
  Container,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UploadAvatar from 'components/UploadAvartar/UploadAvatar';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  useGetSettingsQuery,
  useUploadAvatarMutation,
} from 'features/api/userSlice';
import Spinner from 'components/Spinner/Spinner';

const Onboarding = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const wrapper = useDispachify(dispatch);
  // const { firstName, lastName, loading, avatarUrl } = useAppSelector(
  //   (store) => ({
  //     ...store.auth,
  //     ...store.onboarding,
  //   })
  // );
  // const displayName = `${firstName} ${lastName}`;
  const actions = sliceActions.onboarding;
  const [uploadAvatar, { isLoading: isAvatarLoading }] =
    useUploadAvatarMutation();
  const { data: userSettings, isFetching } = useGetSettingsQuery();
  // const onUploadAvatar = wrapper(actions.onUploadAvatar);
  // const onLoadSettingds = wrapper(actions.onLoadSettingds);

  // useEffect(() => {
  //   onLoadSettingds();
  // }, []);

  const handleOnUploadAvatar = (file: File) => {
    const imageData = new FormData();

    imageData.append('file', file, file.name);

    uploadAvatar(imageData);
  };

  if (isFetching) {
    return <Spinner />;
  }

  // if (isEmpty(firstName)) {
  //   return null;
  // }

  return (
    <NoAuthenticated>
      <Container component="main" maxWidth="xs">
        <BoxStyled
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <MuiAvatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <AccountCircleIcon />
          </MuiAvatar>
          <Typography component="h1" variant="h5">
            Upload a profile image
          </Typography>

          <UploadAvatar
            onUpload={handleOnUploadAvatar}
            loading={isAvatarLoading}
            avatarUrl={userSettings.avatar_url}
            displayName={userSettings.display_name}
          />
          <div style={{ marginTop: 20 }}>
            {isEmpty(userSettings.avatar_url) ? (
              <Button onClick={() => navigation('/')}>Skip</Button>
            ) : (
              <Button variant="contained" onClick={() => navigation('/')}>
                Next
              </Button>
            )}
          </div>
        </BoxStyled>
      </Container>
    </NoAuthenticated>
  );
};

export default withGA(Onboarding);
