import axios, { AxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';

// const DEVELOPMENT_URL = 'http://localhost:3001';
// const TEST_URL = 'http://localhost:4001';
// const PRODUCTON_URL = 'https://backend.givesomegratitude.com';

export const APPLICATION_ENV = isEmpty(process.env.REACT_APP_ENV)
  ? process.env.NODE_ENV
  : process.env.REACT_APP_ENV;

const BASE_URLS = {
  development: 'http://localhost:3001',
  test: 'http://localhost:4001',
  production: 'https://backend.givesomegratitude.com',
};

// const getBaseUrl = () => {
//   if (process.env.NODE_ENV === 'development') {
//     return DEVELOPMENT_URL
//   }
//   if (process.env.NODE_ENV === 'test') {
//     return TEST
//   }
// }

export const BASE_URL = BASE_URLS[APPLICATION_ENV];

const get = (url, config: AxiosRequestConfig = null) =>
  axios.get(`${BASE_URL}${url}`, config);

const post = (url, params = {}) => axios.post(`${BASE_URL}${url}`, params);

const update = (url, params = {}, config: AxiosRequestConfig = null) =>
  axios.put(`${BASE_URL}${url}`, params, config);

const axiosDelete = (url) => axios.delete(`${BASE_URL}${url}`);

const http = {
  get,
  post,
  update,
  delete: axiosDelete,
};

export default http;
