import React from 'react';
import ContentGrid from 'layouts/ContentGrid';
import { Box, Grid, Typography, Button, Link } from '@mui/material';
import { textAlign } from '@mui/system';
import { styled } from '@mui/material/node_modules/@mui/system';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { HStack } from 'components/Stacks/Stacks';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo/Logo';

const Paragraph = ({ children }) => (
  <Box sx={{marginBottom: 5, lineHeight: 2, fontSize: 20 }}>
    {children}
  </Box>
);

const Subtitle = ({ children }) => (
  <Typography variant='h4' marginBottom={3}>{children}</Typography>
);

const QuoteStyled = styled(Box)(({ theme }) => ({
  borderRadius: 5,
  marginBottom: 20,
  padding: 20,
  lineHeight: 1.5,
  backgroundColor: theme.palette.secondary.light,
  border: `1px solid ${theme.palette.secondary.dark}`
}));

// & quot;
const Quote = ({ children }) => (
  <QuoteStyled>
    <HStack spacing={1} justifyContent='center'>
      <FormatQuoteIcon />
      <Typography variant='h5' lineHeight={1.5}>{children}</Typography> 
      <FormatQuoteIcon />
    </HStack>
  </QuoteStyled>
);

const TopNav = () => {
  const navigate = useNavigate();
  return (        
      <HStack justifyContent='right' padding={2} spacing={2} flexGrow={1}>    
        <Box flexGrow={4} textAlign='left'>
          <Link href='/'>
            <Logo height={40} width='auto' />
          </Link>
        </Box>
        <Button variant='outlined' onClick={() => navigate('/login')}>Login</Button>
        <Button variant='contained' onClick={() => navigate('/signup')}>Sign up</Button>
      </HStack>
  );
};

const Blog = () => {
  const navigate = useNavigate();

  return (
  <>
    <ContentGrid
      boxProps={{
        bgcolor: 'secondary.light',
      }}
    >
      <TopNav />
    </ContentGrid>
    <ContentGrid
      boxProps={{
        bgcolor: 'secondary.light',
        marginBottom: 3,
        paddingTop: 5,
        paddingBottom: 5
      }}
    >
      <Typography variant='h3'>
        Here’s What Science Says About Gratitude
      </Typography>
    </ContentGrid>

    <ContentGrid boxProps={{textAlign:'left'}}>
      <Paragraph>
        During holidays such as Thanksgiving, we’re reminded to show gratitude toward friends and loved ones. While giving thanks is oftentimes thought of as a common courtesy, there are tangible, scientific benefits behind this practice. 
      </Paragraph>
      <Paragraph>
        From stronger relationships to improved health and everything in between, there’s more to gratitude than meets the eye. Here’s what science says about gratitude. 
      </Paragraph>
      <Subtitle>What is Gratitude?</Subtitle>
        <Paragraph>
          Before diving into the studies, it’s important to define what gratitude actually means. Simply put, gratitude is the act of expressing thanks for past memories, current blessings, and everyday kind deeds from others.
          At face value, a simple thank you may not seem noteworthy. In reality, these small acts of acknowledgment affect our resiliency, positive emotions, and more. For example, you may find that writing a letter brings you personal joy and satisfaction. By the same token, taking a mental note may achieve the same effect. In either case, gratitude comes in many shapes and forms. 
        </Paragraph>
        <Quote>
          Gratitude is the act of expressing thanks for past memories, current blessings, and everyday kind deeds from others.
        </Quote>
      <Subtitle>Psychological Research</Subtitle>
      <Paragraph>
        By combining their efforts, two leading psychologists led a study where participants kept daily journals on positive, negative, and neutral life events. Their findings? The group that focused on positive events had a more optimistic outlook on life.

        In a separate study, another psychologist tasked over 400 participants with writing letters of gratitude to people who deserved a thank you from months or even years earlier. These participants experienced higher spirits for as long as a month.

        Likewise, studies have shown that expressing gratitude improves relationships, employer-employee collaboration, and even fundraising productivity.

        While there are specific studies indicating that certain groups are neither helped nor hurt by keeping gratitude journals, overwhelming evidence shows that most people experience meaningful improvements in their lives by expressing thanks. 
      </Paragraph>
      <Quote>
        The group that focused on positive events had a more optimistic outlook on life
      </Quote>
      <Subtitle>Practicing Gratitude</Subtitle>
      <Paragraph>
        As part of human nature, we may feel inclined to always reach for the next best thing. By expressing gratitude, we can appreciate the people, opportunities, and things that we currently have. Like any healthy habit, however, gratitude takes practice.

        That means working gratitude into your schedule through simple yet powerful routines, such as:

        <ul>
          <li>Writing thank you notes on a monthly basis addressed to others or to yourself</li>
          <li>Taking a mental note of how you appreciate someone or something</li>
          <li>Maintaining a gratitude journal filled with the gifts that each day brings</li>
          <li>Making a point to write down three to five positive happenings each week</li>
          <li>Reflecting or praying on a nightly basis to express your appreciation</li>
          <li>Meditating to hone in on a specific word, phrase, or feeling that you appreciate</li>
        </ul>

        By embracing one or more of the practices above, you can make gratitude a consistent part of your life. In turn, you’ll feel a stronger sense of optimism and mental wellbeing. 
      </Paragraph>
      <Quote>
        Like any healthy habit, however, gratitude takes practice.
      </Quote>

      <Subtitle>Closing Remarks</Subtitle>
      <Paragraph>
        In summary, science tells us that practicing gratitude is an effective way to lift up our spirits, establish more fruitful relationships, and build a more positive day-to-day existence. While there are countless benefits behind giving thanks, practice is a key part of the process. Now that you know the science behind gratitude, take a moment to send a text, write a letter, or jot down your favorite moments from the week.

        You can also sign up for Give Some Gratitude to keep track of the people you appreciate and your own outreach efforts. Now, it’s our chance to show gratitude… Thank you for reading! 
      </Paragraph>
    </ContentGrid>
    <Box padding={5} marginBottom={20}>
      <Button 
        sx={{padding: 2, width: 200 }}
        variant='contained' 
        onClick={() => navigate('/signup')} 
      >
        Get Started
      </Button>
    </Box>
  </>
  );
};

export default Blog;