import { Box, Grid, GridProps } from '@mui/material';
import { BoxProps } from '@mui/material/node_modules/@mui/system';
import React, { ReactNode } from 'react';

interface ContentGridProps {
  children: ReactNode;
  boxProps?: BoxProps;
  gridProps?: GridProps;
}

const ContentGrid: React.FC<ContentGridProps> = ({ children, boxProps = {}, gridProps = {} }) => (
  <Box {...boxProps} >
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={12} md={6} {...gridProps}>
        {children}
      </Grid>
    </Grid>
  </Box>
);

export default ContentGrid;