import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type ButtonLinkProps = ButtonProps & { to: string };

const ButtonLink: React.FC<ButtonLinkProps> = ({ to, ...props }) => {
  const navigate = useNavigate();

  const handleClick = () => navigate(to);

  return <Button {...props} onClick={handleClick} />;
};

export default ButtonLink;
