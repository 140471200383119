import React, { ReactNode } from 'react';
import './FriendshipApproval.css';
import { isEmpty, size, take } from 'lodash';
import { Link,  styled } from '@mui/material';
import Avatar from '../Avatar/Avatar';
import { plural } from 'utils/strings';
import ButtonLink from 'components/ButtonLink/ButtonLink';
import { HStack, VStack } from 'components/Stacks/Stacks';

const List = styled('ul')(() => ({
  li: {
    paddingTop: 15,
    paddingBottom: 15,
  },
}));

interface FriendshipApprovalProps {
  friendships: any[];
  onApprove: (id: number) => void;
  onReject: (id: number) => void;
}

const toSentences = (values: ReactNode[]) => {
  if (size(values) <= 1) {
    return values;
  }

  const last = values.pop();

  const sentence = [];

  values.forEach((v) => {
    sentence.push(v);
    sentence.push(', ');
  });
  sentence[sentence.length - 1] = ' and ';
  sentence.push(last);

  return <HStack spacing={1}>{sentence}</HStack>;
};

const FriendshipApproval: React.FC<FriendshipApprovalProps> = ({
  friendships,
  onApprove,
  onReject,
}) => {
  if (isEmpty(friendships)) {
    return null;
  }

  const showFriendshipRequests = take(friendships, 3);
  const extraCount = size(friendships) > 3 ? friendships.length - 3 : null;
  const formattedFriendships = showFriendshipRequests.map((f) => (
    <HStack key={f.user.display_name}>
      <div style={{ display: 'inline-block', marginRight: 10 }}>
        <Avatar
          src={f.user.avatar_url}
          name={f.user.display_name}
          size="small"
        />
      </div>
      <div className="FriendshipApproval-Name">
        <Link href={`/u/${f.user.username}`}>{f.user.display_name}</Link>
      </div>
    </HStack>
  ));
  const friendshipsRequestSentence = toSentences(formattedFriendships);

  return (
    // <div style={{ marginTop: 20, textAlign: 'left', fontSize: 12 }}>
    <VStack spacing={1} marginTop={2} overflow='scroll' paddingBottom={2}>
      <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 12 }}>
        Friendship requests
      </div>
      <HStack marginTop={3} spacing={1} style={{ flexGrow: 1, fontSize: 14, whiteSpace:'nowrap' }}>
        {friendshipsRequestSentence}
        {'  '}
        <div>{extraCount && ` plus ${extraCount} ${plural('other', extraCount)}  `}</div>
        <div>sent you a friendship request.</div>
        <ButtonLink
          size="small"
          style={{ top: -5 }}
          to="/friends"
          variant="outlined"
        >
          {' '}
          View
        </ButtonLink>
      </HStack>
    </VStack>
  );

  // return (
  //   <>
  //     {/* <div style={{ marginBottom: 5 }}</div> */}
  //     <BoxStyled title="Friendships requests" className="FriendshipApproval" sx={{ marginBottom: 3, marginTop: 3 }}>
  //       <List>
  //         {friendships.map((f) => (
  //           <li key={f.id}>
  //             {/* <Stack direction='row'> */}
  //             <div style={{ display: 'inline-block', marginRight: 10 }}>
  //               <Avatar src={f.user.avatar_url} name={f.user.display_name} />
  //             </div>
  //             <div className="FriendshipApproval-Name">
  //               <Link to={`/u/${f.user.username}`}>{f.user.display_name}</Link>
  //             </div>
  //             <span className="FriendshipApproval-CreatedAt">
  //               {' '}
  //               requested on {timeAgo(f.created_at)}
  //             </span>
  //             <Stack direction="row" style={{ float: 'right' }}>
  //               <Button
  //                 variant="outlined"
  //                 onClick={() => onApprove(f.other_friendship_id)}
  //                 color="success"
  //               >
  //                 Approve
  //               </Button>
  //               <Button
  //                 variant="outlined"
  //                 onClick={() => onReject(f.other_friendship_id)}
  //                 style={{ marginLeft: 10 }}
  //                 color="error"
  //               >
  //                 Reject
  //               </Button>
  //             </Stack>
  //             {/* </Stack> */}
  //           </li>
  //         ))}
  //       </List>
  //     </BoxStyled>
  //     {/* <hr style={{ border: '1px solid #eee', marginBottom: 30 }}></hr> */}
  //   </>
  // );
};

export default FriendshipApproval;
