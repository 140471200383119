import { createDefaultSlice } from '../../utils/redux';
import friendshipService from '../../services/friendship';
import { actions as alertsActions } from '../../features/AlertsFeature/slice';

export interface FollowersState {
  users?: any;
  usersPageCount?: any;
  loading?: boolean;
}

const initialState: FollowersState = {};

export const followersSlice = createDefaultSlice('followers', initialState);

export const { multiSet, set } = followersSlice.actions;

export default followersSlice.reducer;

const followersMultiSet = (map) => multiSet({ map });

// export const onLoadUsers = () => (dispatch) => {
//   dispatch(followersMultiSet({ loading: true }));
//   friendshipService
//     .fetchFollowersForUser(1)
//     .then((data) => {
//       dispatch(
//         followersMultiSet({
//           users: data.users,
//           usersPageCount: data.pages,
//           usersPage: data.page,
//           loading: false,
//         })
//       );
//     })
//     .catch(() => {
//       dispatch(followersMultiSet({ loading: false }));
//     });
// };

// export const onPageChange = (page) => (dispatch) => {
//   friendshipService.fetchFollowersForUser(page).then((data) => {
//     dispatch(
//       followersMultiSet({
//         users: data.users,
//         usersPageCount: data.pages,
//         usersPage: data.page,
//       })
//     );
//   });
// };

// export const onFrienshipApprove = (friendshipId) => (dispatch) => {
//   friendshipService.approveFrienship(friendshipId).then(() => {
//     dispatch(onLoadUsers());
//     dispatch(alertsActions.addAlert('Friend request accepted'));
//   });
// };

// export const onFrienshipReject = (friendshipId) => (dispatch) => {
//   friendshipService.rejectFrienship(friendshipId).then(() => {
//     dispatch(onLoadUsers());
//     dispatch(alertsActions.addAlert('Friend request rejected'));
//   });
// };

// export const onBlockFriend = (friendshipId) => (dispatch) => {};

export const onRemoveFriend = () => (dispatch) => {};

export const actions = {
  // onLoadUsers,
  // onPageChange,
  // onFrienshipApprove,
  // onFrienshipReject,
  // onBlockFriend,
  onRemoveFriend,
};
