import { Box } from '@mui/material';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import Logo from 'components/Logo/Logo';
import AlertsFeature from 'features/AlertsFeature';
import React from 'react';

const NoAuthenticated = ({ children }) => {
  return (
    <Box paddingTop={2} bgcolor="secondary.light" height="100%">
      <AlertsFeature />
      <Logo />
      {children}
    </Box>
  );
};

export default NoAuthenticated;
