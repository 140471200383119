import React from 'react';
import { Button } from '@mui/material';

const Info = ({ onDismissMessage }) => (
  <div className="GiveThanks-Label" style={{ border: '1px solid green' }}>
    <ol style={{ margin: 0, padding: 0, marginLeft: 20, marginBottom: 20 }}>
      <li>
        Select a person or persons typing <b>@</b> and the the person first
        name, last name or username
      </li>
      <li>
        or enter the email address of the person or persons (if it&apos;s not
        registered it will be invited)
      </li>
      <li>Write a gratitude message</li>
      <li>Click Send gratitude</li>
    </ol>
    <div className="GiveThanks-Example">
      Thanks @Lisa and juan@example.com for helping me with my homework.
    </div>
    {/* Select the person you want to send gratitude typing <b>@</b> or just enter
      an email.
      <br />
      You can enter multiple emails or user and a combination of both, like:
      <br />
      <div className="GiveThanks-Example">
        Thanks @John for helping me moving to my new apartment.
      </div>
      <div className="GiveThanks-Example">
        Thanks rob@example.com for thaking care of my dog.
      </div>
      <div className="GiveThanks-Example">
        Thanks @Lisa and juan@example.com for helping me with my homework.
      </div> */}
    <Button variant="outlined" onClick={onDismissMessage}>
      Hide message
    </Button>
  </div>
);

export default Info;
