import {
  isEmpty,
  range,
  map,
  inRange,
  chunk,
  find,
  toInteger,
  take,
  last,
  takeRight,
} from 'lodash';
import moment from 'moment';

export const santizeKarmaData = (karmaData, isMobile) => {
  if (isEmpty(karmaData)) {
    return null;
  }

  const data = isMobile ? takeRight(karmaData, 8) : karmaData;

  return data.map((k) => {
    const m = moment(k.month).format('DD/M');
    return {
      ...k,
      month: m,
    };
  });
};

export const KARMA_LEVELS = [
  'Samaritan',
  'Martyr',
  'Sentinel',
  'Defender',
  'Dignitary',
  'Peacekeeper',
  'Protector',
  'Exemplar',
  'Paladin',
  'Shield of Hope',
  'Legend',
  'Hero',
  'Savior',
  'Saint',
  'Guardian',
  'Restorer',
  'Model of Selflessness',
  'Shepherd',
  'Friend of the People',
  'Champion of Justice',
  'Symbol of Order',
  'Herald of Tranquility',
  'Last, Best Hope of Humanity',
  'Savior of the Damned',
  'Buddha',
];

// export const calculateKarmaRate = (giving, reciving) => {
//   if (giving < reciving) {
//     return giving / reciving;
//   }
//   return reciving / giving;
// };

// export const karmaLevel = (giving, reciving): [string, number] => {
//   const score = Math.round(
//     calculateKarmaRate(toInteger(giving), toInteger(reciving)) * 100
//   );

//   // console.log(KARMA_LEVELS.length)
//   const chunks = Math.round(100 / KARMA_LEVELS.length);
//   // console.log('chuncks', chunks)
//   let start = 0;
//   let end = 0;
//   const label = find(KARMA_LEVELS, (value, idx) => {
//     end = start + chunks;
//     // console.log(start, end, score, inRange(start, end, score))
//     const v = inRange(score, start, end);
//     start += chunks;
//     return v;
//   });

//   return [label, score];
// };

export const getkarmaColor = (karmaScore) =>
  // const rate = calculateKarmaRate(toInteger(giving), toInteger(reciving));
  // return `rgba(255, 255, 0, ${rate})`;
  `rgba(255, 255, 0, 100)`;
