import React, { useEffect } from 'react';

import { withLayout } from '../../hoc/Layout/Layout';
import withGA from '../../hoc/withGoogleAnalytics';
import BoxStyled from '../../components/Styled/BoxStyled/BoxStyled';
import { Box, styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import AddComment from '../../components/AddComment/AddComment';
import Comment from '../../components/Comment/Comment';
import { VStack } from 'components/Stacks/Stacks';
import GratitudeAction from 'components/GratitudeAction/GratitudeAction';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../store/sliceActions';
import { useDispachify } from '../../utils/redux';
import {
  useCreateCommentMutation,
  useGetGratitudeCommentsQuery,
} from 'features/api/gratitudeSlice';
import Spinner from 'components/Spinner/Spinner';

const List = styled('ul')(() => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
  li: {
    marginBottom: 15,
    paddingBottom: 10,
    borderBottom: '1px solid #eee',
  },
  'li:last-child': {
    border: '0px',
  },
}));

const Comments = () => {
  // const dispatch = useAppDispatch();
  // const { gratitude, comments } = useAppSelector((state) => state.comments);
  const { id } = useParams();
  const gratitudeId = parseInt(id);
  // const wrapper = useDispachify(dispatch);
  // const actions = sliceActions.comments;
  // const onLoadComments = wrapper(actions.onLoadComments);
  // const onAddComment = wrapper(actions.onAddComment);

  const { data, isFetching } = useGetGratitudeCommentsQuery({ gratitudeId });
  const [createComment] = useCreateCommentMutation();

  // useEffect(() => {
  //   onLoadComments(id);
  // }, []);

  if (isFetching) {
    return <Spinner />;
  }

  const { gratitude, comments } = data;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        m: 2,
        justifyContent: 'center',
      }}
    >
      <VStack>
        <GratitudeAction action={gratitude} />
      </VStack>
      <BoxStyled sx={{ mb: 2 }}>
        <List>
          {comments &&
            comments.map((comment) => (
              <li key={comment.id}>
                <Comment comment={comment} />
              </li>
            ))}
        </List>
        <AddComment
          gratitudeId={gratitudeId}
          onAddComment={(gratitudeId, content) =>
            createComment({ gratitudeId, content })
          }
        />
      </BoxStyled>
      {/* <div
        style={{
          display: 'flex',
          margin: 'auto',
          marginTop: 10,
          marginBottom: 10,
          textAlign: 'center',
        }}
      >
        <Pagination
          count={usersPageCount}
          onChange={(e, p) => usersOnPageChange(p)}
        />
      </div> */}
    </Box>
  );
};

export default withLayout(withGA(Comments));
