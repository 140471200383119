import { Box, Button, Grid, Slide, Typography } from '@mui/material';
import React from 'react';

import cells from 'assets/cells.png';
import headerBg from 'assets/header-bg.png';
import logo from 'assets/topnav-logo.png';
import people from 'assets/people.png';
import section2Bg from 'assets/section2-bg.png';
import expectCharts from 'assets/expect-charts.png';
import expectDown from 'assets/expect-down.png';
import expectHeart from 'assets/expect-heart.png';
import expectKarma from 'assets/expect-karma.png';
import expectLogo from 'assets/expect-logo.png';
import expectPrice from 'assets/expect-price.png';
import expectUp from 'assets/expect-up.png';
import { HStack } from 'components/Stacks/Stacks';
import ButtonLink from 'components/ButtonLink/ButtonLink';
import VisibilitySensor from 'react-visibility-sensor';

const TIMEOUT = 700;

const Container = ({ children }) => (
  <Grid container justifyContent="center">
    <Grid item md={8} xs={12} sm={12}>
      {children}
    </Grid>
  </Grid>
);

const Section1 = () => (
    <Box
      minHeight={800}
      height={{ md: '100vh', sx: 'auto', sm: 'auto' }}
      style={{
        backgroundColor: 'rgba(101, 224, 128, 0.12)',
        backgroundImage: `url(${headerBg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container>
        <Box textAlign="left" padding={3}>
          <img src={logo} />
        </Box>
        <Grid container>
          <Grid item md={6} xs={12} sm={12}>
            <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={TIMEOUT}
            >
              <Box margin={3} textAlign="left">
                <Box>
                  <Typography variant="h3">
                    Show your appreciation to the people who matter most
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h5">
                    because a simple thank you goes a long way
                  </Typography>
                </Box>
              </Box>
            </Slide>

            <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={TIMEOUT}
            >
              <Box textAlign="center" margin={3} marginTop={10}>
                <HStack spacing={2}>
                  <ButtonLink
                    sx={{
                      backgroundColor: 'secondary.main',
                      color: 'white',
                      padding: 2,
                      '&:hover': {
                        backgroundColor: 'secondary.dark',
                      },
                    }}
                    size="large"
                    to="/signup"
                  >
                    Get Started
                  </ButtonLink>
                  <ButtonLink sx={{ color: 'secondary.dark' }} size="large" to="/login">
                    Login
                  </ButtonLink>
                </HStack>

                <Box textAlign='left' sx={{mt: 5}}>
                <ButtonLink 
                  sx={{
                    border:'1px solid black',
                    borderColor: 'secondary.dark',
                    color: 'secondary.dark',
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                      borderColor: 'secondary.dark',
                    },
                  }}
                  variant='outlined' 
                  size='large' 
                  to='/what-science-has-to-say-about-gratitude'
                >
                    Here’s What Science Says About Gratitude
                  </ButtonLink>
                </Box>
              </Box>
            </Slide>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={{ md: 'block', xs: 'none', sm: 'none' }}
          >
            <Slide
              direction="left"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={TIMEOUT}
            >
              <img src={cells} width={592} />
            </Slide>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

const Section2 = () => (
    <Box
      style={{
        backgroundPosition: 'right',
        backgroundImage: `url(${section2Bg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container>
        <Grid container>
          <Grid item md={6} xs={12} sm={12}>
            <Box margin={{ md: 5, xs: 0, sm: 0 }}>
              {/* <VisibilitySensor>
                {(d) => (
                  <Box>
                    <Slide
                      direction="right"
                      in={d.isVisible}
                      // mountOnEnter
                      // unmountOnExit
                      timeout={TIMEOUT}
                    >
                      <img
                        src={people}
                        style={{
                          width: '100%',
                          maxWidth: 535,
                          transition: 'opacity 500ms linear',
                          opacity: d.isVisible ? 1 : 0.25,
                        }}
                      />
                    </Slide>
                  </Box>
                )}
              </VisibilitySensor> */}
              <img
                src={people}
                style={{
                  width: '100%',
                  maxWidth: 535,
                }}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <Box margin={5} textAlign="right">
              <Typography variant="h5" lineHeight={2}>
                With Give Some Gratitude, you can thank specific people and
                groups for times when they went out of their way for you or for
                just everyday deeds. All the while, you can keep track of your
                outreach efforts to make sure that everyone gets the praise they
                deserve.
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                Discover the joy of saying thanks
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} xs={12} sm={12}>
            <Box textAlign="left" marginBottom={3}>
              <Typography variant="h5" lineHeight={2}>
                It’s easy to forget to say thank you. By joining our Give Some
                Gratitude community, you can raise your spirits high as you fill
                your life with lasting relationships centered on positivity.
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                Make every conversation count
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

const Feature = ({ text, logo }) => (
    <Box width={250} margin={3}>
      <Box>
        <img src={logo} width={55} />
      </Box>
      <Box>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );

const toGrids = (items: any[]) =>
  items.map((item) => (
    <Grid key={item[0]}item md={3}>
      <Feature text={item[0]} logo={item[1]} />
    </Grid>
  ));

const FEATURE_TEXT = [
  ['A simple, easy-to-navigate app that feels good to use', expectUp],
  [
    'Karma points that track how much gratitude you’ve given and received',
    expectKarma,
  ],
  ['The chance to earn badges as you progress', expectPrice],
  [
    'Metrics that measure how much Karma you’ve given to specific friends',
    expectDown,
  ],
  ['Seamless analytics to see how you’re trending', expectCharts],
  ['Single and group messages to say thanks while saving time', expectLogo],
  ['The opportunity to connect with fellow friends who care', expectHeart],
];
// const features1 = [
//   <Feature
// ]

const Section3 = () => (
    <Box bgcolor="rgba(101, 224, 128, 0.12)" padding={5}>
      <Container>
        <Typography variant="h4">Here’s what you can expect!</Typography>
        <Grid container justifyContent="center">
          {toGrids(FEATURE_TEXT)}
        </Grid>
      </Container>
    </Box>
  );

const Section4 = () => (
  <Box textAlign="center" padding={5} paddingBottom={20}>
    <Typography variant="h5" padding={5}>
      We invite you to sign up, show your appreciation, and Give Some Gratitude
      to loved ones and acquaintances alike.
    </Typography>
    <ButtonLink
      sx={{ backgroundColor: '#65E080', color: 'white', padding: 2 }}
      size="large"
      to="/signup"
    >
      Get Started
    </ButtonLink>
  </Box>
);

const Landing = () => (
    <Box>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </Box>
  );

export default Landing;
