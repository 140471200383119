import React, { CSSProperties } from 'react';

import { MentionsInput, Mention } from '../reactMentions';

import defaultStyles from './defaultStyles';
import defaultMentionStyles from './defaultMentionStyles';
import { renderPeopleSuggestion, renderEmojisSuggestion } from './renderes';
import {
  useLazySearchUsersQuery,
  useSearchUsersQuery,
} from 'features/api/userSlice';
import { capitalize, size } from 'lodash';

const Input = ({ onChange, text, handleOnEmojiClick, error }) => {
  const [triggerSearchUser, { isFetching }] = useLazySearchUsersQuery();
  const mentionsLoading = false;
  const handleChange = (value) => {
    onChange(value);
  };

  const fetchUsers = async (query, callback) => {
    if (size(query) > 0) {
      const { data } = await triggerSearchUser({ query });

      const newData = data?.users.map((user) => ({
        display: `${capitalize(user.first_name)} ${capitalize(user.last_name)}`,
        id: user.id,
        avatarUrl: user.avatar_url,
        username: user.username,
        friends: user.are_users_friends,
      }));

      callback(newData);
    } else {
      callback([]);
    }
  };

  let styles = {
    ...defaultStyles,
  };

  if (error) {
    styles = {
      ...styles,
      '&multiLine': {
        ...styles['&multiLine'],
        input: {
          ...styles['&multiLine']['input'],
          border: '1px solid red',
          color: 'red',
        },
      },
    };
  }

  return (
    <MentionsInput
      value={text}
      className="Mention"
      onChange={(e) => handleChange(e.target.value)}
      style={styles}
      placeholder="Enter message and tag people..."
      onEmojiClick={handleOnEmojiClick}
      data-testid="mention-textarea"
    >
      <Mention
        trigger="@"
        style={defaultMentionStyles}
        displayTransform={(id, display) => `${display}`}
        data={fetchUsers}
        renderSuggestion={renderPeopleSuggestion}
        isLoading={mentionsLoading}
      />
    </MentionsInput>
  );
};

export default Input;
