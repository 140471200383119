import { apiSlice } from './apiSlice';
import { ListResponse } from './types';
import { Notification } from 'types/model';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    markNotifications: builder.mutation<void, void>({
      query: () => ({
        url: '/users/mark_notifications',
        method: 'POST',
      }),
      invalidatesTags: ['Notification', 'CurrentUser'],
    }),
    getNotifications: builder.query<
      ListResponse<Notification>,
      { page: number }
    >({
      query: ({ page }) => `/notifications.json?page=${page}`,
      providesTags: ['Notification'],
    }),
    getLatestNotifications: builder.query<
      { notifications: Notification[]; notifications_count: number },
      void
    >({
      query: () => '/notifications/latest.json',
      // providesTags: ['Notification']
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotificationsQuery,
  useGetLatestNotificationsQuery,
  useMarkNotificationsMutation,
} = extendedApi;
