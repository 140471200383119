import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { FormikProps } from 'formik';
import { AuthErrors, RegisterForm } from 'store/slices/auth';

type FormikTextFieldProps<T> = TextFieldProps & {
  formik: FormikProps<T>;
  formikField: string;
  backendErrors?: AuthErrors;
  validateOnBlur?: boolean;
};

const FormikTextField: React.FC<FormikTextFieldProps<any>> = ({
  formik,
  formikField,
  error,
  backendErrors,
  validateOnBlur = false,
  ...props
}) => {
  const touched = formik.touched[formikField];

  const mergedErrors = {
    ...formik.errors,
    // ...backendErrors
  };

  if (!props.name) {
    props.name = formikField;
  }

  return (
    <TextField
      {...props}
      value={formik.values[formikField]}
      onBlur={validateOnBlur && formik.handleBlur}
      onChange={formik.handleChange}
      error={error || (touched && Boolean(mergedErrors[formikField]))}
      helperText={touched && mergedErrors[formikField]}
    />
  );
};

export default FormikTextField;
