import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { size, capitalize, isEqual, isEmpty, some, filter } from 'lodash';
import Stack from '@mui/material/Stack';
import { MentionsInput, Mention } from '../reactMentions';
import LoadingButton from '@mui/lab/LoadingButton';

import userService from '../../services/users';
import './GiveThanks.css';
import defaultStyles from './defaultStyles';
import defaultMentionStyles from './defaultMentionStyles';
import { extractRecipints, replaceEmailsWithIds } from '../../utils/gratitudes';
import { DEFAULT_GRATTITUDE_TYPE } from '../../utils/constants';
import { GiveThanksPayload, GratitudePrivacy } from '../../types/model';
import PrivacyDropdown from '../PrviacyDropdown/PrivacyDowndown';
import { HStack } from  'components/Stacks/Stacks';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Info from './Info';
import { renderPeopleSuggestion, renderEmojisSuggestion } from './renderes';
import Picker from 'emoji-picker-react';
import { Popover } from '@mui/material';
import {
  useLazyGetUsersByEmailsQuery,
  UserListItem,
} from 'features/api/userSlice';
import Input from './Input';
import { CurrentUser } from 'features/api/types';
import AlertMessage from 'components/AlertMessage/AlertMessage';

const neverMatchingRegex = /($a)/;

interface GiveThanksProps {
  gratitude: GiveThanksPayload;
  onChange: (value: GiveThanksPayload) => void;
  onSend: (event: any) => void;
  loading: boolean;
  onDismissMessage?: () => void;
  showMessage?: boolean;
  currentUser: CurrentUser;
}

const GiveThanks: React.FC<GiveThanksProps> = ({
  gratitude,
  onChange,
  onSend,
  loading,
  onDismissMessage,
  showMessage = false,
  currentUser,
}) => {
  const [error, setError] = useState<string>(null);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [temporaryShowMessage, setTemporaryShowMessage] = useState(false);
  // const [gratitudeText, setGratitudeText] = useState(null);
  const [gratitudeType, setGratitudeType] = useState(DEFAULT_GRATTITUDE_TYPE);
  const [privacy, setPrivacy] = useState<GratitudePrivacy>(1);
  // const [mentionsLoading, setMentionsLoading] = useState(false);
  const [triggerGetUsersByEmail] = useLazyGetUsersByEmailsQuery();
  // const { isMobile } = useDeviceDetect();
  const text = gratitude?.text ?? '';

  // useEffect(() => {
  //   fetch(
  //     'https://gist.githubusercontent.com/oliveratgithub/0bf11a9aff0d6da7b46f1490f86a71eb/raw/d8e4b78cfe66862cf3809443c1dba017f37b61db/emojis.json'
  //   )
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       setEmojis(jsonData.emojis);
  //     });
  // }, []);

  useEffect(() => {
    setTemporaryShowMessage(showMessage);
  }, [showMessage]);

  // const queryEmojis = (query, callback) => {
  //   if (query.length === 0) return;

  //   const matches = emojis
  //     .filter((emoji) => emoji.name.indexOf(query.toLowerCase()) > -1)
  //     .slice(0, 10);
  //   return matches.map(({ emoji }) => ({ id: emoji }));
  // };

  // const fetchUsers = (query, callback) => {
  //   if (size(query) > 0) {
  //     userService
  //       .search(query)
  //       .then((res) =>
  //         res.map((user) => ({
  //           display: `${capitalize(user.first_name)} ${capitalize(
  //             user.last_name
  //           )}`,
  //           id: user.id,
  //           avatarUrl: user.avatar_url,
  //           username: user.username,
  //           friends: user.are_users_friends,
  //         }))
  //       )
  //       .then(callback);
  //     setMentionsLoading(false);
  //   } else {
  //     callback([]);
  //     setMentionsLoading(true);
  //   }
  // };

  const handleTextChange = (value) => {
    // setGratitudeText(value);
    onChange({ text: value, gratitude_type: gratitudeType, privacy });
  };

  const handlePrivacyChange = (value: GratitudePrivacy) => {
    setPrivacy(value);
    onChange({
      text: gratitude.text,
      gratitude_type: gratitudeType,
      privacy: value,
    });
  };

  const handleShowMessage = () => {
    setTemporaryShowMessage(true);
  };

  const handleOnDismissMessage = () => {
    setTemporaryShowMessage(false);
    onDismissMessage();
  };

  const amIInTheList = (users: UserListItem[]) => {
    if (users) {
      return some(users, (u) => u.id == currentUser.id);
    } else {
      return false;
    }
  };

  const replaceEmailsForUsers = async (text) => {
    const [usernames, emails] = extractRecipints(text);
    if (!isEmpty(emails)) {
      const { data } = await triggerGetUsersByEmail({ emails });

      if (amIInTheList(data.users)) {
        setError('You cannot send a gratitude message to yourself');
      } else {
        setError(null);
      }

      const cleanedList = filter(data.users, (u) => (u.id = currentUser.id));

      return replaceEmailsWithIds(text, cleanedList);

      // return userService.fetchUsersByEmails(emails).then((existingUsers) => {
      //   const newText = replaceEmailsWithIds(text, existingUsers);

      //   return newText;
      // });
    }

    return new Promise((resolve, reject) => {
      resolve(text);
    });
  };

  useEffect(() => {
    if (!isEmpty(text)) {
      replaceEmailsForUsers(text).then((newText) => {
        if (!isEqual(text, newText)) {
          handleTextChange(newText);
        }
      });
    }
  }, [text]);

  const [newRecipints, existingRecipints] = extractRecipints(text);

  const notifiadedCount = newRecipints.length + existingRecipints.length;

  const info = <Info onDismissMessage={handleOnDismissMessage} />;

  const handleOnEmojiClick = (currentTarget) => {
    setShowEmojis(true);
    setEmojiAnchorEl(currentTarget);
  };

  const handleCloseEmoji = () => {
    setShowEmojis(false);
    setEmojiAnchorEl(null);
  };

  const handleOnEmojiSelect = (event, emojiObject) => {
    const newText = `${gratitude.text} ${emojiObject.emoji}`;
    // setGratitudeText(newText);
    onChange({ text: newText, gratitude_type: gratitudeType, privacy });
    handleCloseEmoji();
  };

  return (
    <>
      {temporaryShowMessage ? (
        info
      ) : (
        <div
          style={{
            textAlign: 'right',
            position: 'relative',
            bottom: -20,
            right: -20,
          }}
        >
          <Button size="small" onClick={handleShowMessage}>
            <HelpOutlineIcon />
          </Button>
        </div>
      )}
      <Popover
        open={showEmojis}
        anchorEl={emojiAnchorEl}
        onClose={handleCloseEmoji}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Picker onEmojiClick={handleOnEmojiSelect} />
      </Popover>
      <div
        className="GiveThanks"
        style={
          {
            // backgroundColor: '#eee',
            // borderRadius: 20,
            // padding: 5,
            // paddingRight: 20,
            // paddingLeft: 20,
            // marginBottom: 20,
          }
        }
      >
        <div className="GiveThanks-Text-Hint">
          Tag with username or email typing @.
        </div>

        <Input
          error={!isEmpty(error)}
          text={text}
          onChange={handleTextChange}
          handleOnEmojiClick={handleOnEmojiClick}
        />
        <div className="GiveThanks-Buttons">
          <Stack spacing={2}>
            <div className="GiveThanks-PersonNotified">
              {error && (
                <AlertMessage type="text" sx={{ p: 0, m: 0 }}>
                  {error}
                </AlertMessage>
              )}

              {notifiadedCount > 0 && (
                <div>{`${notifiadedCount} person will be notified.`}</div>
              )}

              {gratitude?.privacy === 1 && <div>Everybody can see this</div>}
              {gratitude?.privacy === 2 && (
                <div>
                  Only the people who you are sending gratitude can see this
                </div>
              )}
              {gratitude?.privacy === 3 && (
                <div>Only your friends can see this</div>
              )}
            </div>
            <HStack spacing={2} justifyContent="right">
              <PrivacyDropdown onChange={handlePrivacyChange} />
              <LoadingButton
                disabled={!!error}
                data-testid="send-gratitude-button"
                variant="contained"
                onClick={onSend}
                loading={loading}
              >
                Send gratitude!
              </LoadingButton>
            </HStack>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default GiveThanks;
