import React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Link, Typography } from '@mui/material';

const Breadcrumbs = ({ page }) => (
  <MuiBreadcrumbs aria-label="breadcrumb">
    <Link href="/">Home</Link>
    <Typography color="text.primary" style={{ textTransform: 'capitalize' }}>
      {page}
    </Typography>
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
