import { createDefaultSlice, actionsWrapper } from '../../utils/redux';
import userService from '../../services/users';
import fileUploaderService from '../../services/fileUploader';
import { actions as alertsActions } from '../../features/AlertsFeature/slice';
import { AxiosError } from 'axios';
export interface AccountState {
  // user?: any;
  // karmaData?: object;
  // email?: string;
  // displayName?: string;
  // flash?: string;
  // avatarUrl?: string;
  // loading?: boolean;
  // firstName?: string;
  // lastName?: string;
  // accountSettings?: any;
  unsubscribeEmail?: string;
}

const initialState: AccountState = {
  // user: null,
  // karmaData: null,
};

const accountSlice = createDefaultSlice<AccountState>('account', initialState);

const { multiSet } = actionsWrapper(accountSlice);

export default accountSlice.reducer;

// export const onLoadSettings = () => (dispatch) => {
//   userService.fetchSettings().then((data) => {
//     dispatch(
//       multiSet({
//         accountSettings: data.account_settings,
//         avatarUrl: data.avatar_url,
//         firstName: data.first_name,
//         lastName: data.last_name,
//         user: data,
//       })
//     );
//   });
// };

// export const onUpdateSettings = (paylod) => (dispatch) => {
//   userService
//     .updateSettings(paylod)
//     .then(() => {
//       dispatch(onLoadSettings());
//     })
//     .then(() => {
//       dispatch(alertsActions.addAlert('Settings updated'));
//     });
// };

// export const onAccountReset = () => (dispatch) => {
//   dispatch(
//     multiSet({
//       flash: null,
//     })
//   );
// };

// export const onUnsubscribe = (id) => (dispatch) => {
//   userService.unsibscribe(id).then((email) => {
//     dispatch(
//       multiSet({
//         unsubscribeEmail: email,
//       })
//     );
//   });
// };

// export const onUploadAvatar = (file) => (dispatch) => {
//   dispatch(multiSet({ loading: true }));
//   fileUploaderService.uploadFile(file).then((downloadURL) =>
//     userService.updateAvatar(downloadURL).then(() => {
//       dispatch(
//         multiSet({
//           avatarUrl: downloadURL,
//           loading: false,
//         })
//       );
//     })
//   );
// };

// const onUpdatePassword = (oldPassword, newPassword) => async (dispatch) => {
//   try {
//     await userService.updateToNewPassword(oldPassword, newPassword);

//     dispatch(alertsActions.addAlert('Password updated'));
//   } catch (error) {
//     const {
//       response: {
//         data: { errors },
//       },
//     } = error as AxiosError;
//     dispatch(alertsActions.addAlert(errors));
//   }
// };

export const actions = {
  // onLoadSettings,
  // onUpdateSettings,
  // onAccountReset,
  // onUnsubscribe,
  // onUploadAvatar,
  // onUpdatePassword,
};
