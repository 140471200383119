import { Pagination } from '@mui/material';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Spinner from 'components/Spinner/Spinner';
import BoxStyled from 'components/Styled/BoxStyled/BoxStyled';
import { ListResponse } from 'features/api/types';
import { isEmpty, map } from 'lodash';
import React, { ReactNode } from 'react';

interface ListProps<T> {
  list: ListResponse<T>;
  onPageChange: (page: number) => void;
  children: (item: T) => ReactNode;
  loading: boolean;
  emptyMessage?: string;
}

const List = <T extends unknown>({
  list,
  onPageChange,
  loading,
  children,
  emptyMessage = 'Ups, this is empty... for now',
}: ListProps<T>) => {
  if (loading) {
    return <Spinner />;
  }

  const { data, page: currentPage, pages: pageCount } = list;

  if (isEmpty(data)) {
    return <AlertMessage>{emptyMessage}</AlertMessage>;
  }

  return (
    <>
      <BoxStyled className="has-list">
        <ul>
          {map<T>(data, (item) => (
            <li>
              {children(item)}
              {/* <NotificationEntry notification={notification} /> */}
            </li>
          ))}
        </ul>
      </BoxStyled>
      <div
        style={{
          display: 'flex',
          margin: 'auto',
          marginTop: 10,
          marginBottom: 10,
          textAlign: 'center',
        }}
      >
        <Pagination
          style={{ margin: 'auto', marginTop: 10, textAlign: 'center' }}
          page={currentPage}
          count={pageCount}
          onChange={(e, p) => onPageChange(p)}
        />
      </div>
    </>
  );
};

export default List;

// interface ListHook<T> {
//   hook: any;
//   children: (item: T) => ReactNode;
// }

// export const ListHook = <T extends undefined>({ hook, children }: ListHook<T>) => {
//   return (
//     <List children={children} />
//   )
// }
