import { Box } from '@mui/material';
import ButtonLink from 'components/ButtonLink/ButtonLink';
import React from 'react';

const Footer = () => (
    <footer>
      <Box sx={{my:10}}>
        <ButtonLink to='/what-science-has-to-say-about-gratitude' variant='outlined'>
          Here’s What Science Says About Gratitude
        </ButtonLink>
      </Box>
    </footer>
  );

export default Footer;