import React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import Avatar from 'components/Avatar/Avatar';
import moment from 'moment';
import { Notification } from 'types/model';
import { useNavigate } from 'react-router-dom';

interface NotificationItemProps {
  notification: Notification;
  onClose: () => void;
}

const UnreadDot = styled('div')(() => ({
  backgroundColor: '#d32f2f',
  borderRadius: 5,
  width: 10,
  height: 10,
  marginLeft: 10,
}));

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onClose,
}) => {
  const navigate = useNavigate();
  const { display_name, created_at, read, content, username, avatar_url } =
    notification;

  const handleGoToUser = () => {
    navigate(`/u/${username}`);
    onClose();
  };

  return (
    <Stack direction="row" spacing={0}>
      <Avatar
        src={avatar_url}
        name={display_name}
        size="small"
        sx={{ mr: 1 }}
      />
      <Stack spacing={0}>
        <div style={{ fontSize: 12 }}>
          <Typography
            component="span"
            color="secondary.dark"
            className="cursor-pointer"
            onClick={handleGoToUser}
          >
            {display_name}{' '}
          </Typography>
          {content}
        </div>
        <div style={{ fontSize: 11, color: 'gray' }}>
          {moment.utc(created_at).fromNow()}
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: formatContent(content) }} /> */}
      </Stack>
      {!read && <UnreadDot style={{ marginTop: 4 }} />}
    </Stack>
  );
};

export default NotificationItem;
