import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import axios from 'axios';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
// import { createTheme } from '@mui/material/styles';

import reportWebVitals from './reportWebVitals';
import App from './App';
// import actions from './store/sliceActions';;
import { store } from './store/store';
import { ThemeProvider } from './themes/default';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

const honeybadger = Honeybadger.configure({
  apiKey: 'hbp_mybMygsVfbsUBJyIgkAGodpoefhueB0noSKm',
  environment: process.env.NODE_ENV,
  revision: 'main',
});

ReactDOM.render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <ThemeProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </HoneybadgerErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
