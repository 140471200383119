import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import ALink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import BoxStyled, { Box1 } from '../Styled/BoxStyled/BoxStyled';
import AlertMessage from 'components/AlertMessage/AlertMessage';

interface SignInProps {
  onLogin: (email: string, password: string) => void;
  error: boolean;
  isLoading: boolean;
  errorMessages?: any;
}

const SignIn: React.FC<SignInProps> = ({
  onLogin,
  error,
  isLoading,
  errorMessages,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    onLogin(data.get('email').toString(), data.get('password').toString());
  };

  let defaults = { email: '', password: '' };

  if (process.env.NODE_ENV == 'development') {
    defaults = { email: 'federico.dayan@gmail.com', password: 'pass1234' };
  }

  return (
    <>
      <CssBaseline />
      <Box1
        disabled={isLoading}
        className="SignIn"
        sx={{
          m: 2,
          marginTop: 4,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
        {/* <Typography color='secondary.dark'>Give some gratitude...</Typography> */}
        <Typography
          component="h1"
          variant="h5"
          style={{ fontSize: 34, fontWeight: 600 }}
        >
          Sign in to continue
        </Typography>
        <BoxStyled sx={{ margin: 'auto', marginTop: 2, maxWidth: '500px' }}>
          {errorMessages && (
            <AlertMessage type="text">{errorMessages}</AlertMessage>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              data-testid="email"
              error={error}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              defaultValue={defaults.email}
            />
            <TextField
              data-testid="password"
              error={error}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              defaultValue={defaults.password}
            />
            {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
            <Button
              data-testid="submit"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/forgotPassword">Forgot password?</Link>
              </Grid>
            </Grid>
          </Box>
        </BoxStyled>
        <Grid item style={{ marginTop: 50 }}>
          <div>Don&apos;t have an account? </div>
          <Link to="/signup" className="primary-link">
            Sign Up
          </Link>
        </Grid>
      </Box1>
    </>
  );
};

export default SignIn;
