import { createDefaultSlice, actionsWrapper } from '../../utils/redux';
import friendshipService from '../../services/friendship';
// import { actions as alertsActions } from '../../features/AlertsFeature/slice';

export interface ConnectionsState {
  // users?: any;
  // usersPageCount?: number;
  // loading?: boolean;
  // usersPage?: number;
}

const initialState: ConnectionsState = {};

export const connectionsSlice = createDefaultSlice('connections', initialState);

export default connectionsSlice.reducer;

const { multiSet } = actionsWrapper(connectionsSlice);

// export const onLoadUsers = (connectionssSortBy) => (dispatch) => {
//   dispatch(multiSet({ loading: true }));
//   friendshipService
//     .fetchFriendsForUser(1, connectionssSortBy)
//     .then((data) => {
//       dispatch(
//         multiSet({
//           users: data.users,
//           usersPageCount: data.pages,
//           usersPage: data.page,
//           loading: false,
//         })
//       );
//     })
//     .catch(() => {
//       dispatch(multiSet({ loading: false }));
//     });
// };

// export const onPageChange = (page, connectionssSortBy) => (dispatch) => {
//   friendshipService
//     .fetchFriendsForUser(page, connectionssSortBy)
//     .then((data) => {
//       dispatch(
//         multiSet({
//           users: data.users,
//           usersPageCount: data.pages,
//           usersPage: data.page,
//         })
//       );
//     });
// };

// export const onFrienshipApprove = (friendshipId) => (dispatch) => {
//   friendshipService.approveFrienship(friendshipId).then(() => {
//     dispatch(onLoadUsers(1));
//     dispatch(alertsActions.addAlert('Friend request accepted'));
//   });
// };

// export const onFrienshipReject = (friendshipId) => (dispatch) => {
//   friendshipService.rejectFrienship(friendshipId).then(() => {
//     dispatch(onLoadUsers(1));
//     dispatch(alertsActions.addAlert('Friend request rejected'));
//   });
// };

// export const onAddFriend = (person) => (dispatch) => {
//   friendshipService.addFriend(person.id).then(() => {
//     dispatch(onLoadUsers(1));
//     dispatch(
//       alertsActions.addAlert(`${person.display_name} follow request sent.`)
//     );
//   });
// };

// export const onRemoveFriend = (friendshipId) => (dispatch) => {
//   friendshipService.removeFriend(friendshipId).then(() => {
//     dispatch(onLoadUsers(1));
//     dispatch(alertsActions.addAlert(`Unfriend`));
//   });
// };

export const actions = {
  // onLoadUsers,
  // onPageChange,
  // onFrienshipApprove,
  // onFrienshipReject,
  // onAddFriend,
  // onRemoveFriend,
};
