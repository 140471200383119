import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
  map,
  some,
  isEmpty,
  forEach,
  includes,
  partition,
  size,
  filter,
} from 'lodash';
import './AddNewConnectionModal.css';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

const haveBlankFirstName = ({ first_name }) => isEmpty(first_name);

const getErrors = (partialUsers) => {
  const errors = {};
  forEach(partialUsers, (p) => {
    if (haveBlankFirstName(p)) {
      errors[p.email] = { firstName: 'First name cannot be blank' };
    }
  });

  return errors;
};

const AddNewConnectionModal = ({
  onClose,
  open,
  emails,
  onSave,
  fetchUsers,
  existingUsers,
}) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isEmpty(emails)) {
      fetchUsers(emails);
    }
  }, [emails]);

  const existingUsersMap = {};

  forEach(existingUsers, (e) => {
    existingUsersMap[e.email] = e;
  });

  const newUsersEmails = filter(emails, (e) => !existingUsersMap[e]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const partialUsers = map(newUsersEmails, (e, idx) => ({
      email: data.get(`email${idx}`),
      first_name: data.get(`firstName${idx}`),
      last_name: data.get(`lastName${idx}`),
    }));

    if (some(partialUsers, haveBlankFirstName)) {
      setErrors(getErrors(partialUsers));
    } else {
      setErrors({});
      onSave(partialUsers, existingUsers);
    }
  };

  const getFirstNameError = (errors, email) =>
    errors && errors[email] && errors[email].firstName;

  const handleClose = () => {
    setErrors({});
    onClose();
  };

  const hasExsitingUsers = !isEmpty(existingUsers);
  const hasNewUsers = size(existingUsers) !== size(emails);

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="xl">
        <DialogTitle>Looks like this person is not register.</DialogTitle>
        <Alert>
          {hasNewUsers && (
            <div>
              Looks like you have new users, please add first name (and last
              name optional) for each person <br /> to protect the person's
              email. When they sign up they&apos;ll have an option to validate
              their data.
            </div>
          )}
          {hasNewUsers && hasExsitingUsers && <br />}
          {hasExsitingUsers && (
            <div>
              Looks like you have existing users, you don&apos;t have to do
              anything just confirm the connection.
            </div>
          )}
        </Alert>

        <Box sx={{ m: 2 }} component="form" onSubmit={handleSubmit} noValidate>
          {map(emails, (e, idx) => {
            if (existingUsersMap[e]) {
              return (
                <Stack
                  direction="row"
                  spacing={2}
                  key={e}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    disabled
                    label="Email"
                    variant="outlined"
                    defaultValue={existingUsersMap[e].email}
                  />
                  <TextField
                    disabled
                    label="First name"
                    variant="outlined"
                    defaultValue={existingUsersMap[e].first_name}
                  />
                  <TextField
                    disabled
                    label="Last name"
                    variant="outlined"
                    defaultValue={existingUsersMap[e].last_name}
                  />
                </Stack>
              );
            }
            return (
              <Stack
                direction="row"
                spacing={2}
                key={e}
                style={{ marginBottom: 20 }}
              >
                <TextField
                  id={`email-${idx}`}
                  name={`email${idx}`}
                  label="Email"
                  variant="outlined"
                  defaultValue={e}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  error={!!getFirstNameError(errors, e)}
                  helperText={getFirstNameError(errors, e)}
                  id={`first-name-${idx}`}
                  name={`firstName${idx}`}
                  label="First name"
                  required
                  variant="outlined"
                />
                <TextField
                  id={`last-name-${idx}`}
                  name={`lastName${idx}`}
                  label="Last name"
                  variant="outlined"
                />
              </Stack>
            );
          })}
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2} justifyContent="right">
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddNewConnectionModal;
