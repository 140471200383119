import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import AlertFeature from '../../../features/AlertsFeature';
import { isEmpty } from 'lodash';
import ForgotPassword from '../../../components/ForgotPassword/ForgotPassword';
import withGA from '../../../hoc/withGoogleAnalytics';
import Logo from '../../../components/Logo/Logo';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import sliceActions from '../../../store/sliceActions';
import { useDispachify } from '../../../utils/redux';
import { useAuth } from 'store/slices/auth';
import { useSendForgotPasswordMutation } from 'features/api/authSlice';
import NoAuthenticated from 'layouts/NoAuthenticated';

const RecoverPassword = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  // const actions = sliceActions.auth;
  // const wrapper = useDispachify(dispatch);
  // const { errors } = useAppSelector((store) => store.auth);
  // const onForgotPasswordSend = wrapper(actions.onForgotPasswordSend);
  // const resetAuth = wrapper(actions.onResetAuth);
  const { clearAuth } = useAuth();
  const [sendForgotPassword, { error }] = useSendForgotPasswordMutation();

  useEffect(() => {
    clearAuth();
  }, []);

  const handleOnForgotPasswordSend = (email) => {
    sendForgotPassword({ email });
  };

  return (
    <NoAuthenticated>
      <ForgotPassword onClick={handleOnForgotPasswordSend} error={!!error} />
    </NoAuthenticated>
  );
};

RecoverPassword.displayName = 'RecoverPassword';

export default withGA(RecoverPassword);
