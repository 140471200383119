import { createDefaultSlice } from '../../utils/redux';
// import gratitudeService from '../../services/gratitudes';

export interface CommentsState {
  // gratitude?: any;
  // comments?: any;
}

const initialState: CommentsState = {};

export const commentsSlice = createDefaultSlice('comments', initialState);

export const { multiSet, set } = commentsSlice.actions;

export default commentsSlice.reducer;

// const commentsMultiSet = (map) => multiSet({ map });

// export const onLoadComments = (gratitudeId) => async (dispatch) => {
//   const { comments, gratitude } = await gratitudeService.fetchComments(
//     gratitudeId
//   );

//   dispatch(commentsMultiSet({ comments, gratitude }));
// };

// export const onAddComment = (gratitudeId, content) => async (dispatch) => {
//   const { gratitude } = await gratitudeService.addComment(gratitudeId, content);

//   dispatch(onLoadComments(gratitudeId));
//   // dispatch(commentsMultiSet({
//   //   gratitude
//   // }))
// };

export const actions = {
  // onLoadComments,
  // onAddComment,
};
