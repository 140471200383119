// Import the RTK Query methods from the React-specific entry point
import {
  EndpointBuilder,
  EndpointDefinitions,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponseHeaders,
  HeadersDefaults,
} from 'axios';
import { BASE_URL } from 'services/http';
import { RootState } from 'store/store';
import apiAxios from './apiAxios';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';

export interface AxiosMetaResponse {
  headers?: AxiosResponseHeaders;
}
export interface AxiosMeta {
  response?: AxiosMetaResponse;
}
interface AxiosBaseQueryProps {
  baseUrl: string;
  prepareHeaders?: (
    // headers: Headers,
    axiosInstance: AxiosInstance,
    api: Pick<
      BaseQueryApi,
      'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
    >
  ) => MaybePromise<AxiosInstance>;
  axiosInstance: AxiosInstance;
}

export interface AxiosParams {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
}

const axiosBaseQuery =
  ({
    axiosInstance,
    baseUrl,
    prepareHeaders = (x) => x,
  }: AxiosBaseQueryProps): BaseQueryFn<
    AxiosParams | string,
    unknown,
    unknown,
    unknown,
    AxiosMeta
  > =>
  async (args, api) => {
    try {
      const {
        url,
        method = 'GET',
        params = undefined,
        data = undefined,
      } = typeof args == 'string' ? { url: args } : args;

      let meta: AxiosMeta = {};

      // await prepareHeaders(axiosInstance, api);

      // const token = (api.getState() as RootState).auth.token;
      const axiosRequestConfig: AxiosRequestConfig = {
        url: baseUrl + url,
        method,
        data,
        params,
      };

      // if (token) {
      //   const headers: AxiosRequestHeaders = {}
      //   headers['Authorization'] = token;
      //   axiosRequestConfig.headers = headers
      // }

      const result = await axiosInstance(axiosRequestConfig);

      meta.response = { headers: result.headers };

      // console.log(url);
      // console.log(result.data)

      return { data: result.data, meta: meta };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

// Define our single API slice object
export const apiSlice = createApi({
  reducerPath: 'api',
  refetchOnMountOrArgChange: true,
  // baseQuery: axiosBaseQuery({
  //   axiosInstance: apiAxios,
  //   baseUrl: `${BASE_URL}/api`,
  //   prepareHeaders: (axiosInstance, { getState }) => {
  //     // By default, if we have a token in the store, let's use that for authenticated requests
  //     const token = (getState() as RootState).auth.token;
  //     if (token) {
  //       // headers.set('Authorization', `Bearer ${token}`)
  //       // headers.set('Authorization', token);
  //       axiosInstance.defaults.headers.common.Authorization = token;
  //     }
  //     return axiosInstance;
  //   },
  // }),
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        // headers.set('Authorization', `Bearer ${token}`)
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: [
    'Gratitude',
    'Friendship',
    'Comment',
    'Notification',
    'Session',
    'Karma',
    'Account',
    'User',
    'CurrentUser',
  ],
  endpoints: (builder) => ({}),
});
