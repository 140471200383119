import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Paper from '../Paper/Paper';

import { formatNumberShort } from '../../utils/numbers';
import './UserConnection.css';
import Avatar from '../Avatar/Avatar';
import KarmaChip from '../KarmaChip/KarmaChip';
import {
  ChipKarma,
  ChipGiving,
  ChipReceiving,
} from '../../components/Styled/ChipStyled/ChipStyled';
import { Button, Grid, styled } from '@mui/material';

const Info = styled('span')(() => ({
  fontSize: 12,
  color: 'gray',
}));

interface UserConnectionProps {
  user: any;
  onApprove: (friendshipId: number) => void;
  onReject: (friendshipId: number) => void;
  onRemoveFriend: (friendshipId: number) => void;
}

const UserConnection: React.FC<UserConnectionProps> = ({
  user,
  onRemoveFriend,
  onApprove,
  onReject,
}) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/u/${user.username}`);

  return (
    <Grid container marginBottom={2}>
      <Grid item md={6} xs={12}>
        <Stack spacing={2} direction="row">
          <Avatar
            src={user.avatar_url}
            name={user.display_name}
            size={75}
            fontSize={24}
          />

          <Stack>
            <div className="UserConnection-Link" onClick={handleClick}>
              {user.display_name}
            </div>
            {/* {!user.accepted && (
              <Info>Waiting for friendship request approval.</Info>
            )} */}
            {user.needs_approval && (
              <Info>You have to approve or reject this request.</Info>
            )}
            <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
              <ChipGiving score={user.giving_count} />
              <ChipReceiving score={user.reciving_count} />
              <ChipKarma score={user.karma_score} />
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid item md={6} xs={12}>
        <div style={{ flexGrow: 1, marginTop: 15 }}>
          <Stack
            spacing={1}
            direction="row"
            style={{ float: 'right', height: 30, top: 0, flexGrow: 1 }}
          >
            {user.accepted && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => onRemoveFriend(user.friendship_id)}
                color="error"
              >
                Unfriend
              </Button>
            )}
            {user.needs_approval && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => onApprove(user.friendship_id)}
                  color="success"
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => onReject(user.friendship_id)}
                  color="error"
                >
                  Reject
                </Button>
              </>
            )}
          </Stack>
        </div>
      </Grid>
    </Grid>
  );
  {
    /* {user.needs_approval && (
        <div style={{ flexGrow: 1 }}>
          <Stack
            spacing={1}
            direction="row"
            style={{ float: 'right', height: 30, top: 0, flexGrow: 1 }}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={() => onApprove(user.friend_friendship_id)}
              color="success"
            // sx={{ display: 'block', minWidth: 100 }}
            >
              Approve
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => onReject(user.friend_friendship_id)}
              // style={{ marginLeft: 10 }}
              // sx={{ display: 'block', minWidth: 100 }}
              color="error"
            >
              Reject
            </Button>
          </Stack>
        </div>
      )} */
  }
};

export default UserConnection;
