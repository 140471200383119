import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';

import './App.css';

import Dashboard from './containers/Dashboard/Dashboard';
import Connections from './containers/Connections/Connections';
import UserDetails from './containers/UserDetails/UserDetails';
import Auth from './containers/Auth/Auth';
import Register from './containers/Auth/Register/Register';
import RecoverPassword from './containers/Auth/RecoverPassword/RecoverPassword';
import Password from './containers/Auth/Password/Password';
import Account from './containers/Account/Account';
import Unsubscribe from './containers/Unsubscribe/Unsubscribe';
import Unknown from './containers/Unknown/Unknown';
import Followers from './containers/Followers/Followers';
import Comments from './containers/Comments/Comments';
import Onboarding from 'containers/Auth/Onboarding/Onboarding';
import Blog from 'containers/Blog/Blog';

// import { useAppDispatch, useAppSelector } from 'hooks/redux';
// import sliceActions from './store/sliceActions';
// import { useDispachify } from './utils/redux';
import Notifications from 'containers/Notifications/Notifications';
import { useAuth } from 'store/slices/auth';
import Landing from 'components/Landing/Landing';

const App = () => {
  // const dispatch = useAppDispatch();
  // const wrapper = useDispachify(dispatch);
  // const actions = sliceActions.auth;
  // const onInitSignInUser = wrapper(actions.onInitSignInUser);
  // const { authenticated, isAuthLoading } = useAppSelector(
  //   (state) => state.auth
  // );
  const { isAuthenticated, isAuthLoading } = useAuth(true);

  // useEffect(() => {
  //   onInitSignInUser();
  // }, []);

  if (isAuthLoading) {
    return (
      <Stack alignItems="center" sx={{ mt: 8 }}>
        <CircularProgress />
      </Stack>
    );
  }

  let routes = null;

  if (isAuthenticated) {
    routes = (
      <>
        <Route path="/friends" element={<Connections />} />
        <Route path="/followers" element={<Followers />} />
        <Route path="/account" element={<Account />} />
        <Route path="/gratitudes/:id/comments" element={<Comments />} />
        <Route
          path="/account/settings/unsubscribe/:id"
          element={<Unsubscribe />}
        />
        {/* <Route path="/signup" element={<Navigatex to='/' />} /> */}
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/me/:tab" element={<Dashboard />} />
        <Route path="/signup" element={<Navigate to="/" />} />
        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="*" element={<Unknown />} />
      </>
    );
  } else {
    routes = (
      <>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Auth />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/forgotPassword" element={<RecoverPassword />} />
        <Route path="/api/passwords/edit" element={<Password />} />
        <Route
          path="/account/settings/unsubscribe/:id"
          element={<Unsubscribe />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </>
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/what-science-has-to-say-about-gratitude" element={<Blog />} />
          <Route path="/u/:id" element={<UserDetails />} />
          <Route path="/u/:id/:tab" element={<UserDetails />} />
          {routes}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
