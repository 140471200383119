import { TextField } from '@mui/material';
import React, { useState } from 'react';
interface AddCommentProps {
  gratitudeId: number;
  onAddComment: (gratitudeId: number, content: string) => void;
}

const AddComment: React.FC<AddCommentProps> = ({
  gratitudeId,
  onAddComment,
}) => {
  const [value, setValue] = useState('');

  const handleAddComment = (comment) => {
    onAddComment(gratitudeId, comment);
    setValue('');
  };

  return (
    <div>
      <TextField
        onChange={(e) => setValue(e.target.value)}
        value={value}
        size="small"
        fullWidth
        placeholder="Enter comment..."
        onKeyPress={(e) => {
          const button = e.target as HTMLButtonElement;
          if (e.key === 'Enter') {
            handleAddComment(button.value);
          }
        }}
      />
    </div>
  );
};

export default AddComment;
