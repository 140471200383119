import React from 'react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import NewPassword from '../../../components/NewPassword/NewPassword';
import withGA from '../../../hoc/withGoogleAnalytics';
import NoAuthenticated from 'layouts/NoAuthenticated';
import { useAlert } from 'features/AlertsFeature/hook';
import { useResetPasswordMutation } from 'features/api/authSlice';

const Password = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  // const actions = sliceActions.auth;
  // const wrapper = useDispachify(dispatch);
  // const { errors } = useAppSelector((store) => store.auth);

  // const onUpdatePassword = wrapper(actions.onUpdatePassword);
  const alert = useAlert();
  const [updatePassword, { error }] = useResetPasswordMutation();

  const handleOnUpdatePassword = async (password, token) => {
    await updatePassword({ password, token });
    alert.addAlert('Password successfully updated');
    navigate('/');
  };

  return (
    <NoAuthenticated>
      {/* {errors && <Alert severity="error">{errors}</Alert>} */}
      {/* {flash && <Alert severity="info">{flash}</Alert>} */}
      <NewPassword onUpdate={handleOnUpdatePassword} error={!isEmpty(error)} />
    </NoAuthenticated>
  );
};

Password.displayName = 'Password';

export default withGA(Password);
