import React, { ReactNode } from 'react';
import { Box, BoxProps, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

type BoxStyledProps = BoxProps & { mobile?: boolean; disabled?: boolean };

const BoxStyled: React.FC<BoxStyledProps> = styled(Box)<BoxStyledProps>(
  ({ mobile }) => ({
    padding: mobile ? 15 : 30,
    paddingTop: 20, //mobile ? 20 : 0,
    background: '#FFFFFF',
    boxShadow: '0px 0px 8px rgba(73, 139, 237, 0.3)',
    borderRadius: 20,
    '&.has-list ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '&.has-list li': {
      borderBottom: '1px solid #eee',
      padding: 20,
    },
    '&.has-list li:last-child': {
      borderBottom: '0px',
    },
  })
);

type BoxStyledCmpProps = BoxProps & { disabled?: boolean; title?: ReactNode };

const BoxStyledCmp: React.FC<BoxStyledCmpProps> = ({
  disabled,
  title,
  children,
  ...props
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <BoxStyled disabled={disabled} {...props} mobile={matches}>
      {title && <h4 style={{ margin: 0, padding: 0 }}>{title}</h4>}
      {children}
    </BoxStyled>
  );
};

export default BoxStyledCmp;

export const Box1: React.FC<BoxStyledProps> = styled(Box)<BoxStyledProps>(
  () => ({})
);
