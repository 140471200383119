import Karma from 'components/Karma/Karma';
import Spinner from 'components/Spinner/Spinner';
import { useGetLatestAllKarmaQuery } from 'features/api/karmaSlice';
import React from 'react';

const KarmaPanel = () => {
  const { data, isFetching } = useGetLatestAllKarmaQuery();

  if (isFetching) {
    return <Spinner />;
  }

  return <Karma karmaData={data.karma_data} />;
};

export default KarmaPanel;
