import React, { useEffect } from 'react';
import { Button, Menu, Stack, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';

import { isEmpty, random } from 'lodash';
import Avatar from 'components/Avatar/Avatar';
import NotificationItem from 'components/NotificationItem/NotificationItem';
import { Notification } from 'types/model';
import ButtonLink from 'components/ButtonLink/ButtonLink';
import { useGetLatestNotificationsQuery } from 'features/api/notificationsSlice';
import Spinner from 'components/Spinner/Spinner';

interface MenuNotificationsProps {
  // notifications: Notification[];
  naviationsAnchorEl: any;
  openNavigation: boolean;
  handleClose: () => void;
}

const LatestNotificationsContent = ({ onClose }) => {
  const { data, isFetching } = useGetLatestNotificationsQuery();

  // if (isFetching) {
  //   return <Spinner />;
  // }

  // const { notifications } = data;

  let content = null;
  if (isEmpty(data?.notifications) && !isFetching) {
    content = (
      <Stack spacing={2} style={{ margin: 10 }}>
        <Typography>No notifications</Typography>
      </Stack>
    );
  } else {
    content = (
      <Stack spacing={2} style={{ margin: 10, width: 500 }}>
        {!isFetching &&
          data?.notifications &&
          data?.notifications.map((notification) => (
            <NotificationItem
              onClose={onClose}
              notification={notification}
              key={notification.id}
            />
          ))}
        <div style={{ textAlign: 'center' }}>
          <ButtonLink to="/notifications" variant="outlined" size="small">
            View all notifications
          </ButtonLink>
        </div>
      </Stack>
    );
  }

  return content;
};

const MenuNotifications: React.FC<MenuNotificationsProps> = ({
  naviationsAnchorEl,
  openNavigation,
  handleClose,
  // notifications,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={naviationsAnchorEl}
      open={openNavigation}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      style={{
        top: 30,
        // right: -50
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <LatestNotificationsContent onClose={handleClose} />
      </ClickAwayListener>
    </Menu>
  );
};

export default MenuNotifications;
