import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  IconButton,
  Stack,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { formatNumberShort } from '../../utils/numbers';
import { isFunction } from 'lodash';
import {
  ChipKarma,
  ChipGiving,
  ChipReceiving,
} from '../../components/Styled/ChipStyled/ChipStyled';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const pageName = (page, props) =>
  isFunction(page[0]) ? page[0](props) : page[0];

interface LayoutDrawerProps {
  pages: any[];
  onSetAnchorElNav: (anchor: string) => void;
  onCloseMenu: (url: string) => void;
  anchorElNav: string;
  onOpenMenu: (event: any) => void;
  // karmaColor: string;
  totalGiving: number;
  totalReceiving: number;
  onAccountClick: () => void;
  displayName: string;
  onLogout: () => void;
  karmaScore: number;
}

const LayoutDrawer: React.FC<LayoutDrawerProps> = (props) => {
  const {
    pages,
    onSetAnchorElNav,
    onCloseMenu,
    anchorElNav,
    onOpenMenu,
    // karmaColor,
    totalGiving,
    onAccountClick,
    totalReceiving,
    displayName,
    onLogout,
    karmaScore,
  } = props;
  const navigate = useNavigate();
  return (
    <Box sx={{ display: { xs: '1', md: 'none' } }}>
      <IconButton
        size="medium"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={onOpenMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        variant="temporary"
        open={Boolean(anchorElNav)}
        onClose={() => onSetAnchorElNav(null)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        <List>
          {pages.map((page) => (
            <ListItem
              button
              key={pageName(page, props)}
              onClick={() => onCloseMenu(page[1])}
            >
              <ListItemIcon>{page[2]}</ListItemIcon>
              <ListItemText primary={pageName(page, props)} />
            </ListItem>
          ))}
          <ListItem
            button
            key="notifications"
            onClick={() => navigate('/notifications')}
          >
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItem>
          <ListItem button key="Logout" onClick={onLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
          <ListItem key={displayName} onClick={onAccountClick}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={displayName} />
          </ListItem>
        </List>
        {/* {karmaScore && (
          <Chip
            icon={<EmojiEventsIcon />}
            style={{ backgroundColor: karmaColor }}
            label={karmaScore}
            sx={{ ml: 2, mr: 2, mb: 1 }}
          />
        )}
        <Chip
          icon={<ArrowForwardIcon />}
          color="error"
          label={formatNumberShort(totalGiving)}
          sx={{ ml: 2, mr: 2, mb: 1 }}
        />
        <Chip
          icon={<ArrowBackIcon />}
          color="success"
          label={formatNumberShort(totalReceiving)}
          sx={{ ml: 2, mr: 2, mb: 1 }}
        /> */}

        <Stack spacing={2} justifyContent="left">
          <ChipReceiving score={totalReceiving} />
          <ChipGiving score={totalGiving} />
          <ChipKarma score={karmaScore} />
        </Stack>
      </Drawer>
    </Box>
  );
};

export default LayoutDrawer;
