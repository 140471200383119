import React from 'react';
import {
  Chart,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, ValueScale } from '@devexpress/dx-react-chart';
import { LegendRoot, LegendLabel } from '../Legend/legend';
import BoxStyled from '../Styled/BoxStyled/BoxStyled';

const KarmaScoreChart = ({ data, title }) => (
  <BoxStyled>
    <Chart data={data}>
      <ValueScale modifyDomain={() => [0, 100]} />

      <ArgumentAxis />

      <ValueAxis />

      <LineSeries
        name="Karma"
        valueField="karma"
        argumentField="month"
        color="#29D44F"
      />

      <Title text={title} />
      <Animation />
      <Stack />
      <Legend
        rootComponent={LegendRoot}
        labelComponent={LegendLabel}
        position="bottom"
      />
    </Chart>
  </BoxStyled>
);

export default KarmaScoreChart;
