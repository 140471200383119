import { createDefaultSlice, actionsWrapper } from '../../utils/redux';
import friendshipService from '../../services/friendship';
import { actions as alertsActions } from '../../features/AlertsFeature/slice';
import fileUploaderService from '../../services/fileUploader';
import userService from '../../services/users';
// import accountSettings from '../../services/users';

export interface OnbordingState {
  // avatarUrl?: string;
  // loading?: boolean;
  // firstName?: string;
  // lastName?: string;
}

const initialState: OnbordingState = {};

export const onboardingSlice = createDefaultSlice('onboarding', initialState);

const { multiSet } = actionsWrapper(onboardingSlice);

export default onboardingSlice.reducer;

// const onUploadAvatar = (file) => async (dispatch) => {
//   dispatch(multiSet({ loading: true }));

//   const downloadURL = await fileUploaderService.uploadFile(file);
//   await userService.updateAvatar(downloadURL);

//   dispatch(
//     multiSet({
//       avatarUrl: downloadURL,
//       loading: false,
//     })
//   );
// };

// const onLoadSettingds = () => async (dispatch) => {
//   const data = await userService.fetchSettings();

//   dispatch(
//     multiSet({
//       avatarUrl: data.avatar_url,
//       firstName: data.first_name,
//       lastName: data.last_name,
//     })
//   );
// };

export const actions = {
  // onUploadAvatar,
  // onLoadSettingds,
};
