import moment from 'moment';

// export const iterateDates = (from, to, callback) => {
//   const a = from.clone()
//   const b = to.clone()
//
//   const arr = []
//   for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
//     arr.push(callback(m.clone()))
//   }
//   return arr
// }

export const shorFormatDate = (date) => moment.utc(date).format('MMM DD, YYYY');

export const timeAgo = (date) => moment.utc(date).fromNow();

export const iterateDates = (from, to, size, callback) => {
  const a = from.clone();
  const b = to.clone();

  const arr = [];
  for (let m = moment(a); m.diff(b, size) <= 0; m.add(1, size)) {
    arr.push(callback(m.clone()));
  }
  return arr;
};
