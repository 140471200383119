
import React from 'react';
import Box from '@mui/material/Box';

import './Dashboard.css';
// import GiveThanks from '../../components/GiveThanks/GiveThanks';
import GiveThanksFeature from '../../features/GiveThanksFeature';
import TabPanel from '../../components/TabPanel/TabPanel';
// import userService from '../../services/users';
import { withLayout } from '../../hoc/Layout/Layout';
import FriendshipApproval from '../../components/FriendshipApproval/FriendshipApproval';
import withGA from '../../hoc/withGoogleAnalytics';
import ButtonTabs from '../../components/ButtonTabs/ButtonTabs';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router';
import KarmaPanel from './KarmaPanel';
import AllActivity from './AllActivity';
import MyActivity from './MyActivity';
import {
  useFriendshipApproveMutation,
  useFriendshipRejectMutation,
  useGetWaitingFriendshipsQuery,
} from 'features/api/friendshipSlice';
import SearchPeople from 'components/SearchPeople/SearchPeople';
import ContentGrid from 'layouts/ContentGrid';
// interface FuncHash {
//   [funcName: string]: (...args: any) => void;
// }

// function dispachify<T extends { [funcName: string]: (...args: any) => void }>(
//   dispatch,
//   actions: T
// ) {
//   const a = mapValues(actions, (func) => {
//     return (...args) => {
//       dispatch(func(...args));
//     };
//   });

//   return a;
// }

const PAGE_TO_TAB = {
  mine: 1,
  karma: 2,
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const { data: waitingFriendshipData } = useGetWaitingFriendshipsQuery();
  const [approveFriendship] = useFriendshipApproveMutation();
  const [rejectFriendship] = useFriendshipRejectMutation();

  const tabValue = isEmpty(tab) ? 0 : PAGE_TO_TAB[tab];

  const TAB_TO_PAGE = {
    0: '/',
    1: '/me/mine',
    2: '/me/karma',
  };

  const handleTabChange = (newValue) => navigate(TAB_TO_PAGE[newValue]);

  const handleOnPeopleSelected = (user) => navigate(`/u/${user.username}`);

  return (
    <>
      <ContentGrid 
        boxProps={{
          bgcolor:'secondary.light', 
          marginBottom:3,
          paddingTop:3.
        }}
        >
        {/* <div className="Dashboard" data-testid="dashboard-container"> */}
        <SearchPeople
          placeholder="Search people"
          onAddFriend={handleOnPeopleSelected}
          showRequestFriendshipButton={false}
          textFieldProps={{
            size: 'small',
            // style: {
            //   minWidth: 500,
            // },
          }}
        />
        <FriendshipApproval
          friendships={waitingFriendshipData?.friendships}
          onApprove={(friendshipId) => approveFriendship({ friendshipId })}
          onReject={(friendshipId) => rejectFriendship({ friendshipId })}
        />
        <GiveThanksFeature/>
      </ContentGrid>
      <ContentGrid>
        <Box>
          <Box>
            <ButtonTabs
              value={tabValue}
              onChange={handleTabChange}
              labels={['All Activity', 'My Activity', 'Karma']}
            />
          </Box>
          <TabPanel value={tabValue} index={0}>
            <AllActivity />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box data-testid="my-activity-panel">
              <MyActivity />
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Box data-testid="my-karma-panel">
              <KarmaPanel />
            </Box>
          </TabPanel>
        </Box>
        {/* </div> */}
      </ContentGrid>    
    </>
  );
};

export default withLayout(withGA(Dashboard),{
  gridProps: { md: 12, paddingTop: 0 },
});
